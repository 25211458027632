import React, { useState, useEffect } from "react"
import { Spin, Empty, Card, Space, Row, Col, Form, InputNumber } from "antd"

import { getBrands, getWarrantyTypes, getWarrantyPeriods} from "../../../../api/product.api"

import SpecBrand from "./childs/SpecBrand"
import SpecCountry from "./childs/SpecCountry"
import SpecWarrantyType from "./childs/SpecWarrantyType"
import SpecWarrantyPeriod from "./childs/SpecWarrantyPeriod"
import SpectWeightType from "./childs/SpecWeightType"
import SpecWeight from "./childs/SpecWeight"

export default function Specification(props) {
  const currentData = props.currentData
  const [loading, setLoading] = useState(true)
  const [brandOptions, setBrandOptions] = useState([])
  const [warrantyTypeOptions, setWarrantyTypeOptions] = useState([])
  const [warrantyPeriodOptions, setWarrantyPeriodOptions] = useState([])
  const [selectedWarrantyType, setSelectedWarrantyType] = useState(317)
  const [selectedWeightType, setSelectedWeightType] = useState(1)

  useEffect(() => {
    let isMount = true
    const fetchData = async () => {
      try {
        const [brands, warrantyTypes, warrantyPeriods] = await Promise.all([getBrands(), getWarrantyTypes(), getWarrantyPeriods()])
        if (isMount) {
          setBrandOptions(brands.data)
          setWarrantyTypeOptions(warrantyTypes.data)
          setWarrantyPeriodOptions(warrantyPeriods.data)
          setLoading(false)
        }
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    fetchData()
    return () => { isMount = false }
  }, [props])

  return (
    loading ? <Spin spinning={loading}><Empty /></Spin> :
      <div className="second_step_form">
        <Card
          title="Specification"
        >

          <Row gutter={16}>
            <Col span={12}>
              <SpecBrand style={{ width: '100%' }} options={brandOptions} currentData={currentData} />
            </Col>
            <Col span={12}>
              <SpecCountry style={{ width: '100%' }} currentData={currentData} />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12} className="warranty_weight">
              <Form.Item label="Warranty" labelCol={{ span: 24 }}>
                <Space>
                  <SpecWarrantyType options={warrantyTypeOptions} setActionType={setSelectedWarrantyType} currentData={currentData} />
                  <SpecWarrantyPeriod options={warrantyPeriodOptions} selectedWarrantyType={selectedWarrantyType} currentData={currentData} />
                </Space>
              </Form.Item>
            </Col>

            <Col span={12} className="warranty_weight">
              <Form.Item label="Weight" labelCol={{ span: 24 }}>
                <Space>
                  <SpectWeightType setActionType={setSelectedWeightType} currentData={currentData} />
                  <SpecWeight selectedWeightType={selectedWeightType} currentData={currentData} />
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={24} style = {{ display: currentData.type_id === 'simple' ? 'inline' : 'none'}}>
              <Form.Item name="stock_quantity" label="Quantity" labelCol={{ span: 24 }} rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

        </Card>
      </div>
  )
}