import React from 'react';
import ReactDOM from 'react-dom'
import App from './App'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'

import './index.css'

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { publicRoutes } from './routes'

ReactDOM.render(
    <Router>
      <Switch>
       <Route path="/admin" render={routeProps => <ConfigProvider locale={enUS}> <App {...routeProps } /> </ConfigProvider>}/>
       { publicRoutes.map(route => {
         return <Route key={route.path} {...route} />
        }) }
				<Redirect to='/admin' from="/" />
        <Redirect to='/404'/>
      </Switch>
    </Router>
  ,
  document.getElementById('root')
);
