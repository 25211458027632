import React, {useState} from "react"
import { EditableProTable, ProForm } from '@ant-design/pro-components'

export default function CarrierTable(props) {
  const [editableKeys, setEditableRowKeys] = useState([])
  
  const tracking_columns = [
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      valueType: 'select',
      valueEnum: {
        custom: { text: 'Custom Value', status: 'custom'},
        dhl: { text: 'DHL', status: 'dhl'},
        fedex: { text: 'Federal Express', status: 'fedex'},
        ups: { text: 'United Parcel Service', status: 'ups'},
        usps: { text: 'United States Postal Service', status: 'usps'},
      },
      width: '30%',
      render: (text, record, _, action) => {
        console.log(record)
        return text
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Action',
      dataIndex: ['action'],
      key: 'action',
      valueType: 'option',
      width: '20%'
    }
  ]

  return (
    <ProForm.Item
      name="tracking"
      trigger="onValuesChange"
    >
      <EditableProTable
        rowKey="id"
        toolBarRender={false}
        columns={tracking_columns}
        recordCreatorProps={{
          position: 'bottom',
          newRecordType: 'dataSource',
          creatorButtonText: 'Add Tracking Number',
          record: (index) => {
            return { id: index + 1 };
          },
        }}
        editable={{
          type: 'multiple',
          editableKeys,
          deleteText: 'Remove',
          deletePopconfirmMessage: 'Are you sure want to remove?',
          onChange: setEditableRowKeys,
          actionRender: (row, _, dom) => {
            return [dom.delete]
          },
        }}
      />
    </ProForm.Item>
  )
}