import React, {useEffect, useState} from "react"
import { Form, Input, Select, Button, Checkbox, message, Empty, Spin, List, Space, Divider } from "antd"
import { FieldTimeOutlined, MailOutlined, SafetyCertificateOutlined} from "@ant-design/icons"
import _ from "lodash"

import { getUser } from "../../../../utils/auth"
import { getOrderStatus, getOrderCommentHistory, sendOrderComment } from "../../../../api/order.api"
import { handleError } from "../../../../utils/errorResponse"
import { resolveDatetime } from "../../../../helpers/order.helper"

const { TextArea } = Input
const { Option } = Select

export default function NoticeForm(props) {
  const currentData = props.data
  const { vendor_id } = getUser()
  const [form] = Form.useForm()
  const [statusSource, setStatusSource] = useState({})
  const [historySource, setHistorySource] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const onFinish = async (values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        vendor_id,
        order_id: currentData.order_id,
        data: {
          comment_text: values.comment,
          status: values.status,
          is_customer_notified: values.is_customer_notified,
          is_visible_on_front: values.is_visible_on_front
        }
      }
      const res = await sendOrderComment(payload)
      if(res.data.success) {
        message.success('add comment successfully...').then(async () => {
          const res_history = await getOrderCommentHistory({ parent_id: currentData.order_id })
          const data_history = res_history.data
          setHistorySource(data_history)
          setSubmitLoading(false)
        })
      }
    } catch (err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.warning('Failed:', errorInfo)
  }

  const resolveStatusOptions = (collection) => {
    const pending = (<Option key="pending" value="pending">Pending</Option>)
    const processing = (<Option key="processing" value="processing">Processing</Option>)
    const complete = (<Option key="complete" value="complete">Complete</Option>)
    const ready_to_ship = (<Option key="ready_to_ship" value="ready_to_ship">Ready To Ship</Option>)
    const shipped = (<Option key="shipped" value="shipped">Shipped</Option>)

    const vendorOrderStatus = getOwnVendorOrderStatus(collection)
    const options = []

    switch(vendorOrderStatus.status) {
      case 'pending':
        options.push(pending)
      break
      case 'processing':
        options.push(processing)
      break
      case 'complete':
        options.push(complete, ready_to_ship, shipped)
      break
      default:
        options.push(pending)
      break
    }

    return options
  }

  const getOwnVendorOrderStatus = (collection) => {
    return collection.vendor_sales.find((item) => item.vendor_id === vendor_id)
  }

  const resolveIsNotified = (val) => {
    if(val) {
      return "Is Notified"
    }
    return "Not Notifed"
  }

  const resolveText = (val) => {
    const handleCancel = ['canceled', 'closed']
    if(handleCancel.includes(getOwnVendorOrderStatus(statusSource).status)) {
      return `Cancel Reason: ${val}`
    }
    return val
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  )

  const loadCommentHistory = () => {
    if(!_.isEmpty(historySource)) {
      return <List 
        size="small"
        dataSource={historySource}
        pagination={false}
        renderItem={item => (
          <List.Item 
            key={item.entity_id}
          >
            <Space split={<Divider type="vertical" />}>
              {resolveText(item.comment)}
              <IconText icon={FieldTimeOutlined} text={resolveDatetime(item.created_at)} key="list-vertical-time" />
              <IconText icon={MailOutlined} text={resolveIsNotified(item.is_customer_notified)} key="list-vertical-mail" />
              <IconText icon={SafetyCertificateOutlined} text={item.status} key="list-vertical-stat" />
            </Space>
          </List.Item>
        )}
      />
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res_status = await getOrderStatus({order_id: currentData.order_id })
        const data_status = res_status.data
        const vendor_order = data_status.vendor_sales.find((item) => item.vendor_id === vendor_id)
        setStatusSource(data_status)
        const res_history = await getOrderCommentHistory({ parent_id: currentData.order_id, by_status: vendor_order.status })
        const data_history = res_history.data
        setHistorySource(data_history)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [currentData, vendor_id])

  return (
    _.isEmpty(statusSource) ? <Spin spinning={loading}><Empty /></Spin> :
    <>
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select>
            { resolveStatusOptions(statusSource) }
          </Select>
        </Form.Item>
        <Form.Item label="Comment" name="comment" rules={[{ required: true }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="is_customer_notified" valuePropName="checked">
          <Checkbox>Notify Customer by Email</Checkbox>
        </Form.Item>
        <Form.Item name="is_visible_on_front" valuePropName="checked">
          <Checkbox>Visible on Storefront</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitLoading}>Submit Comment</Button>
        </Form.Item>
      </Form>
      { loadCommentHistory() }
    </>
  )
}