import { request } from "../request"

const prefix_url = '/api/notifications/'

export function getNotification(params) {
  return request.get(prefix_url+'getNotification', params)
}

export function getFiveLatestNotification(params) {
  return request.get(prefix_url+'getFiveLatestNotification', params)
}

export function updateOneNotification(params) {
  return request.put(prefix_url+'updateOneNotification', params)
}

export function updateAllNotification(params) {
  return request.put(prefix_url+'updateAllNotification', params)
}

export function getOrderId(params) {
  return request.get(prefix_url+'getOrderId', params)
}

export function deleteNotification(params) {
  return request.post(prefix_url+'deleteNotification', params)
}

