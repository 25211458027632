import React, { useState } from "react"
import { ProTable } from '@ant-design/pro-components'
import { Button, Image, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from "lodash"

import { MAGENTO_META_URL, PUBLIC_URL } from '../../const/config'

import { getTable, getCategories, getBrands } from "../../api/product.api"
import storePersist from "../../utils/storePersist"
import storeKeys from "../../utils/storeKeys"

export default function ProductTable(props) {
  const pageSize = 10
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)

  const product_columns = [
    {
      title: '',
      dataIndex: ['pe_flat', 'thumbnail'],
      key: 'image',
      width: '120px',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: (text) => {
        if(text !== '-') 
          return <Image 
              className='product_img' 
              src={MAGENTO_META_URL+'/catalog/product'+text}
              fallback={PUBLIC_URL+"/img/default-image.png"}
            />

        return '';
      },
      hideInSearch: true,
    },
    {
      title: 'Name',
      dataIndex: ['pe_flat', 'name'],
      className: 'product_name',
      key: 'name',
      width: '200px',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      sorter: true,
      hideInSearch: true,
      render: (text, record, index) => {
        let price = record.product != null ? parseFloat(record.product.price).toFixed(2) : "-";
        let qty = record.product_stock != null ? record.product_stock.qty : "-";
        return <div>
            <Col xs={24}>{text}</Col>
            <Col xs={24} xl={0} className="product_sku">SKU: {record.sku}</Col>
            <Col xs={24} xl={0} className="product_price">RM {price}</Col>
            <Col xs={24} lg={0}><div className="product_stock">Qty: {qty}</div></Col>
          </div>
      }
    },
    {
      title: 'SKU',
      dataIndex: ['sku'],
      key: 'sku',
      width: '200px',
      responsive: ['xl','xxl'],
      hideInSearch: false,
      sorter: true,
    },
    {
      title: 'Variations',
      dataIndex: ['variations'],
      key: 'variations',
      width: '120px',
      responsive: ['xl','xxl'],
      hideInSearch: true,
    },
    {
      title: 'Price',
      dataIndex: ['pe_flat', 'price'],
      key: 'price',
      width: '120px',
      responsive: ['xl','xxl'],
      render: (text) => {
        return <>
          <div style={{ display: 'flex' }}>
            <div>RM</div>&nbsp;
            <div>{text && !isNaN(text) ? parseFloat(text).toFixed(2) : '0.00'}</div>
          </div>
        </>
      },
      hideInSearch: true,
      sorter: true,
    },
    {
      title: 'Special Price',
      dataIndex: ['pe_flat', 'special_price'],
      key: 'special_price',
      width: '100px',
      responsive: ['xl','xxl'],
      render: (text) => {
        return <>
          <div style={{ display: 'flex' }}>
            <div>RM</div>&nbsp;
            <div>{text && !isNaN(text) ? parseFloat(text).toFixed(2) : '0.00'}</div>
          </div>
        </>
      },
      hideInSearch: true,
    },
    {
      title: 'Special Date From',
      dataIndex: ['pe_flat', 'special_from_date'],
      key: 'special_from_date',
      width: '100px',
      responsive: ['xl','xxl'],
      render: (text, record, index) => {
        if(!_.has(record, 'pe_flat') || _.isNull(record.pe_flat)) {
          return <>-</>
        }
        let fromDateTime = record.pe_flat.special_from_date
        if(_.isUndefined(fromDateTime) || _.isNull(fromDateTime)) {
          return <>-</>
        }
        let fromDate = moment(fromDateTime).format('DD MMM YYYY')
        return <>{fromDate}</>
      },
      hideInSearch: true,
    },
    {
      title: 'Special Date To',
      dataIndex: ['pe_flat', 'special_to_date'],
      key: 'special_to_date',
      width: '100px',
      responsive: ['xl','xxl'],
      render: (text, record, index) => {
        if(!_.has(record, 'pe_flat') || _.isNull(record.pe_flat)) {
          return <>-</>
        }
        let toDateTime = record.pe_flat.special_to_date
        if(_.isUndefined(toDateTime) || _.isNull(toDateTime)) {
          return <>-</>
        }
        let toDate = moment(toDateTime).format('DD MMM YYYY')
        return <>{toDate}</>
      },
      hideInSearch: true,
    },
    {
      title: 'Stock',
      dataIndex: ['pe_stock', 'qty'],
      key: 'stock',
      width: '70px',
      responsive: ['xl','xxl'],
      hideInSearch: true,
    },
    {
      title: 'Sales',
      dataIndex: ['pe_sold', 'sold_amount'],
      key: 'sales',
      width: '70px',
      responsive: ['xl','xxl'],
      hideInSearch: true,
    },
    {
      title: 'Product',
      hideInTable: true,
      dataIndex: ['pe_flat', 'name'],
      key: 'search_name',
    },
    {
      title: 'Category',
      hideInTable: true,
      dataIndex: ['categories'],
      key: 'categories',
      fieldProps: {
        fieldNames: {
          children: 'children_data',
          label: 'name',
          value: 'id'
        },
        changeOnSelect: true,
        showSearch: true
      },
      valueType: 'cascader',
      request: async () => {
        const categories = storePersist.getWithExpiry(storeKeys.categories)
        if(categories) {
          return categories.children_data
        }
        const res = await getCategories()
        storePersist.setWithExpiry(storeKeys.categories, res.data)
        return res.data.children_data
      }
    },
    /* Purpose for search field only*/
    {
      title: 'Price From',
      hideInTable: false,
      dataIndex: ['pricefrom'],
    },
    {
      title: 'Status',
      hideInTable: false,
      dataIndex: ['status'],
      valueType: 'select', 
      request: async () => [
        {
          label: '',
          value: null,
        },
        {
          label: 'Enable',
          value: 1,
        },
        {
          label: 'Disable',
          value: 2,
        }
      ]
    },
    {
      title: 'Brand',
      hideInTable: true,
      dataIndex: ['mgs_brand'],
      fieldProps: {
        fieldNames: {
          label: 'name',
          value: 'option_id'
        },
        showSearch: true
      },
      valueType: 'select',
      request: async () => {
        const brands = storePersist.getWithExpiry(storeKeys.brands)
        if(brands) {
          return brands
        }
        const res = await getBrands()
        storePersist.setWithExpiry(storeKeys.brands, res.data)
        return res.data
      }
    },
    {
      title: 'Price To',
      hideInTable: false,
      dataIndex: ['priceto'],
    }
  ]

  // const child_product_columns = [
  //   {
  //     title: 'Name',
  //     dataIndex: ['child_pe_flat', 'thumbnail'],
  //     key: 'image',
  //     width: '120px',
  //     responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
  //     render: (text) => {
  //       if(text !== '-') 
  //         return <Image className='product_img' src={MAGENTO_META_URL+'/catalog/product'+text} fallback={PUBLIC_URL+"/img/default-image.png"} />

  //       return '';
  //     },
  //     hideInSearch: true,
  //   },
  //   {
  //     title: '',
  //     dataIndex: ['child_pe_flat', 'name'],
  //     className: 'product_name',
  //     key: 'name',
  //     responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
  //     hideInSearch: true,
  //     render: (text, record, index) => {
  //       let price = record.product != null ? parseFloat(record.product.price).toFixed(2) : "-";
  //       let qty = record.product_stock != null ? record.product_stock.qty : "-";
  //       return <div>
  //           <Col xs={24}>{text}</Col>
  //           <Col xs={24} xl={0} className="product_sku">SKU: {record.sku}</Col>
  //           <Col xs={24} xl={0} className="product_price">RM {price}</Col>
  //           <Col xs={24} lg={0}><div className="product_stock">Qty: {qty}</div></Col>
  //         </div>
  //     }
  //   },
  //   {
  //     title: 'SKU',
  //     dataIndex: ['Product', 'sku'],
  //     key: 'sku',
  //     width: '200px',
  //     responsive: ['xl','xxl'],
  //     hideInSearch: true,
  //   },
  //   {
  //     title: 'Variations',
  //     dataIndex: ['variations'],
  //     key: 'variations',
  //     width: '120px',
  //     responsive: ['xl','xxl'],
  //     hideInSearch: true,
  //   },
  //   {
  //     title: 'Price',
  //     dataIndex: ['child_pe_flat', 'price'],
  //     key: 'price',
  //     width: '120px',
  //     responsive: ['xl','xxl'],
  //     render: (text) => {
  //       return <>
  //         <div style={{ display: 'flex' }}>
  //           <div>RM</div>&nbsp;
  //           <div>{text && !isNaN(text) ? parseFloat(text).toFixed(2) : '0.00'}</div>
  //         </div>
  //       </>
  //     },
  //     hideInSearch: true,
  //   },
  //   {
  //     title: 'Stock',
  //     dataIndex: ['child_pe_stock', 'qty'],
  //     key: 'stock',
  //     width: '120px',
  //     responsive: ['xl','xxl'],
  //     hideInSearch: true,
  //   },
  //   {
  //     title: 'Sales',
  //     dataIndex: ['child_pe_sold', 'sold_amount'],
  //     key: 'sales',
  //     width: '120px',
  //     responsive: ['xl','xxl'],
  //     hideInSearch: true,
  //   }
  // ]

  // const expandedRowRender = (record, index, indent, expanded) => {
  //   return <ProTable rowKey="entity_id" 
  //     showHeader={true}
  //     // className="subItem"
  //     columns={child_product_columns} 
  //     dataSource={record.pe_childs} 
  //     pagination={false} 
  //     search={false} 
  //     onRow={(record, rowIndex) => {
  //       return {
  //         onClick: (event) => {
  //           props.history.push(`/admin/products/edit/${record.entity_id}`)
  //         }
  //       }
  //     }}
  //     options={false}/>;
  // }

  const onNewProduct = () => {
    props.history.push(`/admin/products/new`)
  }

  const fetchDatatable = async (params, sorters, filter) => {
    try {
      setLoading(true)
      const payload = { 
        filters: {
          ...params, by_status: props.tabKey
        },
        sorters,
        current: page,
        pageSize
      }
      const res = await getTable(payload)
      const res_data = res.data
      setTotal(res_data.count)
      setLoading(false)
      return {
        data: res_data.rows,
        success: true,
        total: res_data.count,
        page
      }
    } catch (err) {
      setLoading(false)
      console.error('unable to catch:', err)
    }
  }

  return (
    <div className="product_listing_list">
      <ProTable
        tableClassName="product_list_table"
        scroll={{ y: 550 }}
        search={{
          labelWidth: 'auto',
          searchText: 'Search',
          resetText: 'Clear',
          collapsed,
          onCollapse: setCollapsed,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              props.history.push(`/admin/products/edit/${record.entity_id}`)
            }
          }
        }}
        request={fetchDatatable}
        rowKey="entity_id"
        loading={loading}
        options={false}
        columns={product_columns}
        toolBarRender={() => [
          <Button className="add_product_btn" type="primary" key="new_product" onClick={onNewProduct}>
            <PlusOutlined />
            Add a New Product
          </Button>,
        ]}
        // expandable={{
        //   // expandedRowKeys: expandedRowKeys,
        //   // expandIcon: () => { return },
        //   expandedRowRender
        // }}
        pagination={{
          showSizeChanger: false,
          pageSize: pageSize,
          total: total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page) => {
            setPage(page - 1)
          }
        }}
      />
    </div>
  )
}