import React, {useEffect, useState} from "react"
import { Spin, Empty, PageHeader, Space, Form, Button, message } from "antd"
import _ from "lodash"

import { getOrder, makeInvoice } from "../../api/order.api"
import { handleError } from "../../utils/errorResponse"

import OrderAccountInformation from "../../modules/Order/information/childs/OrderAccountInformation"
import AddressInformation from "../../modules/Order/information/childs/AddressInformation"
import PaymentShippingMethod from "../../modules/Order/invoice/new/PaymentShippingMethod"
import ItemOrderedTable from "../../modules/Order/invoice/new/ItemOrderedTable"
import TotalSummary from "../../modules/Order/invoice/new/TotalSummary"

const colStyle = {display: 'flex'}
const cardStyle = {flex:1}
const labelStyle = { fontWeight: 'bold' }

export default function NewInvoiceView(props) {
  const [form] = Form.useForm()
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getOrder({entity_id: props.match.params.id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props])

  const resolveInitFormData = () => {
    return {
      invoice: {
        do_shipment: false,
        items: currentData.products
      }
    }
  }

  const resolveProducts = (collection) => {
    return collection.map((item) => {
      let newItem = Object.assign({},{})
      newItem.item_id = item.item_id
      newItem.qty = item.qty_ordered
      return newItem
    })
  }

  const onFinish = async(values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        vendor_id: currentData.vendor_id,
        order_id: currentData.order_id,
        invoice: {
          do_shipment: values.invoice.do_shipment,
          comment_text: values.invoice.comment_text,
          items: resolveProducts(values.invoice.items)
        }
      }
      const res = await makeInvoice(payload)
      const res_data = res.data
      if(_.isObject(res_data)) {
        message.success(`${res_data.message}`, 1.5)
        .then(() => {
          setSubmitLoading(false)
          props.history.push(`/admin/orders/view/${currentData.order_id}`)
        })
      }
    } catch(err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <PageHeader title="New Invoice" onBack={() => window.history.back()}>
      <Form 
        form={form}
        onFinish={onFinish} 
        autoComplete="off"
        initialValues={resolveInitFormData()}
      >
        <Space direction="vertical" size="large">
          <OrderAccountInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle}/>
          <AddressInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
          <PaymentShippingMethod data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
          <ItemOrderedTable data={currentData}/>
          <TotalSummary data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
          <Form.Item name="submit" style={{float: 'right'}}>
            <Button className="make-invoice-button" type="primary" htmlType="submit" loading={submitLoading}>Submit Invoice</Button>
          </Form.Item>
        </Space>
      </Form>
    </PageHeader>
  )
}