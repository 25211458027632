import React, { useState, useEffect } from "react"
import { Spin, Empty, Card, Space, Row, Col, Form } from "antd"

import { getBrands, getWarrantyTypes, getWarrantyPeriods } from "../../../../api/product.api"

import SpecBrand from "./SpecBrand"
import SpecCountry from "./SpecCountry"
import SpecWarrantyType from "./SpecWarrantyType"
import SpecWarrantyPeriod from "./SpecWarrantyPeriod"
import SpectWeightType from "./SpecWeightType"
import SpecWeight from "./SpecWeight"
import { ProFormDigit } from "@ant-design/pro-components"

export default function Specification({ currentData }) {
  const [loading, setLoading] = useState(true)
  const [brandOptions, setBrandOptions] = useState([])
  const [warrantyTypeOptions, setWarrantyTypeOptions] = useState([])
  const [warrantyPeriodOptions, setWarrantyPeriodOptions] = useState([])
  const [selectedWarrantyType, setSelectedWarrantyType] = useState(317)
  const [selectedWeightType, setSelectedWeightType] = useState(1)

  const fetchData = async() => {
    try {
        const [brands, warrantyTypes, warrantyPeriods] = await Promise.all([getBrands(), getWarrantyTypes(), getWarrantyPeriods()])
        setBrandOptions(brands.data)
        setWarrantyTypeOptions(warrantyTypes.data)
        setWarrantyPeriodOptions(warrantyPeriods.data)
      setLoading(false)
    } catch (err) {
      console.error('unable to catch:', err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    loading ? <Spin spinning={loading}><Empty /></Spin> :
      <div className="second_step_form">
        <Card
          title="Specification"
        >
          <Row gutter={16}>
            <Col span={12}>
              <SpecBrand style={{ width: '100%' }} brandOptions={brandOptions} currentData={currentData} />
            </Col>
            <Col span={12}>
              <SpecCountry style={{ width: '100%' }} currentData={currentData} />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12} className="warranty_weight">
              <Form.Item labelCol={{ span: 24 }}>
                <Space>
                <SpecWarrantyType warrantyTypeOptions={warrantyTypeOptions} setSelectedWarrantyType={setSelectedWarrantyType} currentData={currentData} />
                <SpecWarrantyPeriod warrantyPeriodOptions={warrantyPeriodOptions} selectedWarrantyType={selectedWarrantyType} currentData={currentData} />
                </Space>
              </Form.Item>
            </Col>

            <Col span={12} className="warranty_weight">
              <Form.Item labelCol={{ span: 24 }}>
                <Space>
                <SpectWeightType setSelectedWeightType={setSelectedWeightType} currentData={currentData} />
                <SpecWeight selectedWeightType={selectedWeightType} currentData={currentData} />
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={24}>
              <ProFormDigit
                name="stock_quantity"
                label="Quantity"
                labelCol={{ span: 24 }}
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

        </Card>
      </div>
  )
}