import React, {useState} from "react"
import { Modal, Result, Button, message } from "antd"
import { ShoppingCartOutlined } from '@ant-design/icons'
import { handleError } from "../../../utils/errorResponse"
import { arrangeShipment } from "../../../api/order.api"

export default function PopupShipment(props) {
  const [loading, setLoading] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const isVisible = props.isVisible
  const currentData = props.currentData

  const handleConfirmCancel = () => {
    setConfirmModalVisible(false)
  }

  const handleCancel = () => {
    props.setVisibleState(false)
  }

  const handleSubmit = async() => {
    try {
      const { order_id, vendor_id } = currentData
      setLoading(true)
      await arrangeShipment({ order_id, vendor_id })
      message
      .loading('Action in progress..', 2.5)
      .then(() => {
        message.success('Your shipment has been created...')
        setLoading(false)
      }).then(() => {
        setConfirmModalVisible(false)
        props.setVisibleState(false)
        props.setSubmitState(true)
      })
    } catch(err) {
      setLoading(false)
      setConfirmModalVisible(false)
      props.setVisibleState(false)
      message.warning(handleError(err))
    }
  }

  const showConfirmModal = () => {
    setConfirmModalVisible(true)
  }

  return (
    <>
      <Modal 
        onCancel={handleCancel}
        open={isVisible}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={showConfirmModal}>
            Confirm
          </Button>
        ]}
      >
        <Result
          status="success"
          icon={<ShoppingCartOutlined />}
          title="I will Arrange Drop-Off"
          subTitle="You can drop off your parcel"
        />
      </Modal>
      <Modal
        open={confirmModalVisible}
        onOk={handleSubmit}
        onCancel={handleConfirmCancel}
        title="Confirmation"
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to ship?</p>
      </Modal>
    </>
  )
}