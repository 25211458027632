import React from "react"
import { Card, Divider, Row, Col } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import CommentHistory from "./CommentHistory"

import { resolveAmount } from "../../../../helpers/order.helper"

export default function TotalSummary(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Memo Total">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions title="Credit Memo History" />
          <CommentHistory data={currentData} />
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="middle" column={1} title="Credit Memo Totals" bordered>
            <ProDescriptions.Item label="Subtotal"> RM {resolveAmount(currentData.subtotal) }</ProDescriptions.Item>
            <ProDescriptions.Item label="Shipping & Handling"> RM { resolveAmount(currentData.shipping_amount)}</ProDescriptions.Item>
              { currentData.discount_amount ? <ProDescriptions.Item label="Discount"> - RM { resolveAmount(currentData.discount_amount) }</ProDescriptions.Item> : 'RM'}
              <Divider dashed />
            <ProDescriptions.Item label="Adjustment Refund"> RM { resolveAmount(currentData.adjustment_positive)}</ProDescriptions.Item>
            <ProDescriptions.Item label="Adjustment Fee"> RM { resolveAmount(currentData.adjustment_negative) }</ProDescriptions.Item>
              <Divider dashed />
            <ProDescriptions.Item label="Grand Total"> RM { resolveAmount(currentData.credit_total_refunded) }</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}