import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Empty, Form, Select, Space, Spin, Row, Col, Input } from 'antd'
import _ from "lodash"

import storePersist from '../../../../utils/storePersist'
import storeKeys from '../../../../utils/storeKeys'

import { getCategories } from '../../../../api/product.api'
import { CLOUDFLARE_URL } from '../../../../const/config'

import "../../form.css"

const { Option } = Select;

export default function StepFirstForm({ formRef }) {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState({})
  const [subCategories, setSubCategories] = useState([])
  const [thirdCategories, setThirdCategories] = useState([])

  const fetchInitialData = async() => {
    try {
      let res_data = {}
      const categories = storePersist.getWithExpiry(storeKeys.categories)
      if(categories) {
        res_data = categories
      } else {
        const res = await getCategories()
        res_data = res.data
        storePersist.setWithExpiry(storeKeys.categories, res_data)
      }
      if (_.isObject(res_data)) {
        setCategories(res_data)
        setLoading(false)
      }      
    } catch (err) {
      setLoading(false)
      console.error('unable to catch:', err)
    }
  }

  const getFirstLevelCategories = () => {
    return (<Space size={[4, 8]} wrap>
      {
        _.isObject(categories) ? categories.children_data.map((item) => {
          return (
            <Button
              className="btn-category"
              key={item.id}
              value={item.id}
              onClick={handleFirstLevelCategory}
              style={{
                height: '50px'
              }}
            >
              <Avatar
                src={`${CLOUDFLARE_URL}/catalog/category/${item.image}`}
                preview="false"
                width={50}
                onError={e => {
                  if (e) {
                    e.target.src = ''
                  }
                }}
              />
              <p>{item.name}</p>
            </Button>
          )
        }) : null
      }
    </Space>)
  }

  const handleFirstLevelCategory = (e) => {
    let val = e.currentTarget.value
    formRef?.current?.setFieldsValue({
      first_category: parseInt(val),
      sub_category: null,
      third_category: null
    })
    const nextCategory = categories.children_data.filter((item) => item.id === parseInt(val))
    setSubCategories([])
    setSubCategories(nextCategory[0].children_data)
    setThirdCategories([])
  }

  const handleSubLevelCategory = (val) => {
    formRef?.current?.setFieldsValue({
      third_category: null
    })
    const nextCategory = subCategories.filter((item) => item.id === parseInt(val))
    setThirdCategories(nextCategory[0].children_data)
  }

  const getSubLevelCategories = (
    <Form.Item label="Sub-category:" name="sub_category">
      <Select onChange={handleSubLevelCategory}>
        {subCategories.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))
        }
      </Select>
    </Form.Item>
  )

  const getThirdLevelCategories = (
    <Form.Item label="3rd Level Category:" name="third_category">
      <Select>
        {thirdCategories.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))
        }
      </Select>
    </Form.Item>
  )

  useEffect(() => {
    fetchInitialData()
  }, [])

  return (
    loading ? <Spin spinning={loading}><Empty /></Spin> :
    <div className="add_new_product_listing">
      <Card
        title="Product Categories"
        extra={<span>Please choose the right category for your product.</span>}
        style={{
          minWidth: 1000,
          marginBlockEnd: 16,
          maxWidth: '100%',
        }}
      >
        <Form.Item>
          {getFirstLevelCategories()}
        </Form.Item>
        <div className="sub_cat_flow">
          <Row gutter={16}>
            <Col lg={12} sm={24}>
              <Form.Item>
                {
                  _.isEmpty(subCategories) ? <></> : <>
                    {getSubLevelCategories}
                  </>
                }
              </Form.Item>
            </Col>
            <Col lg={12} sm={24}>
              <Form.Item>
                {
                  _.isEmpty(thirdCategories) ? <></> : <>
                    {getThirdLevelCategories}
                  </>
                }
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item name="first_category">
          <Input type="hidden" />
        </Form.Item>
      </Card>
    </div>
  )
}