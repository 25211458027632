import React from "react"

import {ProForm, ProCard, ProFormList, ProFormText } from '@ant-design/pro-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Row } from "antd";

import { debounce } from 'lodash';

// Caching
let selectedSkuOptions = []

export default function VariationOptions({ formRef, specList, setSpecList }) {

  const getSpecIndex = (id) => {
    return specList.findIndex(item => item.id === id)
  }

  const addSpec = (defaultValue, insertIndex, count) => {
    const id = insertIndex
    setSpecList(specList.concat([{ id }]))
  }

  const removeSpec = (index, count) => {
    const { specId } = specList[getSpecIndex(index)]
    selectedSkuOptions = selectedSkuOptions.filter(i => i !== specId)
    let specListFilter = specList.filter(item => item.id !== index)
    if(specListFilter && specListFilter.length > 0){
      specListFilter.forEach((dataTemp, idxTemp1) => {
        dataTemp.specId = 0
        dataTemp.id = 0
      });
    }
    setSpecList(specListFilter)
  }

  function removeSubSpec(id, subIndex) {
    const index = getSpecIndex(id)
    specList[index].subSpecList.splice(subIndex, 1)

    specList[index].subSpecList.forEach((data, index) => {
      data['subSpecId'] = index;
    });

    setSpecList([...specList])
  }

  const handleVariationChange = (value, index) => {
    specList[index].value = value
    specList[index].specId = index

    //delete specList[index].subSpecList

    
    if(specList && specList[index] && specList[index].subSpecList && specList[index].subSpecList.length > 0){
      specList[index].subSpecList.forEach((data, index) => {
        data['pValue'] = value;
      });
    }

    selectedSkuOptions[index] = Number(index)
    setSpecList([...specList])
  }

  const handleVariationChangeDesc = (value, index) => {
    specList[index].description = value

    setSpecList([...specList])
  }


  const handleAddSubSpecName = (e, index, subIndex) => {
    e.preventDefault();
    const value = e.target.value;
    const pValue = specList[index].value
    const { specId } = specList[index]

    const CurrntSubSpecList = specList[index].subSpecList || []
    const isRepetition = CurrntSubSpecList.filter(c => c.subSpecId === subIndex).length > 0

    if (isRepetition) {
      CurrntSubSpecList.map((item) => {
        if(item.subSpecId === subIndex) {
          item.value = value
        }
        return item
      })
    } else {
      CurrntSubSpecList.push({
        pValue,
        value,
        img: '',
        price: '',
        stock: '',
        weight: '',
        specId,
        subSpecId: subIndex,
      })
    }

    specList[index].subSpecList = CurrntSubSpecList

    // console.log('CurrntSubSpecList', CurrntSubSpecList)

    setSpecList([...specList])
  }

  const handleAddSubSpecNameDesc = (e, index, subIndex) => {
    e.preventDefault();
    const value = e.target.value;
    const pValue = specList[index].value
    const pDescription = specList[index].description
    const { specId } = specList[index]

    const CurrntSubSpecList = specList[index].subSpecList || []
    const isRepetition = CurrntSubSpecList.filter((c, cIndex) => cIndex === subIndex).length > 0

    if (isRepetition) {
      CurrntSubSpecList.map((item, cIndex) => {
        if(cIndex === subIndex) {
          item.description = value
        }
        return item
      })
    } else {
      CurrntSubSpecList.push({
        pValue,
        value,
        img: '',
        price: '',
        stock: '',
        weight: '',
        specId,
        subSpecId: subIndex,
        description: pDescription
      })
    }

    specList[index].subSpecList = CurrntSubSpecList

    // console.log('CurrntSubSpecList', CurrntSubSpecList)

    setSpecList([...specList])
  }

  return (
    <ProFormList
      name="variations"
      creatorButtonProps={{
        creatorButtonText: 'Add New Variation',
        icon: <PlusCircleOutlined/>
      }}
      min={0}
      max={2}
      copyIconProps={false}
      itemRender={({ listDom, action }, { index }) => (
        <ProCard
          bordered
          style={{ marginBlockEnd: 8 }}
          title={`Variation ${index + 1}`}
          extra={action}
          bodyStyle={{ paddingBlockEnd: 0 }}
        >
          {listDom}
        </ProCard>
      )}
      onAfterAdd={addSpec}
      onAfterRemove={removeSpec}
    >
      {(_, idxLv1) => {
        return (
          <>
            <div style={{display:'flex'}}>
              <ProFormText style={{ padding: 0 }} width="md" name="name" label="Name" onChange={debounce((e) => {handleVariationChange(e.target.value, idxLv1)}, 350)} />
              <ProFormText style={{ padding: 0 }} width="md" name="description" label="Description" onChange={debounce((e) => {handleVariationChangeDesc(e.target.value, idxLv1, e)}, 350)} />
            </div>
            <ProForm.Item isListField style={{ marginBlockEnd: 0 }} label="Options,Description">
            <ProFormList
              name="items"
              min={1}
              copyIconProps={false}
              deleteIconProps={{ tooltipText: 'Remove' }}
              creatorButtonProps={{
                creatorButtonText: 'Add Options',
                icon: <PlusCircleOutlined />,
                type: 'dashed',
                style: { width: 'unset' },
              }}
              itemRender={({ listDom, action }) => (
                <div>
                  <Row>
                  {listDom}
                  {action}
                  </Row>
                </div>
              )}
              onAfterRemove={(index, count) => removeSubSpec(idxLv1, index)}
            >
              {(f, idxLv2, action) => {
                return (
                  <div style={{display:'flex'}}>
                    <ProFormText allowClear={false} width="md" name="optionName" onChange={debounce((e) => {handleAddSubSpecName(e, idxLv1, idxLv2)}, 350)} />
                    <ProFormText allowClear={false} width="md" name="description" onChange={debounce((e) => {handleAddSubSpecNameDesc(e, idxLv1, idxLv2)}, 350)} />
                  </div>
                );
              }}
            </ProFormList>
            </ProForm.Item>
          </>
        );
      }}
    </ProFormList>
  )
}