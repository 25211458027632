import React, { useState, useRef } from "react"
import { ProTable } from '@ant-design/pro-components'
import { Button, message, Table, Space, Select } from 'antd'
import moment from 'moment'
import { RocketOutlined } from '@ant-design/icons'

import { getNotification, deleteNotification } from "../../api/notification.api"

const { Option } = Select

export default function NotificationTable(props) {
  const pageSize = 10
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [actionKey, setActionKey] = useState('')
  const [actionLoading, setActionLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const actionRef = useRef(null)

  const actions = ['delete']

  const notification_columns = [
    {
      title: 'Notification ID',
      hideInTable: true,
      hideInSearch: true,
      dataIndex: ['notification_id'],
      key: 'notification_id'
    },
    {
      title: 'Created at',
      dataIndex: ['created_at'],
      key: 'created_at',
      search: false,
      width: '60px',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      responsive: ['xl','xxl'],
      render: val => moment(val).format('DD MMM YYYY HH:mm:ss')
    },
    {
      title: 'Created at',
      dataIndex: ['created_at'],
      key: 'created_at',
      valueType: 'dateRange',
      hideInTable: true,
      search: {
        transform: (value) => {
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
    {
      title: 'Message',
      dataIndex: ['message'],
      key: 'message',
      width: '120px',
      responsive: ['xl','xxl'],
      sorter: (a, b) => a.message.localeCompare(b.message),
      render: (text) => {
        return <><div dangerouslySetInnerHTML={{__html: text}} /></>
      }
    },


  ]

  const onClickExecute = async (e) => {

    try {
      if(!actionKey || 0 >= selectedRowKeys.length) {
        message.warning('please select action and record(s) before click execute...')
        return
      }
      setActionLoading(true)
      if(actions.includes(actionKey)) {
        await handleAction()
        return
      }
      setActionLoading(false)
    } catch(err) {
      setActionLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handleAction = async () => {
    try {
      if(actionKey === 'delete') {
        await deleteNotification({notification_id:selectedRowKeys})
        actionRef.current.reloadAndRest()
      }
      setActionLoading(false)
      setSelectedRowKeys([])
      return true
    } catch (err) {
      setActionLoading(false)
      const action = action_options.filter((item) => item.value === actionKey )[0]
      message.warning(`No File Generated: Selected Item(s) does not have ${action.message}`)
    }
  }

  const action_options = [
    { title:'Delete notification', value: 'delete', message: 'delete' },
   
  ]

  const onChangeActionOptions = (item) => {
    setActionKey(item)
  }

  

  const selectOptions = (<Select style={{ width:'180px'}}placeholder="Select an action" onChange={onChangeActionOptions}>
  { action_options.map((item, index) => {
    return (
      <Option key={index} value={item.value}>{item.title}</Option>
    )
  }) }
  </Select>)



  const fetchDatatable = async (params, sorter, filter) => {
    try {
      const payload = {
        page,
        size: pageSize,
        ...params
      }
      const res = await getNotification(payload)
      const res_data = res.data
      setTotal(res_data.count)
      setLoading(false)
      return {
        data: res_data.rows,
        success: true,
        total: res_data.count,
        page
      }
    } catch (err) {
      setLoading(false)
      console.error('unable to catch:', err)
    }
  }

  return (
    <>
      <ProTable
        actionRef={actionRef}
        rowSelection={{ 
          ...rowSelection,
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT]
        }}
        tableAlertRender={({ selectedRowKeys }) => (
          <Space size={24}>
            <span>
              Selected {selectedRowKeys.length} rows
            </span>
          </Space>
        )}

        tableClassName="product_list_table"
        scroll={{ y: 550 }}
        search={{
          labelWidth: 'auto',
          collapsed,
          onCollapse: setCollapsed,
        }}  
        request={fetchDatatable}
        rowKey="notification_id"
        loading={loading}
        options={false}
        columns={notification_columns}
        toolBarRender={() => [
          selectOptions,
          <Button type="primary" key="execute" onClick={onClickExecute} loading={actionLoading}>
            Execute
            <RocketOutlined />
          </Button>,
        ]}
        pagination={{
          showSizeChanger: false,
          pageSize: pageSize,
          total: total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page) => {
            setPage(page - 1)
          }
        }}
      />
      </>

  )
}