import React, {useState , useEffect, useRef} from 'react'
import { ProTable } from '@ant-design/pro-components';
import moment from 'moment'
import _ from "lodash"
import { Button, Select, Image, message, Modal, Col } from 'antd'
import { RocketOutlined } from '@ant-design/icons'
import { getTable } from "../../api/order.api"
import { MAGENTO_META_URL } from '../../const/config'
import fileDownload from 'js-file-download'

import { bulkDownloadInvoice, bulkDownloadShipment, bulkDownloadCreditMemo, bulkDownloadShippingLabel } from '../../api/download.api'
import { cancelOrders, getShipmentDetailForLabel } from '../../api/order.api'
import PopupShipment from "./shipment/PopupShipment"
import PopupWaybill from "./shipment/PopupWaybill"

const { Option } = Select

export default function OrderTable(props) {
  const pageSize = 10
  const [selectedRowKeysForPrint, setSelectedRowKeysForPrint] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowKeysForLabel, setSelectedRowKeysForLabel] = useState([])
  const [actionKey, setActionKey] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [shipmentVisible, setShipmentVisible] = useState(false)
  const [isSubmitShipment, setIsSubmitShipment] = useState(false)
  const [selectedArrange, setSelectedArrange] = useState({})
  const [waybillVisible, setWaybillVisible] = useState(false)
  const [selectedWayBill, setSelectedWayBill] = useState({})
  
  const actionRef = useRef(null)

  const download_actions = ['invoice', 'shipment', 'creditmemo', 'shipping_label']

  const order_columns = [
    {
      title: 'Product(s)',
      dataIndex: ['order_grid', 'shipping_name'],
      key: 'shipping_name',
      className: 'shipping_name',
      hideInSearch: true,
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl']
    },
    {
      title: 'ID',
      hideInTable: true,
      dataIndex: ['order_grid', 'increment_id'],
      key: 'increment_id'
    },
    {
      title: 'Shipping Name',
      hideInTable: true,
      dataIndex: ['order_grid', 'shipping_name'],
      key: 'shipping_name',
    },
    {
      title: 'Billing Name',
      hideInTable: true,
      dataIndex: ['order_grid', 'billing_name'],
      key: 'billing_name',
    },
    {
      title: 'Purchase Date',
      dataIndex: 'created_at',
      key: 'created_at',
      valueType: 'dateTime',
      search: false,
      render: val => '',
      width: '130px',
      responsive: ['xl','xxl']
    },
    {
      title: 'Purchase Date',
      dataIndex: 'created_at',
      valueType: 'dateTimeRange',
      hideInTable: true,
      search: {
        transform: (value) => {
          return {
            start_time: value[0],
            end_time: value[1],
          };
        },
      },
    },
    {
      title: 'Order Total (RM)',
      dataIndex: 'grand_total',
      key: 'grand_total',
      hideInSearch: true,
      width: '140px',
      className:'order_total',
      responsive: ['xl','xxl'],
      render: (text) => {
        return text ? parseFloat(text).toFixed(2) : 0.00
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      hideInSearch: true,
      width: '160px',
      className:'order_status',
      responsive: ['lg', 'xl','xxl'],
      render: (text) => {
        return '';
      }
    },
    {
      title: 'Countdown',
      dataIndex: 'created_at',
      key: 'countdown',
      hideInSearch: true,
      width: '100px',
      className: 'order_countdown',
      responsive: ['xxl'],
      render: (text) => {
        return '';
      }
    },
    {
      title: 'Channel',
      dataIndex: 'shipping_company',
      key: 'channel',
      hideInSearch: true,
      width: '120px',
      className: 'order_channel',
      responsive: ['xxl'],
      render: (text) => {
        return '';
      }
    },
    { 
      title: 'Actions', 
      dataIndex: ['order_grid', 'increment_id'],
      key: 'action',
      align: 'center',
      hideInSearch: true,
      className: 'order_rightAction',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: val => "Order ID: " + val,
      width:'160px'
    },
    // {
    //   title: 'Action',
    //   valueType: 'option',
    //   hideInSearch: true,
    //   key: 'operation',
    //   render: (index, record) => (
    //     <>
    //     <Button type="link" key="detail" onClick={() => props.history.push(`/admin/orders/view/${record.order_id}`) }>View</Button>
    //     <Button type="link" key="download" onClick={async () => await handleSingleDownload(record) }>Download</Button>
    //     </>
    //   )
    // },
  ]
  
  const product_columns = [
    {
      title: 'Image',
      dataIndex: ['product', 'thumbnail'],
      key: 'image',
      className: 'table-img',
      width: '120px',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: (text) => {
        return <Image className='product_img' src={MAGENTO_META_URL+'/catalog/product'+text} />
      }
    },
    {
      title: 'Name',
      dataIndex: ['name'],
      key: 'name',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: (text, record, index) => {
        let purchase_datetime = moment(record.order_created_at).format('DD MMM YYYY HH:mm:ss')
        let order_price = (record.row_total - record.discount_amount).toFixed(2)

        let finalStatus = ''
        let order_status = record.status
          
        if(index === 0) {
          switch(order_status)
          {
            case 'pending_payment':
              finalStatus = "Unpaid";
            break;
            default:
              finalStatus = order_status;
              break;
          }
        }

        return <div>
          <Col xs={24}>{text}</Col>
          <Col xs={24} xl={0} className="order_date">{purchase_datetime}</Col>
          <Col xs={24} xl={0} className="order_price">RM {order_price}</Col>
          <Col xs={24} lg={0}><div className={order_status + ' order_status_txt'}>{finalStatus}</div></Col>
        </div>
      }
    },
    {
      title: 'Quantity',
      dataIndex: ['qty_ordered'],
      key: 'quantity',
      width: '60px',
      className: 'prod_qty',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: (text) => {
        return <span>{'x '} {text}</span>
      }
    },
    {
      title: 'Purchase Date',
      dataIndex: 'order_created_at',
      //valueType: 'dateTimeRange',
      //hideInTable: true,
      width: '130px',
      align: 'center',
      responsive: ['xl','xxl'],
      render: (text, record, index) => {
        if(index === 0) {
          let purchase_datetime = record.order_created_at
          let purchase_date = moment(purchase_datetime).format('DD MMM YYYY')
          let purcase_time = moment(purchase_datetime).format('HH:mm:ss')
          return <>{purchase_date}<br />{purcase_time}</>
        }
          
        return '';
      }
    },
    {
      title: 'Row Total',
      dataIndex: ['row_total'],
      key: 'row_total',
      width: '140px',
      className: 'prod_total',
      responsive: ['xl','xxl'],
      render: (text, record) => {
        return (record.row_total - record.discount_amount).toFixed(2)
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      hideInSearch: true,
      width: '160px',
      className:'prod_status',
      responsive: ['lg','xl','xxl'],
      render: (text, record, index) => {
        if(index === 0) {
          let finalStatusCls = text;
          
          switch(record.finalstatus)
          {
            case 'To Ship':
              finalStatusCls = "to_ship";
            break;
            case 'Shipping':
              finalStatusCls = "shipping";
            break;
            default:
              finalStatusCls = text;
              break;
          }

          return <div className={finalStatusCls + ' order_status_txt'}>{record.finalstatus}</div>;
        }
        else return '';
      }
    },
    {
      title: 'Countdown',
      dataIndex: 'order_created_at',
      key: 'countdown',
      hideInSearch: true,
      width: '100px',
      className: 'prod_countdown',
      responsive: ['xxl'],
      render: (text, record, index) => {
        if(index === 0) {
          if(record.status === "processing") {
            let purchase_date = moment(record.order_created_at);
            let cur_date = moment();
            
            return 14 - cur_date.diff(purchase_date, 'days') 
          }
        }
        return '';
      }
    },
    {
      title: 'Channel',
      dataIndex: 'order_channel',
      key: 'channel',
      hideInSearch: true,
      width: '120px',
      className: 'prod_channel',
      responsive: ['xxl'],
      render: (text, record, index) => {
        if(index === 0) {
          if(text.toLowerCase() === "lbm")
            return "Lambo Move"
            
          return text
        }
        
        return ''
      }
    },
    { 
      title: 'Action', 
      key: 'operation',
      valueType: 'option',
      hideInSearch: true,
      className: 'rightAction',
      width:'160px',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg','xl','xxl'],
      render: (text, record, index) => {
        // if(index == 0) {
        //   return <>
        //     <Button type="link" className='btnDownload' key="download" onClick={async () => await handleSingleDownload(record) }>Download PDF</Button>
        //     <Button type="link" className='btnDetail' key="detail" onClick={() => props.history.push(`/admin/orders/view/${record.order_id}`) }>More Details</Button>
        //     </>
        // }
        if(index === 0) {
          if(props.tabKey === 'to_ship') {
            if(canShowButtonsForToShip(record)) {
              return showForToShipWithShipment(record)
            } else {
              return showForToShipWithoutShipment(record)
            }
          }
          if(props.tabKey === 'all') {
            if(canArrangeShip(record)) {
              return (
                <Button className='btn_order_action'
                  onClick={async () => await showArrangeShipment(record)}
                >
                  Arrange<br />Shipment
                </Button>
              )
            }
          }
        }

        return ''
      }      
    }
  ]

  const action_options = [
    // { title:'Cancel', value: 'cancel', message: 'cancel' },
    { title:'Print Invoices', value: 'invoice', message: 'Invoice(s)' },
    { title:'Print Packing Slips', value: 'shipment', message: 'Shipment(s)' },
    { title:'Print Credit Memos', value: 'creditmemo', message: 'Credit Memo(s)' },
    { title:'Print Shipping Labels', value: 'shipping_label', message: 'Shipping Label(s)' }
  ]

  const onChangeActionOptions = (item) => {
    setActionKey(item)
  }

  const selectOptions = (<Select style={{ width:'180px'}}placeholder="Select an action" onChange={onChangeActionOptions}>
  { action_options.map((item, index) => {
    return (
      <Option key={index} value={item.value}>{item.title}</Option>
    )
  }) }
  </Select>)

  const expandedRowRender = (record, index, indent, expanded) => {
    return <ProTable rowKey="item_id" 
      showHeader={false}
      className="subItem"
      columns={product_columns} 
      dataSource={filterVariationProducts(record.vendor_order_items)} 
      pagination={false} 
      search={false} 
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: (event) => {
            props.history.push(`/admin/orders/view/${record.order_id}`)
          }
        }
      }}
      options={false}/>;
  }

  const rowRecordMap = (collection) => {
    if(_.isEmpty(collection)) {
      return []
    }
    return collection.map((item) => {
      let { entity_id, order_id } = item
      return {
        vendor_order_id: entity_id, order_id
      }
    })
  }

  const resolveLabelRows = (collection) => {
    if(_.isEmpty(collection)) {
      return []
    }
    return collection.map((item) => {
      return item.entity_id
    })
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeysForPrint(rowRecordMap(selectedRows))
    setSelectedRowKeysForLabel(resolveLabelRows(selectedRows))
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const canArrangeShip = (product_item) => {
    const valid_status = ['processing', 'pending']
    const record = currentData.find((item) => item.entity_id === product_item.vendor_order_id)

    return valid_status.includes(record.status) && !_.isNull(record.vendor_invoice) && _.isNull(record.vendor_order_shipment)
  }

  const canShowButtonsForToShip = (product_item) => {
    const record = currentData.find((item) => item.entity_id === product_item.vendor_order_id)
    return !_.isNull(record.vendor_order_shipment)
  }

  const showForToShipWithoutShipment = (record) => {
    return (
      <>
      <Button className='btn_order_action' onClick={async () => await showShipmentDetail(record) }>
        View Shipping<br />Details
      </Button>
      <br />
      <Button className='btn_order_action' onClick={async () => await showArrangeShipment(record)}>
        Print Waybill
      </Button>
      </>
    )
  }

  const showForToShipWithShipment = (record) => {
    return (
      <>
      <Button className='btn_order_action' onClick={async () => await showShipmentDetail(record) }>
        View Shipping<br />Details
      </Button>
      <Button className='btn_order_action' onClick={async () => await showWayBill(record)}>
        Print Waybill
      </Button>
      </>
    )
  }

  const showArrangeShipment = async(product_item) => {
    const record = currentData.find((item) => item.entity_id === product_item.vendor_order_id)
    setSelectedArrange(record)
    setShipmentVisible(true)
  }

  const showWayBill = async(product_item) => {
    const selected = currentData.find((item) => item.entity_id === product_item.vendor_order_id)
    const record = await getShipmentDetailForLabel({ entity_id: selected.entity_id })
    setSelectedWayBill(record.data.result)
    setWaybillVisible(true)
  }

  const showShipmentDetail = async(product_item) => {
    const record = currentData.find((item) => item.entity_id === product_item.vendor_order_id)
    props.history.push(`/admin/orders/view/${record.order_id}`)
  }

  const onClickExecute = async (e) => {
    try {
      if(!actionKey || 0 >= selectedRowKeys.length) {
        message.warning('please select action and record(s) before click execute...')
        return
      }
      setActionLoading(true)
      if(download_actions.includes(actionKey)) {
        await handleDownloadAction()
        return
      }
      if(actionKey === 'cancel') {
        showModal()
        return
      }
      setActionLoading(false)
    } catch(err) {
      setActionLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }

  const handleDownloadAction = async () => {
    let res = null
    const timestamp = moment().format('X')
    const filename = `${actionKey}-${timestamp}.pdf`
    try {
      switch(actionKey) {
        case 'invoice':
          res = await bulkDownloadInvoice({
            targets: selectedRowKeysForPrint
          })
        break
        case 'shipment':
          res = await bulkDownloadShipment({
            targets: selectedRowKeysForPrint
          })
        break
        case 'creditmemo':
          res = await bulkDownloadCreditMemo({
            targets: selectedRowKeysForPrint
          })
        break
        case 'shipping_label':
          res = await bulkDownloadShippingLabel({
            entity_ids: selectedRowKeysForLabel
          })
        break
        default:
          res = false
        break
      }
      if(res) {
        fileDownload(res.data, filename)
      }
      setActionLoading(false)
      setSelectedRowKeys([])
      return true
    } catch (err) {
      setActionLoading(false)
      const action = action_options.filter((item) => item.value === actionKey )[0]
      message.warning(`No File Generated: Selected Item(s) does not have ${action.message}`)
    }
  }

  const handleCancel = async () => {
    try {
      setConfirmLoading(true)
      const res = await cancelOrders({ order_ids: selectedRowKeys })
      const res_data = res.data
      message.success(`Success Count: ${res_data.success}, Failed Count: ${res_data.failed}`, 1.5)
      .then(() => {
        setConfirmLoading(false)
        hideModal()
        setSelectedRowKeys([])
        window.location.reload(false)
      })
    } catch(err) {
      message.error('something went wrong...')
      return
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setActionLoading(false)
  }

  // const handleSingleDownload = async(record) => {
  //   try {
  //     const timestamp = moment().format('X')
  //     const filename = `shipmentLabel-${timestamp}.pdf`

  //     const payload = {
  //       vendor_id: record.vendor_id,
  //       entity_id: record.entity_id
  //     }

  //     const res = await downloadShippingLabel(payload)

  //     if(res) {
  //       fileDownload(res.data, filename)
  //     }

  //   } catch(err) {
  //     console.log(err)
  //     message.warning('NO FILE GENERATED')
  //   }
  // }

  const filterVariationProducts = (products) => {
    return _.reject(products, (product) => {
      return product.product_type === 'configurable' && _.isNull(product.parent_item_id)
    })
  }

  const fetchDatatable = async(params, sorters, filter) => {
    try {
      const payload = { 
        filters: {
          ...params, by_status: props.tabKey
        },
        sorters,
        current: page,
        pageSize
      }
      const res = await getTable(payload)
      const res_data = res.data

      if(res_data && res_data.rows) {
        for(let i = 0; i < res_data.rows.length; i++) {
          if(res_data.rows[i].vendor_order_items) {
            let order_channel = ''

            if(res_data.rows[i].vendor_order_shipment) {
              for (let k =0; k < res_data.rows[i].vendor_order_shipment.shiptracks.length; k++) {
                if(res_data.rows[i].vendor_order_shipment.shiptracks[k].title != null && res_data.rows[i].vendor_order_shipment.shiptracks[k].title !== "")
                  order_channel = res_data.rows[i].vendor_order_shipment.shiptracks[k].title
                  break;
              }
            }

            let finalStatus = res_data.rows[i].status
            //let finalStatusCls = 
            switch(res_data.rows[i].status) {
              case 'pending_payment':
                finalStatus = "Unpaid";
                break;
              case "pending":
                finalStatus = "To Ship";
                break;
              case "processing":
                if(res_data.rows[i].vendor_order_shipment === null || res_data.rows[i].vendor_order_shipment.length === 0) {
                  finalStatus = "To Ship";
                }
                else {
                  finalStatus = "Shipping";
                }
                break;
              case "complete":
                if(res_data.rows[i].order_grid.status === "complete") {
                  finalStatus = res_data.rows[i].status;
                }
                else {
                  finalStatus = "Shipping";
                }
                break;
              default:
                finalStatus = res_data.rows[i].status;
                break;
            }

            for(let j = 0; j < res_data.rows[i].vendor_order_items.length; j++) {
              res_data.rows[i].vendor_order_items[j]["status"] = res_data.rows[i].status;
              res_data.rows[i].vendor_order_items[j]["finalstatus"] = finalStatus;
              
              res_data.rows[i].vendor_order_items[j].order_created_at = res_data.rows[i].created_at
              res_data.rows[i].vendor_order_items[j].order_channel = order_channel
            }
          }
        }
      }

      setExpandedRowKeys(res_data.rows.map(item => { return item.order_id }))
      setTotal(res_data.count)
      setCurrentData(res_data.rows)
      setLoading(false)
      return {
        data: res_data.rows,
        success: true,
        total: res_data.count,
        page
      }
    } catch (err) {
      setLoading(false)
      console.error('unable to catch:', err)
    }
  }

  useEffect(() => {
    if(isSubmitShipment) {
      actionRef.current.reloadAndRest()
      setIsSubmitShipment(false)
    }
  }, [isSubmitShipment])

  return (
    <>
    <ProTable
      actionRef={actionRef}
      scroll={{ y: 550 }}
      search={{
        labelWidth: 'auto',
        searchText: 'Search',
        resetText: 'Clear',
        collapsed,
        onCollapse: setCollapsed,
      }}
      request={async (params = {}, sort, filter) => {
        const dataTable = await fetchDatatable(params, sort, filter)
        return Promise.resolve(dataTable)
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            props.history.push(`/admin/orders/view/${record.order_id}`)
          }
        }
      }}
      rowKey="order_id"
      loading={loading}
      rowSelection={rowSelection}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        expandIcon: () => { return },
        expandedRowRender
      }}
      className="order_table"
      options={false}
      columns={order_columns}
      rowClassName="order_table_row"
      toolBarRender={() => [
        selectOptions,
        <Button type="primary" key="execute" onClick={onClickExecute} loading={actionLoading}>
          Execute
          <RocketOutlined />
        </Button>,
      ]}
      pagination={{
        showSizeChanger: false,
        pageSize: pageSize,
        total: total,
        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
        onChange: (page) => {
          setPage(page-1)
        }
      }}
    />
    <Modal
      open={modalVisible}
      title="Confirmation"
      okText="Confirm"
      cancelText="Cancel"
      onOk={handleCancel}
      confirmLoading={confirmLoading}
      onCancel={hideModal}
      >
      <p>{`Are you sure you want to cancel these ${selectedRowKeys.length} order(s)?`}</p>
    </Modal>
    <PopupShipment
      isVisible={shipmentVisible}
      setVisibleState={setShipmentVisible}
      setSubmitState={setIsSubmitShipment}
      currentData={selectedArrange}
    />
    <PopupWaybill
      isVisible={waybillVisible}
      setVisibleState={setWaybillVisible}
      currentData={selectedWayBill}
    />
    </>
  )
}