import React, {useState, useEffect} from 'react'
import { Button, List, Skeleton, Avatar } from 'antd'
import { getGrid } from "../../../api/dashboard.api"
import { MAGENTO_META_URL } from '../../../const/config'
import { resolveShortDescription } from '../../../helpers/product.helper'

const footer = (<Button type="primary" style={{ width:"100%" }}>View All Products</Button>)

export default function GridRecentlyAddedProduct(props) {
  const [dataSource, setDataSource] = useState([])

  const fetchData = async() => {
		try {
      const res = await getGrid({ by_time: '2y', by_type: 'recently_added_product' })
      const res_data = res.data
      setDataSource(res_data)
    } catch (err) {
      console.error('unable to catch:', err)
    }
	}

	useEffect(() => {
		fetchData()
	}, [])

  return (
    <List
      size="large"
      bordered
      footer={footer}
      dataSource={dataSource}
      renderItem={item => (
        <List.Item>
          <Skeleton avatar title={false} loading={false} active>
              <List.Item.Meta
                avatar={<Avatar size={{xl: 80}} src={MAGENTO_META_URL+'/catalog/product'+item.thumbnail} />}
                title={<a href="/#">{item.name}</a>}
                description={resolveShortDescription(item.short_description)}
              />
            </Skeleton>
        </List.Item>
      )}
    />
  )
}