import React from "react"

import OrderAccountInformation from "./childs/OrderAccountInformation"
import PaymentShippingMethod from "./childs/PaymentShippingMethod"
import AddressInformation from "./childs/AddressInformation"
import ItemRefundedTable from "./childs/ItemRefundedTable"
import TotalSummary from "./childs/TotalSummary"

const colStyle = {display: 'flex'}
const cardStyle = {flex:1}
const labelStyle = { fontWeight: 'bold' }

export default function CreditMemoView(props) {
  const currentData = props.data

  return (
    <>
      <OrderAccountInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle}/>
      <br/>
      <AddressInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
      <br/>
      <PaymentShippingMethod data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
      <br/>
      <ItemRefundedTable data={currentData}/>
      <br/>
      <TotalSummary data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
    </>
  )
}