const storeKeys = {
  categories: 'categories',
  brands: 'brands',
  warrantyTypes: 'warrantyTypes',
  warrantyPeriods: 'warrantyPeriods',
  productAttributes: 'productAttributes',
  productAttributeOptions: 'productAttributeOptions'
};

export default storeKeys;
