import React, { useState } from "react"
import { Form, Upload, message } from "antd"
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons"
import _ from "lodash"

import { uploadService } from "../../../../../utils/uploader"

export default function VariationImageUpload(props) {
  const formItemName = props.formItemName
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const uploadLabel = () => {
    <div>
      {loading ? <LoadingOutlined /> : <CameraOutlined /> }
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
   return e && e.fileList
  }

  const handleUpload = async (file) => {
    try {
      setLoading(true)
      let progress = { percent: 1 }
      const formData = new FormData()
      formData.append("product_cover", file.file)
      formData.append("type", "product_cover")
      const res = await uploadService(formData)

      file.onProgress(progress)
      if(res.status === 200) {
        file.onProgress({ percent: 100})
        file.onSuccess(res.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      file.onError(err)
    }
  }

  const handleUploadChange = async (file) => {
    if(_.isEmpty(file.fileList)) {
      setImageUrl('')
    }

    if (file.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(file.file.originFileObj, imageUrl =>
        setImageUrl(imageUrl)
      );
    }
  }

  return (
    <Form.Item name={formItemName} label="Image" valuePropName="fileList" getValueFromEvent={getFile} rules={[{ required: true }]}>
      <Upload
        customRequest={handleUpload}
        onChange={handleUploadChange}
        className="avatar-uploader"
        showUploadList={false}
        listType="picture-card"
        beforeUpload={beforeUpload}
        maxCount={1}
        >
        { imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadLabel }
      </Upload>
    </Form.Item>
  )
}