import axios from 'axios'
import { getToken, logout } from './auth'

const instance = axios.create({
	baseURL: process.env.API_URL,
	timeout: 600000
})

instance.interceptors.request.use(function (config) {
	// Do something before request is sent
	config.headers.Authorization = 'Bearer ' + getToken()
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	if (error.response.status === 401) {
		logout()
		window.location.href = '/'
	}

	return Promise.reject(error);
});

export function get(url, params, config) {
	return instance.get(url, {
		params
	}, config)
}

export function post(url, data, config) {
	return instance.post(url, data, config)
}

export function put(url, data, config) {
	return instance.put(url, data, config)
}

export function del(url) {
	return instance.delete(url)
}
