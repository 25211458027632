import React from "react"

import StatCardRow from "../../modules/Dashboard/StatCardRow"
import StatGraphRow from "../../modules/Dashboard/StatGraphRow"
import StatGridRow from "../../modules/Dashboard/StatGridRow"

export default function Dashboard(props) {  
  return (
    <>
    <StatCardRow />
    <br />
    <StatGraphRow />
    <br />
    <StatGridRow />
    </>
  )
}