import React, {useState, useEffect} from "react"
import { PageHeader, Empty, Spin } from 'antd'
import _ from "lodash"

import OrderInformation from "../../modules/Order/informationDetails/View"

import { getUser } from "../../utils/auth"
import { getOrderStatus } from "../../api/order.api"

import './index.css'

export default function OrderView(props) {
  const { vendor_id } = getUser()
  const [currentData, setCurrentData] = useState({})
  const [isSubmitShipment, setIsSubmitShipment] = useState(false)
  const [isSubmitCreditMemo, setIsSubmitCreditMemo] = useState(false)
  const [isSubmitCancel, setIsSubmitCancel] = useState(false)
  const [loading, setLoading] = useState(false)

  const getOwnVendorOrder = () => {
    return currentData.vendor_sales.filter((item) => item.vendor_id === vendor_id)[0]
  }

  useEffect(() => {
    const fetchData = async() => {
      try {
        setLoading(true)
        const res = await getOrderStatus({order_id: props.match.params.id, vendor_id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props, vendor_id, isSubmitShipment, isSubmitCreditMemo, isSubmitCancel])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <PageHeader
      className="site-page-header"
      onBack={() => window.history.back()}
      title="Order View"
    >
      <OrderInformation entity_id={getOwnVendorOrder().entity_id} {...props} orderStatus={currentData} setSubmitState={setIsSubmitShipment} setCreditMemoState={setIsSubmitCreditMemo} setCancelState={setIsSubmitCancel}/>
    </PageHeader>
  )
}