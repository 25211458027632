import React, {useState, useEffect, useRef } from "react"
import { PageHeader, Spin, Empty, message } from "antd"
import { ProForm } from "@ant-design/pro-components"
import _ from "lodash"

import { getProduct, storeProduct, getBrands } from "../../api/product.api"
import { handleError } from "../../utils/errorResponse"
import { getEditFormInitialValues, getEditFormPayload } from "../../helpers/product.helper"

import EditProductForm from "../../modules/Product/EditProduct"

import "./index.css"

export default function EditProductView(props) {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [isDone, setIsDone] = useState(false)
  const [specList, setSpecList] = useState(false)
  const [brand, setBrand] = useState(false)

  const id = props.match.params.id

  const getInitialFormValues = () => {
    const newValues = getEditFormInitialValues(currentData)
    return newValues
  }

  const onFinish = async(values) => {
    try {
      setSubmitLoading(true)
      // const formData = form.getFieldsValue()
      const payload = getFormattedPayload(values)
      // console.log(payload)
      await storeProduct(payload)
      setIsDone(true)
    } catch(err) {
      console.debug(err)
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo)
  }

  const getFormattedPayload = (values) => {
    return getEditFormPayload(values, currentData, brand, specList, formRef.current.getFieldValue('description'), formRef.current.getFieldValue('short_description'))
  }

  // useEffect(() => { console.log(specList) }, [specList])

  useEffect(() => {
    let isMount = true
    if(isDone) {
      message.success(`Update Product Successfully...`, 2)
      .then(() => {
        if(isMount) {
          setSubmitLoading(false)
        }
        props.history.push(`/admin/products`)
      })
    }
    return () => {
      isMount = false
    }
  }, [isDone, props])
  
  useEffect(() => {
    let isMount = true
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getProduct({ entity_id: id })
        const brandlist = await getBrands()
        const res_data = res.data
        if(isMount) {
          setCurrentData(res_data)
          setSpecList(res_data.specList)
          setLoading(false)
          setBrand(brandlist.data)
        }
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()
    return () => {
      isMount = false
    }

  }, [id, props])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <>
      <PageHeader
        title="Edit product"
        onBack={() => window.history.back()}
      >
      <ProForm
        formRef={formRef}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialFormValues()}
      >
        <EditProductForm 
          formRef={formRef} 
          submitLoading={submitLoading} 
          currentData={currentData} 
          history={props.history}  
          specList={specList}
          setSpecList={setSpecList}
        />
      </ProForm>
      </PageHeader>
    </>
  )
}