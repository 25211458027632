import React from "react"
import { Space, Button } from "antd"

import ProductGeneral from "./childs/edit/ProductGeneral"
import ProductCategory from "./childs/edit/ProductCategory"
import ProductInformation from "./childs/edit/ProductInformation"
import PriceInformation from "./childs/edit/PriceInformation"
import Specification from "./childs/edit/Specification"
import Shipping from "./childs/edit/Shipping"
import Variation from "./childs/edit/Variation"
import VariationOption from "./childs/edit/VariationOption"

export default function EditProductForm(props) {
  const form = props.form
  const currentData = props.currentData
  const history = props.history

  return (<>
    <Space
      direction="vertical" size="middle" style={{ display: 'flex' }}
    >
      <ProductGeneral currentData={currentData}/>
      <ProductCategory form={form} currentData={currentData} />
      <ProductInformation form={form} currentData={currentData} />
      <PriceInformation currentData={currentData} />
      <Specification currentData={currentData} />
      <Shipping currentData={currentData} />
      <Variation currentData={currentData} history={history} />
      <VariationOption currentData={currentData} />
      <div className="steps-action">
        <Button type="primary" htmlType="submit" loading={props.submitLoading}>
          Update
        </Button>
      </div> 
    </Space>
  </>)
}