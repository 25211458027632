import React from "react"

import AddressInformation from "./childs/AddressInformation"
import ItemShipTable from "./childs/ItemShipTable"
import OrderAccountInformation from "./childs/OrderAccountInformation"
import PaymentShippingMethod from "./childs/PaymentShippingMethod"
import TotalSummary from "./childs/TotalSummary"

export default function ShipmentView(props) {
  const data = props.data
  const colStyle = {display: 'flex'}
  const cardStyle = {flex:1}
  const labelStyle = { fontWeight: 'bold' }

  return (
    <>
      <OrderAccountInformation data={data} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle}/>
      <br/>
      <AddressInformation data={data} colStyle={colStyle} cardStyle={cardStyle} />
      <br/>
      <PaymentShippingMethod data={data} colStyle={colStyle} cardStyle={cardStyle} />
      <br/>
      <ItemShipTable data={data} />
      <br />
      <TotalSummary data={data} colStyle={colStyle} cardStyle={cardStyle} />
    </>
  )
}
