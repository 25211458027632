import React from 'react'

import ProductGeneral from '../ProductGeneral'
import ProductInformation from '../ProductInformation'
import PriceInformation from '../PriceInformation'
import Specification from '../Specification'
import Shipping from '../Shipping'
import Variation from '../Variation'

import "../../form.css"

export default function StepSecondForm({ formRef, specList, setSpecList }) {
  return (
    <>
      <ProductGeneral />
      <ProductInformation formRef={formRef} />
      <PriceInformation />
      <Specification />
      <Shipping />
      <Variation formRef={formRef} specList={specList} setSpecList={setSpecList} />
    </>
  )
}