import React from "react"
import { Form, Input, Button, Card, Menu, Row, Col } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { loginAPI } from "../../api/auth.api"
import { setToken, setUser } from "../../utils/auth"

import './login.css'

export default function Login(props) {
  const menuItems = [
    {
      label: <a href="/#">News</a>,
      key: 'news'
    },
    {
      label: <a href="https://lambocustomercare.freshdesk.com/">Help Center</a>,
      key: 'helpcenter'
    },
    {
      label: <a href="https://www.lamboplace.com" className="nav-shopping">Shopping site</a>,
      key: 'shoppingsite'
    },
  ]

  const onFinish = async (values) => {
    try {
      const res = await loginAPI(values)
      setToken(res.data.access_token)
      setUser(res.data.user)
      props.history.push('/admin')
    } catch (err) {
      console.log(err)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.warning('Failed:', errorInfo)
  }

  return (
    <>
    <header>
      <div className="header-container container">
      <Row justify="space-between">
        <Col sm={20} md={12}>
        <div className="header-logo">
          <img src="https://bo.lamboplace.com//pub/media/wysiwyg/logo-white.png" alt="LamboPlace Vendor Panel" width="133" height="52" />
        </div>
        </Col>
        <Col sm={4} md={12}>
        <div className="header-nav">
          <Menu mode="horizontal" items={menuItems} />
        </div>
        </Col>
        </Row>
      </div>
    </header>
    <Row className="container main-content" align="top" justify="center">
      <Col sm={24} md={12}>
        <img src="https://bo.lamboplace.com//pub/media/wysiwyg/login-img.jpg" className="img-responsive" alt="Lamboplace Login" />
        <p className="login-text">Manage your shop efficiently on LamboPlace with our <br /> LamboPlace Seller Centre</p>
      </Col>
      <Col sm={24} md={12}>
        <Card>
          <h2 className="login-title">LamboPlace <br />Seller Center</h2>
          <p className="login-title-text">Creating a seller account now to start selling your goods.</p>
          <Form
            name="vendor-login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              className="input-require"
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input placeholder="Email" prefix={<UserOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              className="input-require"
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              name="submit"
            >
              <Button className="login-form-button" type="primary" htmlType="submit">Sign In</Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
    <style jsx="true">{`
    body {
      color: #878787;
    }
    h2 {
      font-size: 36px;
    }
    .container {
      margin: 0 auto;
    }
    .ant-menu-overflow-item {
      color:#f7f7f7;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
    .ant-menu-light .ant-menu-submenu-title:hover
    {
      color: #c9d8f1;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
      border: none;
    }

    .ant-menu-horizontal > .ant-menu-item a:hover {
      color: #c9d8f1;
    }
    .ant-card-bordered {
      border-radius: 10px;
      border:1px solid #cfd8dc !important;
    }
    .ant-btn-primary {
      color: black;
      background-color: #ffd429 !important;
      border: 2px solid #ffd429;
      height: auto;
      border-radius: 5px;
    }
    .ant-btn-primary span {
      padding: 5px;
    }
    .login-text {
      text-align: center;
      font-size: 14px;
    }
    header {
      background: #1349a0;
    }
    .header-container {
      margin: 0 auto 20px;   
    }

    .header-nav ul {
      background: none;
      border: 0;
      display: flex;
      justify-content: flex-end;
      align-items:center;
      height: 52px;
    }

    .header-nav ul li.ant-menu-item a {
      color: #f7f7f7;
      padding: 5px;
    }

    .header-nav ul li a.nav-shopping {
      border: 1px solid #fff;
      border-radius: 5px;
      font-weight: 600;
    }

    .img-responsive {
      width: 100%;
    }
    .main-content > div {
      margin: 30px 0;
    }
    .login-title {
      color: #222;
      font-weight: 600;
      line-height: 1.428;
      letter-spacing: 0.5px;
    }

    .login-title-text {
        font-size: 15px;
        font-weight: 450;
        color: #878787;
    }

    .ant-form-item-label label {
      color: #5f5f5f;
      font-size: 13px !important;
    }

    .input-require .ant-form-item-label:after {
      color: #ff0000;
      content: "*" !important;
      font-size: 13px;
      margin: 0 0 0 3px;  
    }

    .ant-input-affix-wrapper {
      border-radius: 5px;
      height: 48px;
      font-size: 20px;
    }
    .ant-input-affix-wrapper input {
      font-size: 15px;
    }
      @media (min-width: 768px) 
      {
        .container {
            width: 750px;
        }
      }
      @media (min-width: 992px) 
      {
        .container {
            width: 970px;
        }
      }
      @media (min-width: 1200px)
      {
        .container {
          width: 1170px;
        }
      }
      @media(max-width: 768px) 
      {
        .main-content {
          padding: 0 15px;
        }
      }
      
    `}</style>
    </>
  )
}