import React from "react"
import { Card, Space, Form, InputNumber } from "antd"

export default function Shipping() {
  return (
    <div className="second_step_form">
      <div className="shipping_details">
      <Card
        title="Shipping"
      >
        <Space direction="vertical">
          <Form.Item label="Parcel Size" labelCol={{ span: 24 }}>
            <Space>
              <Form.Item name="ts_dimensions_width">
                <InputNumber addonAfter="CM" placeholder="W (integer)" />
              </Form.Item>
              <Form.Item name="ts_dimensions_length">
                <InputNumber addonAfter="CM" placeholder="L" step="0.01" />
              </Form.Item>
              <Form.Item name="ts_dimensions_height">
                <InputNumber addonAfter="CM" placeholder="H (integer)" />
              </Form.Item>
            </Space>
          </Form.Item>
        </Space>
      </Card>
      </div>
    </div>
  )
}