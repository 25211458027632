import React, { useState, useEffect } from "react"
import { Card, Table, Image, Space, Button } from "antd"
import _ from "lodash"

import { MAGENTO_META_URL } from "../../../../const/config"

import { getProductAttributeOptions } from "../../../../api/product.api"

import AddVariation from "./childs/AddVariation"

export default function Variation(props) {
  const { variations, has_parent } = props.currentData
  const [attributes, setAttributes] = useState([])
  const [attributeOptions, setAttributeOptions] = useState([])

  const columns = [
    {
      key: 1,
      title: 'Name',
      dataIndex: 'name',
      width: '300px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      key: 2,
      title: 'SKU',
      dataIndex: 'sku',
      width: '300px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      key: 3,
      title: 'Image',
      dataIndex: 'image',
      width: '180px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text) => {
        if (text !== '-')
          return <Image className='product_img' src={MAGENTO_META_URL + '/catalog/product' + text} />
        return '';
      }
    },
    {
      key: 4,
      title: 'Price',
      dataIndex: 'price',
      width: '120px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      key: 5,
      title: 'Action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => { props.history.push(`/admin/products/variation/edit/${record.entity_id}`) }}>Edit</Button>
        </Space>
      ),
    }
  ]

  const canVariation = () => {
    return !has_parent
  }

  useEffect(() => {
    let isMount = true
    const fetchData = async () => {
      try {
        const res = await getProductAttributeOptions()
        if (isMount) {
          setAttributes(res.data.attributes)
          setAttributeOptions(res.data.attributes_options)
        }
      } catch (err) {
        console.error('unable to catch:', err)
      }
    }
    fetchData()
    return () => { isMount = false }
  }, [props])

  return (
    canVariation() ?
      <div className="second_step_form">
        <Card title="Variations">
          {_.isEmpty(variations) ? null :
            <Table
              dataSource={variations}
              columns={columns}
              rowKey="entity_id"
              pagination={false}
            />
          }
          <AddVariation
            attributes={attributes}
            attribute_options={attributeOptions}
          />
        </Card>
      </div> : null
  )
}