import React, {useEffect, useState} from "react"
import { List, Space, Divider } from "antd"
import { FieldTimeOutlined, MailOutlined } from "@ant-design/icons"
import _ from "lodash"

import { resolveDatetime } from "../../../../helpers/order.helper"

export default function CommentHistory(props) {
  const currentData = props.data
  const [historySource, setHistorySource] = useState([])

  const resolveIsNotified = (val) => {
    if(val) {
      return "Is Notified"
    }
    return "Not Notifed"
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  )

  const loadCommentHistory = () => {
    if(!_.isEmpty(historySource)) {
      return <List 
        size="small"
        dataSource={historySource}
        pagination={false}
        renderItem={item => (
          <List.Item 
            key={item.entity_id}
          >
            <Space split={<Divider type="vertical" />}>
              {item.comment}
              <IconText icon={FieldTimeOutlined} text={resolveDatetime(item.created_at)} key="list-vertical-time" />
              <IconText icon={MailOutlined} text={resolveIsNotified(item.is_customer_notified)} key="list-vertical-mail" />
            </Space>
          </List.Item>
        )}
      />
    }
  }

  useEffect(() => {
    if(currentData) {
      setHistorySource(currentData.memo_comments)
    }
  }, [currentData])

  return (
    <>{loadCommentHistory()}</>
  )
}