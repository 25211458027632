import React from "react"
import { withRouter } from "react-router-dom"
import { Layout, Menu } from 'antd'
import { DashboardOutlined, ShopOutlined, ShoppingCartOutlined } from "@ant-design/icons"

import RightContent from "../RightContent"

import './frame.css'

const { Header, Sider, Content } = Layout

const items = [
  getItem('Dashboard', '/admin/dashboard', <DashboardOutlined />),
  getItem('Order', '/admin/orders', <ShoppingCartOutlined />),
  getItem('Product', '/admin/products', <ShopOutlined />),
];

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function Frame(props) {
  const menuHandler = ({ key }) => {
    props.history.push(key)
  }

  return (
    <Layout hasSider>
      <Sider className="frame-slider" trigger={null}>
        <div className="logo">
          <img className="dashboard_logo_img" src="/img/logo-rectangle.png" alt="LamboPlace Vendor Panel" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} onClick={menuHandler} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background frame-header">
          <RightContent {...props} />
        </Header>
        <Content
          className="site-layout-background frame-content"
        >
          { props.children }
        </Content>
      </Layout>
    </Layout>
  )
}

export default withRouter(Frame)