import React from "react"
import { Menu, Dropdown, Avatar } from "antd"

import { LogoutOutlined, UserOutlined } from '@ant-design/icons'

import { getUser, logout } from "../../utils/auth"

import './index.css'

export default function AvatarDropdown(props) {
  const getAdminName = () => {
		const {first_name, last_name} = getUser()
		return `${first_name} ${last_name}`
	}

	const getAdminAvatar = () => {
		const {first_name, last_name} = getUser()
		return `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`
	}

  const items = [
    getItem('Profile', 'profile', <UserOutlined />),
    getItem('Logout', 'logout', <LogoutOutlined />),
  ];

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={(e) => {onMenuClick(e)}} items={items} />
  )
  
  const onMenuClick = (e) => {
    switch(e.key) {
      case 'logout':
        logout()
        props.history.push('/login')
      break
      case 'profile':
        props.history.push('/admin/profile')
      break
      default:
        console.log(e)
      break;
    }
  }

  return (
    <Dropdown overlay={menuHeaderDropdown}>
      <span className="action account">
      <span className="anticon">{ getAdminName() }</span>
      <Avatar className="avatar" alt="avatar">{ getAdminAvatar() }</Avatar>
      </span>
    </Dropdown>
  )
}