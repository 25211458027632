import React from "react"
import { Form, Select } from "antd"

import { country_map } from "../../../../const/country"

const { Option } = Select

export default function SpecCountry() {
  return (
    <Form.Item name="country_of_manufacture" label="Country of Origin" labelCol={{ span: 24 }}>
      <Select 
        placeholder="Please select" 
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        { country_map.map(item => (
          <Option key={item.code} value={item.code}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}