import React, { useRef, useState } from 'react';
import { StepsForm } from '@ant-design/pro-components';
import { Card, Button, Space, message } from 'antd';

import StepFirstForm from './Form/StepFirstForm';
import StepSecondForm from './Form/StepSecondForm';

import { prepareNewFormPayload } from '../../../helpers/product.helper'
import { getUser } from '../../../utils/auth';
import { storeProduct } from '../../../api/product.api';

export default function NewProductForm({ history }) {
  const formRef = useRef(null)
  const [specList, setSpecList] = useState([]);

  const handleSubmit = async(values) => {
    const user = getUser();
    const payload = prepareNewFormPayload(values, specList, user.vendor_id, formRef.current.getFieldValue('description'), formRef.current.getFieldValue('short_description'))
    await storeProduct(payload)
    message.success(`Create Product Successfully...`, 2)
    .then(() => {
      history.push(`/admin/products`)
    });
  }

  return (
    <Card>
      <StepsForm
        formRef={formRef}
        onFinish={handleSubmit}
        submitter={{
          render: (props) => {
              if (props.step === 0) {
                  return (
                      <div>
                      <Button type="primary" onClick={() => props.onSubmit?.()}>
                        Next
                      </Button>
                      </div>
                  );
              }
              return (
                <Space style={{ textAlign: 'right' }}>
                    <Button key="pre" onClick={() => props.onPre?.()}>
                      Previous
                    </Button>
                    <Button type="primary" key="goToTree" onClick={() => props.onSubmit?.()}>
                      Submit
                    </Button>
                </Space>
              )
          },
        }}      
      >
        <StepsForm.StepForm name="step1">
          <StepFirstForm formRef={formRef} />
        </StepsForm.StepForm>
        <StepsForm.StepForm name="step2">
          <StepSecondForm formRef={formRef} specList={specList} setSpecList={setSpecList} />
        </StepsForm.StepForm>
      </StepsForm>
    </Card>
  )
}
