import { request } from "../request"

const prefix_url = '/api/shared/'

export function getRegionList(params) {
  return request.get(prefix_url+'getRegionList', params)
}

export function getMainCategoryList(params = {}) {
  return request.get(prefix_url+'getMainCategoryList', params)
}