import React from "react"
import { Card, Table } from "antd"

export default function ItemShipTable(props) {
  const currentData = props.data

  const table_columns = [
    { title: 'Product', dataIndex: ['name'], key: 'name' },
    { title: 'Qty Shipped', dataIndex: ['qty'], key: 'qty' },
  ]

  return (
    <Card title="Items Shipped">
      <Table rowKey="entity_id" columns={table_columns} dataSource={currentData.shipment_items} pagination={false}/>
    </Card>
  )
}