import React, { useState, useEffect } from "react"
import { Spin, Empty, Card, Form, Select } from "antd"

import _ from "lodash"
import { getCategories } from "../../../../api/product.api"
import { getCacheCategories, setCacheCategories } from "../../../../utils/cacheStorage"

const { Option } = Select

export default function ProductCategory(props) {
  const form = props.form
  const currentData = props.currentData
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [thirdCategories, setThirdCategories] = useState([])

  const handleFirstLevelCategory = (val) => {
    form.setFieldsValue({
      first_category: parseInt(val),
      sub_category: null,
      third_category: null
    })
    const nextCategory = categories.find((item) => item.id === parseInt(val))
    setSubCategories([])
    setThirdCategories([])
    setSubCategories(nextCategory.children_data)
  }

  const handleSubLevelCategory = (val) => {
    form.setFieldsValue({
      third_category: null
    })
    const nextCategory = subCategories.filter((item) => item.id === parseInt(val))
    setThirdCategories(nextCategory[0].children_data)
  }

  const category_options = (
    <Form.Item label="Category:" name="first_category" labelCol={{ span: 24 }}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={handleFirstLevelCategory}
      >
        {categories.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))
        }
      </Select>
    </Form.Item>
  )

  const subCategories_options = (
    <Form.Item label="Sub-category:" name="sub_category" labelCol={{ span: 24 }}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={handleSubLevelCategory}
      >
        {subCategories.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))
        }
      </Select>
    </Form.Item>
  )

  const thirdCategories_options = (
    <Form.Item label="3rd Level Category:" name="third_category" labelCol={{ span: 24 }}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {thirdCategories.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))
        }
      </Select>
    </Form.Item>
  )

  useEffect(() => {
    let isMount = true
    const fetchData = async () => {
      try {
        let res_data = {}
        
        if(getCacheCategories()) {
          res_data = getCacheCategories()
        } else {
          const res = await getCategories()
          res_data = res.data
          setCacheCategories(res.data)
        }

        if (isMount) {
          const { first_category, sub_category } = currentData
          const default_level = res_data.children_data
          let tmp_first = []
          let tmp_sub = []
          
          setCategories(default_level)

          if (!_.isUndefined(sub_category)) {
            tmp_first = default_level.find((item) => item.id === parseInt(first_category))
            tmp_sub = tmp_first.children_data.find((item) => item.id === parseInt(sub_category))
            setSubCategories(tmp_first.children_data)
            setThirdCategories(tmp_sub.children_data)
          }

          setLoading(false)
        }
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

    fetchData()

    return () => { isMount = false }

  }, [props, currentData])

  return (
    loading ? <Spin spinning={loading}><Empty /></Spin> :
      <div className="second_step_form">
        <Card title="Product Category">
          {_.isEmpty(categories) ? <></> : category_options}
          {_.isEmpty(subCategories) ? <></> : subCategories_options}
          {_.isEmpty(thirdCategories) ? <></> : thirdCategories_options}
        </Card>
      </div>
  )
}