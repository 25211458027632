import React, {useEffect, useState} from "react"
import { Spin, Empty, PageHeader, Space, Button, message } from "antd"
import { ProForm } from '@ant-design/pro-components'
import _ from "lodash"

import { getOrder, makeShipment } from "../../api/order.api"
import { handleError } from "../../utils/errorResponse"

import OrderAccountInformation from "../../modules/Order/information/childs/OrderAccountInformation"
import AddressInformation from "../../modules/Order/information/childs/AddressInformation"
import PaymentShippingMethod from "../../modules/Order/shipment/new/PaymentShippingMethod"
import ItemToShipTable from "../../modules/Order/shipment/new/ItemToShipTable"
import ShipmentTotal from "../../modules/Order/shipment/new/ShipmentTotal"

const colStyle = {display: 'flex'}
const cardStyle = {flex:1}
const labelStyle = { fontWeight: 'bold' }

export default function NewShipmentView(props) {
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getOrder({entity_id: props.match.params.id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props])


  const resolveInitFormData = () => {
    return {
      shipment: {
        items: resolveProductsForInit(currentData.vendor_order_items)
      },
      comment_text: '',
      comment_customer_notify: false,
      send_email: false
    }
  }

  const resolveProductsForInit = (collection) => {
    return collection.map((item) => {
      let newItem = Object.assign({},{})
      newItem.item_id = item.item_id
      newItem.name = item.name
      newItem.sku = item.sku
      newItem.price = item.price
      newItem.qty_ordered = item.qty_ordered
      newItem.qty_canceled = item.qty_canceled
      newItem.qty_invoiced = item.qty_invoiced
      newItem.qty_shipped = item.qty_ordered
      newItem.qty_refunded = item.qty_refunded

      return newItem
    })
  }

  const resolveProducts = (collection) => {
    return collection.map((item) => {
      let newItem = Object.assign({},{})
      newItem.item_id = item.item_id
      newItem.qty = item.qty_shipped
      return newItem
    })
  }

  const onFinish = async(values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        vendor_id: currentData.vendor_id,
        order_id: currentData.order_id,
        shipment: {
          comment_text: values.comment_text,
          comment_customer_notify: values.comment_customer_notify,
          send_email: values.send_email,
          items: resolveProducts(values.shipment.items),
          tracks: values.tracking
        }
      }
      const res = await makeShipment(payload)
      const res_data = res.data
      if(_.isObject(res_data)) {
        message.success(`${res_data.message}`, 1.5)
        .then(() => {
          setSubmitLoading(false)
          props.history.push(`/admin/orders/view/${currentData.order_id}`)
        })
      }
    } catch(err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <PageHeader title="New Shipment" onBack={() => window.history.back()}>
    <ProForm 
      onFinish={onFinish} 
      autoComplete="off"
      initialValues={resolveInitFormData()}
      submitter={{
        searchConfig: {
          submitText: 'Submit Shipment',
        },
        submitButtonProps: {
          style: {
            display: 'none'
          }
        },
        resetButtonProps: {
          style: {
            display: 'none',
          },
        },
      }}
    >
      <Space direction="vertical" size="large">
          <OrderAccountInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle}/>
          <AddressInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
          <PaymentShippingMethod data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
          <ItemToShipTable data={currentData} />
          <ShipmentTotal />
          <ProForm.Group name="submit" style={{float: 'right'}}>
            <Button className="make-shipment-button" type="primary" htmlType="submit" loading={submitLoading}>Submit Shipment</Button>
          </ProForm.Group>
        </Space>
    </ProForm>
    </PageHeader>
  )
}