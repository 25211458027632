import React from "react"

import { ProFormDigit } from "@ant-design/pro-components"

export default function SpecWeight({ selectedWeightType }) {
  return (
    <ProFormDigit 
      name="weight"
      rules={[{ required: true, min:0.5, type:'number' }]} 
      initialValue={0.5}
      style={{ width: '100%' }}
      addonBefore="kgs"
      disabled={selectedWeightType === 0}
      fieldProps={{ precision: 1, step: "0.1" }}
    />
  )
}