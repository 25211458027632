import React, {useState} from "react"
import { Card, Select, Tabs } from "antd"

import GraphOrder from "./graphs/GraphOrder"
import GraphAmount from "./graphs/GraphAmount"
import GraphCredit from "./graphs/GraphCredit"

const { Option } = Select

export default function StatGraphRow(props) {
  const[menuVal, setMenuVal] = useState('24h')

  const filter_date_range = [
    { title: '24 hours', value: '24h' },
    { title: '7 days', value: '7d' },
    { title: '1 month', value: '1m' },
    { title: '1 year', value: '1y' },
    { title: '2 years', value: '2y' },
  ]
  
  const filter_menu = (<Select defaultValue={menuVal} onChange={(val) => {
    setMenuVal(val)
  }}>
    { filter_date_range.map((item, index) => {
      return (
        <Option key={index} value={item.value}>{item.title}</Option>
      )
    }) }
  </Select>)

  const tab_items = [
    { 
      label: 'Orders',
      key: 'order',
      children: <GraphOrder by_time={menuVal} />
    },
    { 
      label: 'Amounts',
      key: 'amount',
      children: <GraphAmount by_time={menuVal} />
    },
    { 
      label: 'Credits',
      key: 'credit',
      children: <GraphCredit by_time={menuVal} />
    }
  ]

  return (
    <Card
      className="stat-graph"
      bordered
    >
      <Tabs
        tabBarExtraContent={filter_menu}
        items={tab_items}
      />
    </Card>
  )
}