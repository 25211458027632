import React from "react"
import { Card, Divider, Row, Col, Form, Input } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { resolveAmount } from "../../../../helpers/order.helper"

export default function TotalSummary(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Order Total">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions title="Invoice History"/>
          <Form.Item name={['invoice', 'comment_text']}>
            <Input.TextArea rows={5} />
          </Form.Item>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="middle" column={1} title="Order Totals" bordered>
            <ProDescriptions.Item label="Subtotal"> RM {resolveAmount(currentData.subtotal) }</ProDescriptions.Item>
            <ProDescriptions.Item label="Shipping & Handling"> RM { resolveAmount(currentData.shipping_amount)}</ProDescriptions.Item>
            <ProDescriptions.Item label="Discount"> RM { resolveAmount(currentData.discount_amount) }</ProDescriptions.Item>
              <Divider dashed />
            <ProDescriptions.Item label="Grand Total"> RM {resolveAmount(currentData.grand_total) }</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}