import React, {useState} from "react"
import { Card, Tabs } from 'antd'

import OrderTable from "../../modules/Order/OrderTable"

export default function Order(props) {
  const [activeTabKey, setActiveTabKey] = useState('all')

  const tabChangeHandler = (key) => {
    setActiveTabKey(key)
  }

  const tab_items = [
    {
      key: 'all',
      label: 'All',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'pending_payment',
      label: 'Unpaid',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'to_ship',
      label: 'To Ship',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'shipping',
      label: 'Shipping',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'complete',
      label: 'Completed',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'canceled',
      label: 'Cancellations',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'closed',
      label: 'Return/Refund',
      children: <OrderTable {...props} tabKey={activeTabKey} />
    }
  ]

  return (
    <Card
      style={{ width: '100%'}}
      title="Order List"
    >
      <Tabs 
        defaultActiveKey={activeTabKey} 
        onChange={(e) => {tabChangeHandler(e)}}
        tabBarGutter={72}
        items={tab_items}
      />
    </Card>
  )

}