import React, {useState, useEffect, useRef, useMemo } from "react"
import { Card, Tabs } from 'antd'
import ProductTable from "../../modules/Product/ProductTable";
import { StopOutlined, WarningOutlined, CloseCircleOutlined, ClockCircleOutlined,HomeOutlined, TagsOutlined } from '@ant-design/icons'
import { getProductCount } from "../../api/product.api"
import moment from 'moment'

export default function Product(props) {
  const [activeTabKey, setActiveTabKey] = useState('all')
  const [orderCount, setOrderCount] = useState('all')
  const [position, setPosition] = useState(['left', 'right']);


  useEffect(() => {
    let isMount = true
    const fetchData = async () => {
      try {
        const res = await getProductCount()
        if(res.data && res.data.length > 0){
          setOrderCount(res.data[0])
        }
      } catch (err) {
        console.error('unable to catch:', err)
      }
    }
    fetchData()
    return () => { }
  }, [props])

  const tabChangeHandler = (key) => {
    setActiveTabKey(key)
  }

  const tabList = [
    {
      key: 'all',
      label: <span><TagsOutlined />All ({`${orderCount && orderCount.AllOrderCount ? orderCount.AllOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'live',
      label: <span><HomeOutlined />Live ({`${orderCount && orderCount.LiveOrderCount ? orderCount.LiveOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'sold_out',
      label: <span><WarningOutlined />Sold Out ({`${orderCount && orderCount.SoldOutOrderCount ? orderCount.SoldOutOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'violation',
      label: <span><StopOutlined />Violation ({`${orderCount && orderCount.ViolationOrderCount ? orderCount.ViolationOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'delisted',
      label: <span><CloseCircleOutlined />Delisted ({`${orderCount && orderCount.DelistedOrderCount ? orderCount.DelistedOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
    {
      key: 'reviewing',
      label: <span><ClockCircleOutlined />Reviewing ({`${orderCount && orderCount.ReviewvingOrderCount ? orderCount.ReviewvingOrderCount  : 0}`})</span>,
      children: <ProductTable {...props} tabKey={activeTabKey} />
    },
  ]

  const operations = (
    <div  style={{fontSize:'8pt', marginRight: '8px', position: 'relative', top: '-15px'}}>
      <div>Last Update: {orderCount && orderCount.updatedAt ? moment(new Date(orderCount.updatedAt)).format("YYYY-MM-DD HH:mm:ss")  : ""}</div>
    </div>
  );

  return (
    <div className="product_listing_page">
    <Card>
      <Tabs 
        defaultActiveKey={activeTabKey} 
        onChange={(e) => {tabChangeHandler(e)}}
        tabBarGutter={72}
        items={tabList}
        tabBarExtraContent={operations}
      />
    </Card>
    </div>
  )

}