import { UPLOAD_META_URL } from "../const/config"
import axios from "axios"

export const uploadService = async (formData)  => {
  const res = await axios.post(UPLOAD_META_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return res
}