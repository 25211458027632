import React from "react"
import { Form, Select } from "antd"
import _ from "lodash"

const { Option } = Select

export default function SpectWeightType(props) {
  const currentData = props.currentData

  const weightTypes = [
    { value: 1, title: 'This item has weight' },
    { value: 0, title: 'This item has no weight' },
  ]

  const onWeightTypeChange = (val) => {
    props.setActionType(val)
  }

  const getWeightTypeTooltip = () => {
    const value = weightTypes.find((item) => item.value === currentData.product_updates['weight_type'])
    return `Pending Update: ${value.title}`
  }

  return (
    <Form.Item name="weight_type" tooltip={_.isUndefined(currentData.product_updates['weight_type']) ? null : getWeightTypeTooltip}>
      <Select placeholder="Please select" onChange={onWeightTypeChange}>
      { weightTypes.map(item => (
        <Option style={{width:'100%'}} key={item.value} value={item.value}>{item.title}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}