import React from "react"
import { Form, Select } from "antd"
import _ from "lodash"

const { Option } = Select

export default function SpecWarrantyType(props) {
  const currentData = props.currentData
  const warrantyTypeOptions = props.options

  const onWarrantyTypeChange = (val) => {
    props.setActionType(val)
  }

  const getWarrantyTypeTooltip = () => {
    const value = warrantyTypeOptions.find((item) => item.option_id === currentData.product_updates['warranty_type'])
    return `Pending Update: ${value.value}`
  }

  return (
    <Form.Item name="warranty_type" label="Warranty" tooltip={_.isUndefined(currentData.product_updates['warranty_type']) ? null : getWarrantyTypeTooltip}>
      <Select placeholder="Warranty Type" onChange={onWarrantyTypeChange}>
      { warrantyTypeOptions.map(item => (
        <Option key={item.option_id} value={item.option_id}>{item.value}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}