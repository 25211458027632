import React, {useEffect, useState} from "react"
import { Spin, Empty, PageHeader, Space, Form, Button, message } from "antd"
import _ from "lodash"

import { getOrder, makeCreditMemo } from "../../api/order.api"
import { handleError } from "../../utils/errorResponse"

import OrderAccountInformation from "../../modules/Order/information/childs/OrderAccountInformation"
import AddressInformation from "../../modules/Order/information/childs/AddressInformation"
import PaymentShippingMethod from "../../modules/Order/information/childs/PaymentShippingMethod"
import ItemToRefundTable from "../../modules/Order/creditmemo/new/ItemToRefundTable"
import RefundSummary from "../../modules/Order/creditmemo/new/RefundSummary"

const colStyle = {display: 'flex'}
const cardStyle = {flex:1}
const labelStyle = { fontWeight: 'bold' }

export default function NewCreditmemoView(props) {
  const [form] = Form.useForm()
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getOrder({entity_id: props.match.params.id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props])

  const resolveProducts = (collection) => {
    return collection.map((item) => {
      let newItem = Object.assign({},{})
      newItem.item_id = item.item_id
      newItem.qty = item.qty_refunded
      if(item.return_to_stock) {
        newItem.return_to_stock = true
      } else {
        newItem.return_to_stock = false
      }
      return newItem
    })
  }

  const resolveProductsForInit = (collection) => {
    return collection.map((item) => {
      let newItem = Object.assign({},{})
      newItem.item_id = item.item_id
      newItem.name = item.name
      newItem.sku = item.sku
      newItem.price = item.price
      newItem.qty_ordered = item.qty_ordered
      newItem.qty_canceled = item.qty_canceled
      newItem.qty_invoiced = item.qty_invoiced
      newItem.qty_shipped = item.qty_shipped
      newItem.qty_refunded = item.qty_invoiced
      newItem.price = item.price
      newItem.tax_amount = item.tax_amount
      newItem.discount_amount = item.discount_amount
      newItem.row_total = item.row_total
      newItem.return_to_stock = false

      return newItem
    })
  }

  const onFinish = async(values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        vendor_id: currentData.vendor_id,
        order_id: currentData.order_id,
        creditmemo: {
          comment_text: values.comment_text,
          items: resolveProducts(values.items),
          comment_customer_notify: values.comment_customer_notify,
          shipping_amount: values.shipping_amount,
          adjustment_positive: values.adjustment_positive,
          adjustment_negative: values.adjustment_negative,
          payment_fee: values.payment_fee,
          return_applied_credits: values.return_applied_credits
        }
      }
      const res = await makeCreditMemo(payload)
      const res_data = res.data
      if(_.isObject(res_data)) {
        message.success(`${res_data.message}`, 1.5)
        .then(() => {
          setSubmitLoading(false)
          props.history.push(`/admin/orders/view/${currentData.order_id}`)
        })
      }
    } catch(err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  const resolveInitFormData = () => {
    console.log(currentData)
    return {
      items: resolveProductsForInit(currentData.vendor_order_items),
      comment_text: '',
      shipping_amount: parseFloat(currentData.shipping_amount).toFixed(2),
      adjustment_positive: 0,
      adjustment_negative: 0,
      payment_fee: parseFloat(currentData.payment_fee).toFixed(2),
      return_applied_credits: parseFloat(currentData.grand_total).toFixed(2)
    }
  }

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <PageHeader title="New Credit Memo" onBack={() => window.history.back()}>
    <Form 
      form={form}
      onFinish={onFinish} 
      autoComplete="off"
      initialValues={resolveInitFormData()}
    >
      <Space direction="vertical" size="large">
        <OrderAccountInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle}/>
        <AddressInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
        <PaymentShippingMethod data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
        <ItemToRefundTable data={currentData} />
        <RefundSummary data={currentData} colStyle={colStyle} cardStyle={cardStyle} />
        <Form.Item name="submit" style={{float: 'right'}}>
          <Button className="make-creditmemo-button" type="primary" htmlType="submit" loading={submitLoading}>Refund Offline</Button>
        </Form.Item>
      </Space>
    </Form>
    </PageHeader>
  )
}