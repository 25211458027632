import React from "react"
import { Card, Space, Row, Col } from "antd"
import { ProFormText } from "@ant-design/pro-components"

export default function ProductGeneral() {
  return (
    <div className="second_step_form">
      <Space
        direction="vertical" 
        size="middle" 
        style={{ 
          display: 'flex',  
          minWidth: 1000,
          marginBlockEnd: 16,
          maxWidth: '100%',
        }}
      >
        <div className="product_name_card">
          <Card size="small">
            <Row gutter={16}>
              <Col lg={15} sm={24}>
                <ProFormText 
                  name="name" 
                  label="Product Name:" 
                  placeholder="Fill in your product name"
                  rules={[{ required: true }]}
                />
              </Col>

              <Col lg={9} sm={24}>
                <ProFormText 
                  name="sku" 
                  label="SKU:" 
                  placeholder="Fill in your product SKU" 
                  rules={[{ required: true }]}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </Space>
    </div >
  )
}