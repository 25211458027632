import React, { useRef, useCallback } from 'react'
import { Form, Input, InputNumber, Button, Row, Col } from "antd"

export default function VariationFillBar({ formRef, specList, setSpecList, visible }) {
  const priceInputEl = useRef(null);
  const stockInputEl = useRef(null);
  const skuInputEl = useRef(null);

  const applyToAll = () => {
    const resolved = []
    const price = parseInt(priceInputEl.current.value)
    const stock = parseInt(stockInputEl.current.value)
    const sku = skuInputEl.current.input.value
    if(specList.length > 0) {
      for(let spec of specList) {
        if(spec.subSpecList && spec.subSpecList.length > 0) {
          spec.subSpecList.map((item) => {
            item.price = price
            item.stock = stock
            item.weight = sku
            return item
          })
          resolved.push(spec)
        } 
      }
    }
    const tableSkuList = getDataSource(resolved)
    tableSkuList.forEach((list) => {
      formRef?.current?.setFieldsValue({
        [`${list.skuId}-price`]: price,
        [`${list.skuId}-stock`]: stock,
        [`${list.skuId}-weight`]: sku
      })
    })
    setSpecList(resolved)
  }

  const descartes = useCallback((array) => {
    if (array.length < 2) return array[0] || []
    return [].reduce.call(array, (col, set) => {
      const res = []
      col.forEach(c => {
        set.forEach(s => {
          const t = [].concat(Array.isArray(c) ? c : [c])
          t.push(s)
          res.push(t)
        })
      })
      return res
    })
  }, [])

  const getSkuList = useCallback((sourceData) => {
    const data = []
    sourceData.forEach(c => {
      if (c.subSpecList && c.subSpecList.length > 0) {
        data.push(c.subSpecList)
      }
    })
    if (!data.length) return false
    const result = descartes(data)
    return result
  }, [descartes])

  const getDataSource = useCallback((sourceData) => {
    const skuList = getSkuList(sourceData)
    if (!skuList) return []
    return skuList.map((c, i) => {
      let tmp = { key: i, skuId: '' }
      const skuIdArray = []
      if (Array.isArray(c)) {
        c.forEach(sc => {
          tmp[sc.pValue] = sc.value
          skuIdArray.push(`${sc.specId}-${sc.pValue}:${sc.subSpecId}-${sc.value}`)
          tmp = { ...tmp, ...sc }
        })
      } else {
        tmp[c.pValue] = c.value
        skuIdArray.push(`${c.specId}-${c.pValue}:${c.subSpecId}-${c.value}`)
        tmp = { ...tmp, ...c }
      }
      tmp.skuId = skuIdArray.join()
      return tmp
    })
  },[getSkuList])

  return (
    visible ? <div style={{ marginTop: "5vh" }}>
      <Form.Item label="Variation Information">
        <Row gutter={24}>
          <Col span={6}>
            <InputNumber style={{ width: '100%'}} ref={priceInputEl} placeholder="Price" addonBefore="RM" />
          </Col>
          <Col span={6}>
            <InputNumber style={{ width: '100%'}} ref={stockInputEl} placeholder="Stock" />
          </Col>
          <Col span={6}>
            <Input style={{ width: '100%'}} ref={skuInputEl} placeholder="Weight" />
          </Col>
          <Col span={6}>
            <Button className="add_product_btn" style={{ width: '100%'}} type="primary" key="new_product" onClick={applyToAll}>
              Apply To All
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </div> : null
  )
}
