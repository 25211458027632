import React from "react"
import { Form, Select } from "antd"

const { Option } = Select

export default function SpectWeightType({ setSelectedWeightType }) {
  const weightTypes = [
    { value: 1, title: 'This item has weight' },
    { value: 0, title: 'This item has no weight' },
  ]

  const onWeightTypeChange = (val) => {
    setSelectedWeightType(val)
  }

  return (
    <Form.Item name="weight_type" initialValue={1}>
      <Select placeholder="Please select" onChange={onWeightTypeChange}>
      { weightTypes.map(item => (
        <Option style={{width:'100%'}} key={item.value} value={item.value}>{item.title}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}