import React, { useState, useEffect, useCallback } from 'react'

import { Table, Form, Input, InputNumber } from 'antd'

import _ from "lodash"

export default function VariationTable({ specList }) {
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  const onFieldChange = useCallback((val, row, field) => {
    console.log(row)
  }, [])

  const getRowSpan = useCallback((columns, tableDataList) => {
    if (tableDataList.length === 0) return false
    const rowSpanConfig = {}
    columns.forEach(item => {
      let point = 0
      const keyName = item.value
      rowSpanConfig[keyName] = []
  
      tableDataList.forEach((sc, si) => {
        if (!sc[keyName]) return
        if (si === 0) {
          rowSpanConfig[keyName].push(1)
          return
        }
        if (sc[keyName] === tableDataList[si - 1][keyName]) {
          rowSpanConfig[keyName][point]++
          rowSpanConfig[keyName].push(0)
        } else {
          rowSpanConfig[keyName].push(1)
          point = si
        }
      })
    })
  
    return rowSpanConfig
  }, [])

  const getColumns = useCallback((sourceData, tableSkuList) => {
    for (let i = 0; i < sourceData.length; i++) {
      if (!sourceData[i].value) return false
    }
  
    const config = getRowSpan(sourceData, tableSkuList)
    const columns = []
    const concatCol = [
      {
        title: (
          <div>
            {/*<em style={{ color: 'red' }}>*</em>*/}price (RM)
          </div>
        ),
        dataIndex: 'price',
        width: 200,
        render: (value, row) => (
          <Form.Item name={`${row.skuId}-price`} initialValue={value} validateTrigger={'onBlur'} rules={[{ required: true, message: 'cannot be empty' }]}>
            <InputNumber onChange={(val) => { onFieldChange(val, row, 'price')}} style={{ width: '100%'}} />
          </Form.Item>
        ),
      },
      {
        title: (
          <div>
            {/*<em style={{ color: 'red' }}>*</em>*/}Stock
          </div>
        ),
        dataIndex: 'stock',
        width: 200,
        render: (value, row) => (
          <Form.Item name={`${row.skuId}-stock`} initialValue={value} validateTrigger={'onBlur'} rules={[{ required: true, message: 'cannot be empty' }]}>
            <InputNumber style={{ width: '100%'}} />
          </Form.Item>
        ),
      },
      {
        title: (
          <div>
            Weight
          </div>
        ),
        dataIndex: 'weight',
        width: 200,
        render: (value, row) => (
          <Form.Item name={`${row.skuId}-weight`} initialValue={value ? value : 0.5} validateTrigger={'onBlur'} rules={[{ required: true, message: 'cannot be empty' }]}>
            <InputNumber style={{ width: '100%'}} step="0.1" />
          </Form.Item>
        ),
      }
    ]
  
    sourceData.forEach(c => {
      const col = {
        title: (
          <div>
            { _.startCase(_.toLower(c.value )) }
          </div>
        ),
        dataIndex: c.value,
        render: (value, row, index) => (
          <div className="sku-td-first">
            {row.img && <img src={row.img} alt="" />}
            <span>{value}</span>
          </div>
        ),
        onCell: (_, index) => ({
          rowSpan: config[c.value] ? config[c.value][index] : 1
        })
      }
      columns.push(col)
    })
  
    return columns.concat(concatCol)
  }, [getRowSpan, onFieldChange])

  const descartes = useCallback((array) => {
    if (array.length < 2) return array[0] || []
    return [].reduce.call(array, (col, set) => {
      const res = []
      col.forEach(c => {
        set.forEach(s => {
          const t = [].concat(Array.isArray(c) ? c : [c])
          t.push(s)
          res.push(t)
        })
      })
      return res
    })
  }, [])

  const getSkuList = useCallback((sourceData) => {
    const data = []
    sourceData.forEach(c => {
      if (c.subSpecList && c.subSpecList.length > 0) {
        data.push(c.subSpecList)
      }
    })
    if (!data.length) return false
    const result = descartes(data)
    return result
  }, [descartes])

  const getDataSource = useCallback((sourceData) => {
    const skuList = getSkuList(sourceData)
    if (!skuList) return []
    return skuList.map((c, i) => {
      let tmp = { key: i, skuId: '' }
      const skuIdArray = []
      if (Array.isArray(c)) {
        c.forEach(sc => {
          tmp[sc.pValue] = sc.value
          skuIdArray.push(`${sc.specId}-${sc.pValue}:${sc.subSpecId}-${sc.value}`)
          tmp = { ...tmp, ...sc }
        })
      } else {
        tmp[c.pValue] = c.value
        skuIdArray.push(`${c.specId}-${c.pValue}:${c.subSpecId}-${c.value}`)
        tmp = { ...tmp, ...c }
      }
      tmp.skuId = skuIdArray.join()
      return tmp
    })
  },[getSkuList])

  useEffect(() => {
    if (specList.length > 0) {
      const tableSkuList = getDataSource(specList)
      const tableColumns = getColumns(specList, tableSkuList)
      setColumns(tableColumns)
      setDataSource(tableSkuList)
    } else {
      setDataSource([])
    }
  }, [specList, getDataSource, getColumns])

  return (
    <div className="sku-table">
    {columns && dataSource.length > 0 && (
      <Table columns={columns} dataSource={dataSource} bordered pagination={false} />
    )}
  </div>
  )
}
