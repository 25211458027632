export const storePersist = {
  set: (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  },
  setWithExpiry: (key, state, ttl=300000) => {
    const now = new Date()
    const item = {
      value: state,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  },
  get: (key) => {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  },
  getWithExpiry: (key) => {
    const itemStr = localStorage.getItem(key)
    
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  },
  remove: (key) => {
    localStorage.removeItem(key)
  },
  getAll: () => {
    return localStorage;
  },
  clear: () => {
    localStorage.clear();
  }
};

export default storePersist;
