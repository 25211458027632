import React, {useEffect, useState} from "react"
import { Form, Select, Space, Spin, Empty, Card, Button } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import _ from "lodash"

import { getProductAttributeOptions } from "../../../../api/product.api"

const { Option } = Select

export default function VariationOption(props) {
  const { currentData } = props
  const [loading, setLoading] = useState(false)
  const [attributes, setAttributes] = useState([])
  const [attributeOptions, setAttributeOptions] = useState([])
  const [selectedAttributeOptions, setSelectedAttributeOptions] = useState([])
  
  const handleChangeAttribute = (value) => {
    const selected = attributeOptions[value]
    setSelectedAttributeOptions(selected)
  }

  const getSelectedSelections = () => (
    selectedAttributeOptions.map((attribute) => (
      <Option key={attribute.option_id} value={attribute.option_id}>{attribute.value}</Option>
    ))
  )

  const getAttributeOptionSelection = (index) => {
    const current = currentData.options[index]
    if(_.isEmpty(selectedAttributeOptions) && current) {        
      return attributeOptions[current.attribute].map((attribute) => <Option key={attribute.option_id} value={attribute.option_id}>{attribute.value}</Option>);
    }
    return getSelectedSelections()
  }

  const showOrHide = () => {
    if(!currentData.has_parent) {
      return <></>
    }

    return loading ? <Spin spinning={loading}><Empty /></Spin> :
    <Card
      title="Variation Options"
    >
      <Form.List name="options">
        {(fields, {add, remove}) => (
          <div>
            {
              fields.map(field => (
                <React.Fragment key={field.key}>
                <Space
                  key={field.key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item name={[field.name, 'attribute']}>
                    <Select
                      style={{
                        width: 200,
                      }}
                      onChange={handleChangeAttribute}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                      {attributes.map((attribute) => (
                        <Option key={attribute.attribute_id} value={attribute.attribute_id}>{attribute.frontend_label}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={[field.name, 'attribute_option']}>
                    <Select
                      style={{
                        width: 200,
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                      { !_.isEmpty(attributeOptions) ? getAttributeOptionSelection(field.key) : getSelectedSelections() }
                    </Select>
                  </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
                </React.Fragment>
              ))
            }
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Option
            </Button>
          </Form.Item>
          </div>
        )}
      </Form.List>
    </Card>
  }

  useEffect(() => {
    let isMount = true
    const fetchData = async() => {
      try {
        setLoading(true)
        const res = await getProductAttributeOptions()
        if(isMount) {
          setAttributes(res.data.attributes)
          setAttributeOptions(res.data.attributes_options)
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        console.error('unable to catch:', err)
      }
    }
    fetchData()
    return () => { isMount = false }
  }, [props])

  return (
    showOrHide()
  )
}
