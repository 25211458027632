const customer_group_map = {
  0: 'NOT LOGGED IN',
  1: 'General',
  2: 'Wholesale',
  3: 'Retailer',
  4: 'Cash On Delivery'
}

module.exports = {
  customer_group_map
}