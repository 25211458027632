import { request } from "../request"

/**
 * Login API
 *
 * @param   {object}  { email, password }
 *
 * @return  {json}  json response
 */
export function loginAPI(user) {
	return request.post('/public/api/auth/login', user)
}

