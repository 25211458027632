import React from "react"
import { Card, Table } from "antd"

import { resolveAmount } from "../../../../helpers/order.helper"

export default function ItemRefundedTable(props) {
  const currentData = props.data

  const product_columns = [
    {
      title: 'Product',
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: 'Sku',
      dataIndex: ['sku'],
      key: 'sku',
    },
    {
      title: 'Qty',
      dataIndex: ['qty'],
      key: 'qty',
    },
    {
      title: 'Subtotal',
      dataIndex: ['price'],
      key: 'subtotal',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },
    {
      title: 'Tax Amount',
      dataIndex: ['tax_amount'],
      key: 'tax_amount',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },
    {
      title: 'Discount Amount',
      dataIndex: ['discount_amount'],
      key: 'discount_amount',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },
    {
      title: 'Row Total',
      dataIndex: ['row_total'],
      key: 'row_total',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    }
  ]

  return (
    <Card title="Items Refunded">
      <Table rowKey="entity_id" columns={product_columns} dataSource={currentData.memo_items} pagination={false} />
    </Card>
  )
}