import { 
  Dashboard,
  Login,
  NotFound,
  Notification,
  Order,
  Profile,
  Product
 } from "../pages"

 import { DashboardOutlined, ShoppingCartOutlined, ShopOutlined } from '@ant-design/icons'

 import OrderView from "../pages/Order/View"
 import OrderShipmentView from "../pages/Order/ShipmentView"
 import OrderCreditMemoView from "../pages/Order/CreditMemoView"
 import NewInvoiceView from "../pages/Order/NewInvoiceView"
 import NewShipmentView from "../pages/Order/NewShipmentView"
 import NewCreditmemoView from "../pages/Order/NewCreditmemoView"
 import NewProductView from "../pages/Product/NewProductView"
 import EditProductView from "../pages/Product/EditProductView"
 import EditProductVariationView from "../pages/Product/EditProductVariationView"

export const publicRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/404',
    component: NotFound,
  }
]

export const protectedRoutes = [
  {
    path: '/admin/dashboard',
    component: Dashboard,
    title: 'Dashboard',
    isShow: true,
    isParent: true,
    exact: true,
    icon: <DashboardOutlined />
  },
  {
    path: '/admin/orders',
    component: Order,
    title: 'Order',
    isShow: true,
    isParent: true,
    exact: true,
    icon: <ShoppingCartOutlined />
  },
  {
    path: '/admin/orders/view/:id',
    component: OrderView,
    title: 'Order View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/orders/new/invoice/:id',
    component: NewInvoiceView,
    title: 'New Invoice View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/orders/new/shipment/:id',
    component: NewShipmentView,
    title: 'New Shipment View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/orders/new/creditmemo/:id',
    component: NewCreditmemoView,
    title: 'New CreditMemo View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/orders/shipments/:id',
    component: OrderShipmentView,
    title: 'Shipment View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/orders/creditmemos/:id',
    component: OrderCreditMemoView,
    title: 'Credit Memo View',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/profile',
    component: Profile,
    title: 'Profile',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/products',
    component: Product,
    title: 'Product',
    isShow: true,
    isParent: true,
    exact: true,
    icon: <ShopOutlined />
  },
  {
    path: '/admin/products/new',
    component: NewProductView,
    title: 'New Product',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/products/edit/:id',
    component: EditProductView,
    title: 'Edit Product',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/products/variation/edit/:id',
    component: EditProductVariationView,
    title: 'Edit Product',
    isShow: false,
    isParent: false
  },
  {
    path: '/admin/notifications',
    component: Notification,
    title: 'Notification',
    isShow: false,
    isParent: false,
  },
]
