import React from "react"
import { Card, Row, Col } from "antd"
import { ProDescriptions, ProFormTextArea, ProFormCheckbox, ProForm } from '@ant-design/pro-components';

export default function ShipmentTotal(props) {
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Shipment Total">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions title="Shipment Comments"/>
          <ProForm.Item label="Comment Text" name="comment_text">
            <ProFormTextArea rows={5} />
          </ProForm.Item>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="middle" column={1} title="Shipment Options"/>
          <ProForm.Item name="comment_customer_notify" valuePropName="checked">
            <ProFormCheckbox>Append Comments</ProFormCheckbox>
          </ProForm.Item>
          <ProForm.Item name="send_email" valuePropName="checked">
            <ProFormCheckbox>Email Copy of Shipment</ProFormCheckbox>
          </ProForm.Item>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}