import React from "react"
import { Form, Select } from "antd"
import _ from "lodash"

const { Option } = Select

export default function SpecBrand({ currentData, brandOptions }) {
  const getBrandTooltip = () => {
    const value = brandOptions.find((brand) => brand.option_id === currentData.product_updates['mgs_brand'])
    return `Pending Update: ${value.name}`
  }

  return (
    <Form.Item name="brand" label="Brand" labelCol={{ span: 24 }} tooltip={_.isUndefined(currentData.product_updates['mgs_brand']) ? null : getBrandTooltip}>
      <Select 
        placeholder="Please select"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        { brandOptions.map(item => (
          <Option key={item.brand_id} value={item.brand_id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}