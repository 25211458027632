import React, { useState } from "react"
import { Card, Row, Col, Switch } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import NoticeForm from "./NoticeForm"

export default function TotalSummary(props) {
  const [showNotes, setShowNotes] = useState(false);
  const currentData = props.data

  return (
    <div className="order_notes_component">
      <Card>
        <Row gutter={16}>
          <Col span={24}>
            <div className="order_info_page_title total_summary_page">
              <ProDescriptions title="Notes for this Order" />
              <Switch className="switch_note_button" onClick={() => setShowNotes(showNotes => !showNotes)} />
            </div>
            {showNotes && <NoticeForm {...props} currentData={currentData} />}
          </Col>
        </Row>
      </Card>
    </div>
  )
}