import React from "react"
import { Card, Row, Col } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { getPaymentInfo } from "../../../../helpers/order.helper"
import CarrierTable from "./CarrierTable"

export default function PaymentShippingMethod(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Payment & Shipping Method">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={1} title="Payment Information" layout="vertical">
            <ProDescriptions.Item>{ getPaymentInfo(currentData.shipment_vendor_order.vendor_order_payment.additional_information) }</ProDescriptions.Item>
            <ProDescriptions.Item>The order was placed using MYR.</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={1} title="Shipping & Handling Information" layout="vertical">
            <ProDescriptions.Item>{currentData.shipment_vendor_order.shipping_description}</ProDescriptions.Item>
            <ProDescriptions.Item>Total Shipping Charges: RM { currentData.shipment_vendor_order.shipping_amount }</ProDescriptions.Item>
          </ProDescriptions>
          <CarrierTable data={currentData} />
        </Card>
        </Col>
      </Row>
    </Card>
  )
}