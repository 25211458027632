import React from "react"
import { Card, Space, Form, InputNumber } from "antd"
import _ from "lodash"

export default function Shipping({ currentData }) {
  
  const getDimensionWidthTooltip = () => {
    const value = currentData.product_updates['ts_dimensions_width']
    return `Pending Update: ${value}`
  }

  const getDimensionLengthTooltip = () => {
    const value = currentData.product_updates['ts_dimensions_length']
    return `Pending Update: ${value}`
  }

  const getDimensionHeightTooltip = () => {
    const value = currentData.product_updates['ts_dimensions_height']
    return `Pending Update: ${value}`
  }
  
  return (
    <div className="second_step_form">
    <Card
      title="Shipping"
    >
      <Space direction="vertical">
        <Form.Item label="Parcel Size" labelCol={{ span: 24 }}>
          <Space>
            <Form.Item name="ts_dimensions_width" label=' ' tooltip={_.isUndefined(currentData.product_updates['ts_dimensions_width']) ? null : getDimensionWidthTooltip}>
              <InputNumber addonAfter="CM" placeholder="W (integer)" step="0.01"/>
            </Form.Item>
            <Form.Item name="ts_dimensions_length" label=' ' tooltip={_.isUndefined(currentData.product_updates['ts_dimensions_length']) ? null : getDimensionLengthTooltip}>
              <InputNumber addonAfter="CM"  placeholder="L (length)" step="0.01"/>
            </Form.Item>
            <Form.Item name="ts_dimensions_height" label=' ' tooltip={_.isUndefined(currentData.product_updates['ts_dimensions_height']) ? null : getDimensionHeightTooltip}>
              <InputNumber addonAfter="CM"  placeholder="H (integer)" step="0.01"/>
            </Form.Item>
          </Space>
        </Form.Item>
      </Space>
    </Card>
    </div>
  )
}