import React from "react"
import { Form, Select } from "antd"

const { Option } = Select;

export default function SpecBrand({ brandOptions }) {
  return (
    <Form.Item name="brand" label="Brand" labelCol={{ span: 24 }}>
      <Select 
        placeholder="Please select"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        { brandOptions.map(item => (
          <Option key={item.brand_id} value={item.brand_id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}