import React from "react"
import { Form, Select } from "antd"
import _ from "lodash"

const { Option } = Select

export default function SpecWarrantyPeriod(props) {
  const currentData = props.currentData
  const selectedWarrantyType = props.selectedWarrantyType
  const warrantyPeriodOptions = props.options

  const getWarrantyPeriodTooltip = () => {
    const value = warrantyPeriodOptions.find((item) => item.option_id === currentData.product_updates['warranty_period'])
    return `Pending Update: ${value.value}`
  }

  return (
    <Form.Item name="warranty_period" label=" " tooltip={_.isUndefined(currentData.product_updates['warranty_period']) ? null : getWarrantyPeriodTooltip}>
      <Select placeholder="Warranty Period" disabled={selectedWarrantyType === 317}>
      { warrantyPeriodOptions.map(item => (
        <Option key={item.option_id} value={item.option_id}>{item.value}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}