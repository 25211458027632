import React from "react"
import { Card, Row, Col, Form, InputNumber, DatePicker } from "antd"
import _ from "lodash"

import "../../form.css"

const { RangePicker } = DatePicker

export default function PriceInformation(props) {
  const currentData = props.currentData

  const getPriceTooltip = () => {
    const value = currentData.product_updates['price']
    return `Pending Update: ${value}`
  }

  const getSpecialPriceTooltip = () => {
    const value = currentData.product_updates['special_price']
    return `Pending Update: ${value}`
  }

  return (
    <div className="second_step_form">
      <Card
        title="Price Information"
      >

        <Row gutter={16}>
          <Col lg={12} sm={24}>
            <Form.Item name="price" label="Price" labelCol={{ span: 24 }} rules={[{ required: true }]} tooltip={_.isUndefined(currentData.product_updates['price']) ? null : getPriceTooltip}>
              <InputNumber style={{ width: '100%' }} addonBefore="MYR" step="0.0001" />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24}>
            <Form.Item name="special_price" label="Special Price" labelCol={{ span: 24 }} tooltip={_.isUndefined(currentData.product_updates['special_price']) ? null : getSpecialPriceTooltip}>
              <InputNumber style={{ width: '100%' }} addonBefore="MYR" step="0.0001" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col lg={12} sm={24}>
            <Form.Item name="special_price_datetime_range" label="Special Price From" labelCol={{ span: 24 }}>
              <RangePicker style={{ width: '100%' }}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
        </Row>

      </Card>
    </div>
  )
}