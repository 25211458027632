import React from "react"
import { PageHeader } from "antd"

import NewProductForm from "../../modules/Product/NewProduct"

import "./index.css"

export default function NewProductView(props) {
  return (
    <>
      <div className="start_new_product_page">
        <PageHeader
          style={{ color: '#254B97' }}
          title="Add a New product"
          onBack={() => window.history.back()}
        >
          <NewProductForm history={props.history} />
        </PageHeader>
      </div>
    </>
  )
}