import React from "react"
import { Card, Row, Col, Form, InputNumber } from "antd"
import { ProFormDateRangePicker } from "@ant-design/pro-components"

export default function PriceInformation() {
  return (
    <div className="second_step_form">
      <Card
        title="Price Information"
      >
        <Row gutter={16}>
            <Col lg={12} sm={24}>
              <Form.Item name="price" label="Price" labelCol={{ span: 24 }} rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} addonBefore="MYR" step="0.0001" />
              </Form.Item>
            </Col>
            <Col lg={12} sm={24}>
              <Form.Item name="special_price" label="Special Price" labelCol={{ span: 24 }}>
                <InputNumber style={{ width: '100%' }} addonBefore="MYR" step="0.0001" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={24}>
              <ProFormDateRangePicker 
                name="special_price_datetime_range" 
                label="Special Price From"
                format="YYYY-MM-DD"
              />
            </Col>
          </Row>
        
      </Card>
    </div>
  )
}