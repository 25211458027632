import React from "react"
import { Form, Select } from "antd"

const { Option } = Select

export default function SpecWarrantyType({ warrantyTypeOptions, setSelectedWarrantyType }) {
  const onWarrantyTypeChange = (val) => {
    setSelectedWarrantyType(val)
  }

  return (
    <Form.Item name="warranty_type">
      <Select placeholder="Warranty Type" onChange={onWarrantyTypeChange}>
      { warrantyTypeOptions.map(item => (
        <Option key={item.option_id} value={item.option_id}>{item.value}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}