import React, {useEffect, useState} from "react"
import { Card, Row, Col, Form, Input, Button, Checkbox, message, List, Space, Divider } from "antd"
import { FieldTimeOutlined, MailOutlined } from "@ant-design/icons"
import _ from "lodash"

import { getShipmentCommentHistory, sendShipmentComment } from "../../../../api/order.api"
import { handleError } from '../../../../utils/errorResponse'
import { resolveDatetime } from "../../../../helpers/order.helper"

const { TextArea } = Input

export default function TotalSummary(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  const [form] = Form.useForm()
  const [historySource, setHistorySource] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)


  const onFinish = async (values) => {
    try {
      const payload = {
        order_id: currentData.order_id,
        shipment_id: currentData.entity_id,
        data: {
          comment_text: values.comment,
          is_customer_notified: values.is_customer_notified,
          is_visible_on_front: values.is_visible_on_front
        }
      }

      const res = await sendShipmentComment(payload)
      if(res) {
        message.success('add comment successfully...').then(async () => {
          const res_history = await getShipmentCommentHistory({ parent_id: currentData.entity_id})
          const data_history = res_history.data
          setHistorySource(data_history)
          setSubmitLoading(false)
        })
      }
      
    } catch (err) {
      console.error(err)
      message.warning(handleError(err))
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const resolveIsNotified = (val) => {
    if(val) {
      return "Is Notified"
    }
    return "Not Notifed"
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  )

  const loadCommentHistory = () => {
    if(!_.isEmpty(historySource)) {
      return <List 
        size="small"
        dataSource={historySource}
        pagination={false}
        renderItem={item => (
          <List.Item 
            key={item.entity_id}
          >
            <Space split={<Divider type="vertical" />}>
              {item.comment}
              <IconText icon={FieldTimeOutlined} text={resolveDatetime(item.created_at)} key="list-vertical-time" />
              <IconText icon={MailOutlined} text={resolveIsNotified(item.is_customer_notified)} key="list-vertical-mail" />
            </Space>
          </List.Item>
        )}
      />
    }
  }


  useEffect(() => {
    const fetchData = async() => {
      try {
        const res_history = await getShipmentCommentHistory({ parent_id: currentData.entity_id })
        const data_history = res_history.data
        setHistorySource(data_history)
      } catch (err) {
        console.error('unable to catch:', err)
      }
    }

		fetchData()

  }, [currentData])

  return (
    <Card title="Order Total">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card title="Shipment History" style={cardStyle}>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Comment Text" name="comment" rules={[{ required: true }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name="is_customer_notified" valuePropName="checked">
              <Checkbox>Notify Customer by Email</Checkbox>
            </Form.Item>
            <Form.Item name="is_visible_on_front" valuePropName="checked">
              <Checkbox>Visible on Storefront</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitLoading}>Submit Comment</Button>
            </Form.Item>
          </Form>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card title="Comment History" style={cardStyle}>
          {loadCommentHistory()}
        </Card>
        </Col>
      </Row>
    </Card>
  )
}