import React, { useState, useEffect } from "react"
import { PageHeader, message, Spin, Empty, Modal } from 'antd'
import { MailOutlined, PrinterOutlined } from "@ant-design/icons"
import Button from "antd-button-color"
import fileDownload from "js-file-download"
import _ from "lodash"

import CreditMemoView from "../../modules/Order/creditmemo/View"
import { CreditMemoPrintableMapper } from "../../helpers/order.helper"
import { downloadCreditMemo } from "../../api/download.api"
import { getCreditMemo, sendNotify } from "../../api/order.api"

export default function OrderCreditMemoView(props) {
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [printLoading, setPrintLoading] = useState(false)
  const [mailLoading, setMailLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const handlePrint = async () => {
    try {
      setPrintLoading(true)
      const collection = CreditMemoPrintableMapper(currentData)
      const filename = `creditmemo-${collection.increment_id}.pdf`
      const res = await downloadCreditMemo({
        collection
      })
      fileDownload(res.data, filename)
      setPrintLoading(false)
    } catch(err) {
      setPrintLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }

  const handleMail = async () => {
    try {
      setMailLoading(true)
      const payload = {
        id: currentData.entity_id,
        action: 'creditmemo'
      }
      const res = await sendNotify(payload)
      if(!res.data.result) {
        message.warning('unable to send email')
      } else {
        message.success('sent email successfully')
      }
      setModalVisible(false)
      setMailLoading(false)
    } catch(err) {
      setMailLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }

  const action_buttons = () => {
    const buttons = []
    const email = (<Button type="default" key="btn_email" icon={<MailOutlined />} onClick={showModal}>Send Email</Button>)
    const printButton = (<Button type="success" key="btn_print" icon={<PrinterOutlined />} onClick={handlePrint} loading={printLoading}>Print</Button>)

    buttons.push(email)
    buttons.push(printButton)

    return buttons
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const entity_id = props.match.params.id
        const res = await getCreditMemo({entity_id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <>
    <PageHeader
      className="site-page-header"
      onBack={() => window.history.back()}
      title="Credit Memo Details"
      extra={action_buttons()}
    >
      <CreditMemoView data={currentData} />
    </PageHeader>
    <Modal
      visible={modalVisible}
      title="Confirmation"
      okText="Confirm"
      cancelText="Cancel"
      onOk={handleMail}
      confirmLoading={mailLoading}
      onCancel={hideModal}
      >
      <p>Are you sure you want to send a credit memo email to customer?</p>
    </Modal> 
    </>
  )
}