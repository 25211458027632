import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';

import 'react-quill/dist/quill.snow.css'
import { publicUploadService } from '../../utils/publicUploader';

Quill.register('modules/imageUploader', ImageUploader);

class CustomEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text
    };
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component
  }

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
    'image',
    'imageBlot'
  ]
  toolbar = [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'code'],
    ['clean', 'image'],
  ]  

  modules = {
    toolbar: this.toolbar,
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);
          publicUploadService(formData)
          .then((result) => {
            resolve(result.data.url_path);
          })
          .catch(error => {
            reject("Upload failed");
            console.error("Error:", error);
          });
        });
      }
     }
  }
  
  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs = () => {
    if (this.reactQuillRef === null) return;
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  }

  handleChange = (value) => {
    this.props.formRef?.current?.setFieldsValue({
      [this.props.field]: value
    })   
    this.setState({ text: value });
  }

  render() {
    return (
      <ReactQuill
        ref={this.reactQuillRef}
        value={this.state.text}
        onChange={this.handleChange}
        formats={this.formats}
        modules={this.modules}
      />
    )
  }

}

export default CustomEditor;