import React from "react"
import { Card, Table } from "antd"

import { resolveAmount } from "../../../../helpers/order.helper"

export default function ItemOrderedTable(props) {
  const currentData = props.data

  const product_columns = [
    {
      title: 'Product',
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => {
        return <>
          <div style={{ display: 'flex' }}>
            <div className="purchase_item">{text}&nbsp;&nbsp;&nbsp;</div>
            <div className="purchase_qty">x{record.qty_ordered}</div>
          </div>
        </>
      }
    },

    {
      title: 'Original Price',
      dataIndex: ['original_price'],
      key: 'original_price',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },

    {
      title: 'Subtotal',
      dataIndex: ['price'],
      key: 'subtotal',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },

    {
      title: 'Discount Amount',
      dataIndex: ['discount_amount'],
      key: 'discount_amount',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    },

    {
      title: 'Tax Percent',
      dataIndex: ['tax_percent'],
      key: 'taxt_percent',
      render: (text) => {
        return <>{resolveAmount(text)} %</>
      }
    },

    {
      title: 'Row Total',
      dataIndex: ['row_total'],
      key: 'row_total',
      render: (text) => {
        return <>RM {resolveAmount(text)}</>
      }
    }
  ]

  return (
    <div className="order_info_page_title items_ordered_component">
      <Card title="Items Ordered">
        <Table rowKey="item_id" columns={product_columns} dataSource={currentData.vendor_order_items} pagination={false} />
      </Card>
    </div>
  )
}