import React from "react"
import { Card, Form, Table, InputNumber } from "antd"

export default function ItemToShipTable(props) {
  const currentData = props.data

  const rules = [{ required: true, message:'cannot be empty'} ]

  const product_columns = [
    {
      title: 'Product',
      dataIndex: ['name'],
      key: 'name',
      width: '30%',
      render: (text, record, index) => {
        return <>{getItemVal(index, 'name')}</>
      }
    },
    {
      title: 'Sku',
      dataIndex: ['sku'],
      key: 'sku',
      width: '30%',
      render: (text, record, index) => {
        return <>{getItemVal(index, 'sku')}</>
      }
    },
    {
      title: 'Quantity',
      dataIndex: ['qty_ordered'],
      key: 'quantity',
      width: '10%',
      readonly: true,
      render: (text, record, index) => {
        const items = [
          { title:'Ordered', value: getItemVal(index, 'qty_ordered')}, 
          { title:'Canceled', value: getItemVal(index, 'qty_canceled')},
          { title:'Invoiced', value: getItemVal(index, 'qty_invoiced')},
          { title:'Shipped', value: getItemVal(index, 'qty_shipped')}
        ]
        const filtered_items = items.filter(item => item.value > 0)
        return (<table><tbody>
          { filtered_items.map((item, index) => {
            return (
              <tr key={index}><th>{item.title}</th><td>{item.value}</td></tr>
            )
          } )}
        </tbody></table>)
      }
    },
    {
      title: 'Qty To Ship',
      dataIndex: ['qty_shipped'],
      key: 'qty_shipped',
      width: '10%',
      render: (text, record, index) => {
        return (
          <Form.Item name={[record.name, "qty_shipped"]} rules={rules}>
            <InputNumber min={0} max={getItemVal(index, 'qty_ordered')} />
          </Form.Item>
        )
      }
    }
  ]

  const getItemVal = (index, key) => {
    return currentData.vendor_order_items.filter((val, i) => {
      return i === index
    })[0][key]
  }

  return (
    <Card title="Items To Ship">
      <Form.List name={['shipment', 'items']}>
        {(fields, { add, remove }) => {
          return (<Table rowKey="name" columns={product_columns} dataSource={fields} pagination={false} />)
        }}
      </Form.List>
    </Card>
  )
}