import { request } from "../request"

const prefix_url = '/api/products/'

export function getTable(params) {
  return request.post(prefix_url+'table', params)
}

export function getCategories(params) {
  return request.get(prefix_url+'getCategories', params)
}

export function getAttributeSets(params) {
  return request.get(prefix_url+'getAttributeSets', params)
}

export function getWarrantyTypes(params) {
  return request.get(prefix_url+'getWarrantyTypes', params)
}

export function getWarrantyPeriods(params) {
  return request.get(prefix_url+'getWarrantyPeriods', params)
}

export function getBrands(params) {
  return request.get(prefix_url+'getBrands', params)
}

export function storeProduct(params) {
  return request.post(prefix_url+'storeProduct', params)
}

export function getProduct(params) {
  return request.get(prefix_url+'getProduct', params)
}

export function getProductAttributeOptions(params) {
  return request.get(prefix_url+'getProductAttributeOptions', params)
}


export function getProductCount(params) {
  return request.get(prefix_url+'getProductCount', params)
}