import React from "react"
import { Form, Select } from "antd"
import _ from "lodash"

import { country_map } from "../../../../const/country"

const { Option } = Select

export default function SpecCountry({ currentData }) {

  const getCustomTooltip = () => {
    const value = currentData.product_updates['country_of_manufacture']
    return `Pending Update: ${value}`
  }

  return (
    <Form.Item name="country_of_manufacture" label="Country of Origin" labelCol={{ span: 24 }} tooltip={_.isUndefined(currentData.product_updates['country_of_manufacture']) ? null : getCustomTooltip}>
      <Select 
        placeholder="Please select" 
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        { country_map.map(item => (
          <Option key={item.code} value={item.code}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}