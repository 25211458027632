import { request } from "../request"

const prefix_url = '/api/dashboard/'

export function getStatsRow(params) {
  return request.get(prefix_url+'getStatsRow', params)
}

export function getGraph(params) {
  return request.get(prefix_url+'getGraph', params)
}

export function getGrid(params) {
  return request.get(prefix_url+'getGrid', params)
}