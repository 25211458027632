import React, { useEffect, useState } from "react"
import { Card } from "antd"
import { Chart, Tooltip, Interval} from "bizcharts"
import { getGraph } from "../../../api/dashboard.api"

export default function GraphCredit(props) {
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {
    const fetchData = async() => {
      try {
        const { by_time } = props
        const res = await getGraph({ by_time, by_type: 'credit' })
        const res_data = res.data
        setDataSource(res_data)
      } catch (err) {
        console.error('unable to catch:', err)
      }
    }
		fetchData()
	}, [props])

  return (
		<Card>
        <Chart height={400} padding="auto" data={dataSource} autoFit>
        <Interval
          adjust={[
          {
              type: 'dodge',
              marginRatio: 0,
            },
          ]}
          color="type"
          position="y*value"
        />
        <Tooltip shared />
      </Chart>
		</Card>
  )
}