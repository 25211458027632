import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { protectedRoutes } from './routes'

import { isLogged } from './utils/auth'

import './App.css'

import Frame from './modules/Layout/Frame'

export default class App extends React.Component {
  render() {
    return isLogged() ? (
      <Frame>
        <Switch>
          { protectedRoutes.map(route => {
            return (
              <Route
                key={route.title}
                path={route.path}
                exact={route.exact}
                render={routeProps => {
                  return <route.component {...routeProps} />
                }}
              />
            );
          }) }
          <Redirect to={protectedRoutes[0].path} from="/admin"/>
          <Redirect to="/404" />
        </Switch>
      </Frame>
    ) : ( <Redirect to="/login" />)
  }
}
