import React, {useState} from "react"
import { Card, Row, Col, Space } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';
import Button from "antd-button-color"
import { CloseOutlined, DeliveredProcedureOutlined, RollbackOutlined } from "@ant-design/icons"

import { customer_group_map } from "../../../../const/order"
import { getMobileNumber, getCustomerName, getFullAddress, getOrderStatus } from "../../../../helpers/order.helper"
import PopupShipment from "../../shipment/PopupShipment"
import PopupWaybill from "../../shipment/PopupShipment"
import PopupCreditMemo from "../../creditmemo/PopupCreditMemo"
import PopupCancel from "../../creditmemo/PopupCancel"
import { getUser } from "../../../../utils/auth"
// import { cancelOrder } from "../../../../api/order.api"

const moment = require('moment')

export default function OrderAccountInformation(props) {
  const currentData = props.data
  const labelStyle = props.labelStyle
  const orderStatus = props.orderStatus
  const {vendor_id} = getUser()

  const [cancelVisible, setCancelVisible] = useState(false)
  const [shipmentVisible, setShipmentVisible] = useState(false)
  const [waybillVisible, setWaybillVisible] = useState(false)
  const [creditMemoVisible, setCreditMemoVisible] = useState(false)

  const getOrderDetailPageHeaderButtons = () => {
    const buttons = []
    const cancelButton = (<Button type="danger" key="order_view_action_cancel" icon={<CloseOutlined />} onClick={showModal}>Cancel Order</Button>)
    // const invoiceButton = (<Button type="primary" key="order_view_action_invoice" icon={<FileTextOutlined />} onClick={handleInvoice}>Invoice</Button>)
    const shipButton = (<Button type="success" key="order_view_action_ship" icon={<DeliveredProcedureOutlined />} onClick={handleShip}>Ship</Button>)
    const creditmemoButton = (<Button type="danger" key="order_view_action_creditmemo" icon={<RollbackOutlined />} onClick={handleCreditMemo}>Cancel Order</Button>)
    const waybillButton = (<Button type="success" key="order_view_action_waybill" icon={<DeliveredProcedureOutlined />} onClick={handlePrintWayBill}>Print Waybill</Button>)

    const vendor_order = currentData
    const valid_status_for_waybill = ['processing', 'complete']

    if(orderStatus.canCancel) {
      buttons.push(cancelButton)
    }

    // if(currentData.canInvoice) {
    //   buttons.push(invoiceButton)
    // }

    if(orderStatus.canCreditMemo) {
      buttons.push(creditmemoButton)
    }

    if(orderStatus.canShip && !orderStatus.canInvoice) {
      buttons.push(shipButton)
    }

    if(!orderStatus.canShip && !orderStatus.canInvoice && valid_status_for_waybill.includes(vendor_order.status)) {
      buttons.push(waybillButton)
    }

    return buttons
  }

  const showModal = () => {
    setCancelVisible(true)
  }

  // const hideModal = () => {
  //   setCancelVisible(false)
  // }

  // const handleCancel = async() => {
  //   try {
  //     setConfirmLoading(true)
  //     const order_id = getOwnVendorOrder().order_id
  //     const res = await cancelOrder({ order_id, vendor_id })
  //     const res_data = res.data.result
  //     message.success(res_data.message)
  //     setConfirmLoading(false)
  //     setCancelVisible(false)
  //     props.setCancelState(true)
  //   } catch(err) {
  //     setConfirmLoading(false)
  //     setCancelVisible(false)
  //     message.error(err.message)
  //   }
  // }

  const handleShip = async() => {
    setShipmentVisible(true)
  }

  const handleCreditMemo = async() => {
    // const entity_id = getOwnVendorOrder().entity_id
    // props.history.push(`/admin/orders/new/creditmemo/${entity_id}`)
    setCreditMemoVisible(true)
  }

  const handlePrintWayBill = async() => {
    setWaybillVisible(true)
  }

  const getOwnVendorOrder = () => {
    return orderStatus.vendor_sales.find((item) => item.vendor_id === vendor_id)
  }

  return (
    <>
    <div className="acc_info_component">
      <Card title=
        {
          <>
          <div className="order_info_details">
            <div className="order_id">Order ID : {currentData.order.increment_id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="order_time">{moment(currentData.created_at).format('MMM DD, YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className={`order_status ${currentData.status}`}>{getOrderStatus(currentData.status, orderStatus, vendor_id)}</div>
            <div className="order_buttons">
              <Space>
                { getOrderDetailPageHeaderButtons() }
              </Space>
            </div>
          </div>
          </>
        }>

        <Row gutter={16}>
          <Col className="column_style" lg={12} sm={24}>
            <div className="order_info_page_title order_acc_table">
              <ProDescriptions size="small" column={2} title="Account Information" bordered>
                <ProDescriptions.Item span={2} label="Customer Name" labelStyle={labelStyle}>{currentData.order.customer_firstname} {currentData.order.customer_lastname}</ProDescriptions.Item>
                <ProDescriptions.Item span={2} label="Email" labelStyle={labelStyle}>{currentData.order.customer_email}</ProDescriptions.Item>
                <ProDescriptions.Item span={2} label="Customer Group" labelStyle={labelStyle}>{customer_group_map[currentData.order.customer_group_id]}</ProDescriptions.Item>
              </ProDescriptions>
            </div>
          </Col>

          <Col className="column_style2" lg={12} sm={24}>
            <div className="order_info_page_title order_addr_table">
              <ProDescriptions size="small" column={2} title="Address Information" bordered>
                  <ProDescriptions.Item>
                    <ProDescriptions size="small" column={1} title="Billing Address">
                      <ProDescriptions.Item>{getCustomerName(currentData.vendor_order_address, 'billing')}</ProDescriptions.Item>
                      <ProDescriptions.Item><div dangerouslySetInnerHTML={{ __html: getFullAddress(currentData.vendor_order_address, 'billing') }}></div></ProDescriptions.Item>
                      <ProDescriptions.Item>T: {getMobileNumber(currentData.vendor_order_address, 'billing')}</ProDescriptions.Item>
                    </ProDescriptions>
                  </ProDescriptions.Item>

                  <ProDescriptions.Item>
                    <ProDescriptions size="small" column={1} title="Shipping Address">
                      <ProDescriptions.Item>{getCustomerName(currentData.vendor_order_address, 'shipping')}</ProDescriptions.Item>
                      <ProDescriptions.Item><div dangerouslySetInnerHTML={{ __html: getFullAddress(currentData.vendor_order_address, 'shipping') }}></div></ProDescriptions.Item>
                      <ProDescriptions.Item>T: {getMobileNumber(currentData.vendor_order_address, 'shipping')}</ProDescriptions.Item>
                    </ProDescriptions>
                  </ProDescriptions.Item>
              </ProDescriptions>
            </div>
          </Col>
        </Row >
      </Card >
    </div>
    {/* <Modal
        visible={cancelVisible}
        title="Confirmation"
        okText="Confirm"
        cancelText="Cancel"
        onOk={handleCancel}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        >
        <p>Are you sure you want to cancel this order?</p>
      </Modal> */}
      <PopupCancel
        isVisible={cancelVisible}
        setVisibleState={setCancelVisible}
        setSubmitState={props.setCancelState} 
        currentData={getOwnVendorOrder()}
      />
      <PopupShipment 
        isVisible={shipmentVisible} 
        setVisibleState={setShipmentVisible}
        setSubmitState={props.setSubmitState} 
        currentData={getOwnVendorOrder()}
      />
      <PopupWaybill
        isVisible={waybillVisible}
        setVisibleState={setWaybillVisible}
        currentData={getOwnVendorOrder()}
      />
      <PopupCreditMemo
        isVisible={creditMemoVisible}
        setVisibleState={setCreditMemoVisible}
        setSubmitState={props.setCreditMemoState}
        currentData={getOwnVendorOrder()}
      />
    </>
  )
}
