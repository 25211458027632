import moment from "moment"
import _ from "lodash"
import { country_map } from "../const/country"
import { MAGENTO_META_URL } from '../const/config'

const PRODUCT_META_URL=`${MAGENTO_META_URL}/catalog/product`

const STAT_NEW = 'new'
const STAT_PENDING = 'pending'
const STAT_PROCESSING = 'processing'
const STAT_COMPLETE = 'complete'

export const InvoicePrintableMapper = (collection) => {
  const data = {}
  data.increment_id = collection.invoice.increment_id
  data.invoice = {
    increment_id: collection.invoice.increment_id,
    created_at: moment(collection.invoice.created_at).format('MMM DD, YYYY')
  }
  data.order = {
    increment_id: collection.order.increment_id,
    created_at: moment(collection.order.created_at).format('MMM DD, YYYY')
  }
  data.customer = {
    name: getClientName(collection.order),
    email: collection.order.customer_email
  }
  data.billing = {
    name: getCustomerName(collection.order_address, 'billing'),
    street: getStreet(collection.order_address, 'billing'),
    region: getRegion(collection.order_address, 'billing'),
    city: getCity(collection.order_address, 'billing'),
    postcode: getPostcode(collection.order_address, 'billing'),
    country: resolveCountry(collection.order_address, 'billing')
  }
  data.shipping = {
    name: getCustomerName(collection.order_address, 'shipping'),
    street: getStreet(collection.order_address, 'shipping'),
    region: getRegion(collection.order_address, 'shipping'),
    city: getCity(collection.order_address, 'shipping'),
    postcode: getPostcode(collection.order_address, 'shipping'),
    country: resolveCountry(collection.order_address, 'shipping')
  }
  data.method = {
    payment: getPaymentInfo(collection.order_payment.additional_information),
    shipping: collection.order.shipping_description
  }
  data.products = resolveProductItem(collection.invoice_items)
  data.summary = {
    subtotal: resolveAmount(collection.subtotal),
    shipping_handling: resolveAmount(collection.shipping_and_handling),
    discount: resolveAmount(collection.discount_amount),
    grand_total: resolveAmount(collection.grand_total)
  }
  return data
}

export const ShipmentPrintableMapper = (collection) => {
  const data = {}
  data.increment_id = collection.increment_id
  data.shipment = { increment_id: collection.increment_id}
  data.order = { 
    increment_id: collection.shipment_vendor_order.order.increment_id, 
    created_at: moment(collection.shipment_vendor_order.order.created_at).format('MMM DD, YYYY')
  }
  data.method = {
    payment: getPaymentInfo(collection.shipment_vendor_order.vendor_order_payment.additional_information),
    shipping: collection.shipment_vendor_order.shipping_description,
    shipping_price: resolveAmount(collection.shipment_vendor_order.shipping_amount)
  }
  data.billing = {
    name: getCustomerName(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    street: getStreet(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    region: getRegion(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    city: getCity(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    postcode: getPostcode(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    country: resolveCountry(collection.shipment_vendor_order.vendor_order_address, 'billing'),
    phone: getMobileNumber(collection.shipment_vendor_order.vendor_order_address, 'billing')
  }
  data.shipping = {
    name: getCustomerName(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    street: getStreet(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    region: getRegion(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    city: getCity(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    postcode: getPostcode(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    country: resolveCountry(collection.shipment_vendor_order.vendor_order_address, 'shipping'),
    phone: getMobileNumber(collection.shipment_vendor_order.vendor_order_address, 'shipping')
  }
  data.products = resolveProductItem(collection.shipment_items)
  data.tracks = resolveTracks(collection.shiptracks)
  return data
}

export const CreditMemoPrintableMapper = (collection) => {
  const data = {}
  data.increment_id = collection.increment_id
  data.creditmemo = { increment_id: collection.increment_id, created_at: moment(collection.created_at).format('MMM DD, YYYY') }
  data.order = { 
    increment_id: collection.memo_vendor_order.order.increment_id, 
    created_at: moment(collection.memo_vendor_order.order.created_at).format('MMM DD, YYYY')
  }
  data.customer = {
    id: collection.memo_vendor_order.order.customer_id
  }
  data.method = {
    payment: getPaymentInfo(collection.memo_vendor_order.vendor_order_payment.additional_information),
    shipping: collection.memo_vendor_order.order.shipping_description
  }
  data.billing = {
    name: getCustomerName(collection.memo_vendor_order.vendor_order_address, 'billing'),
    street: getStreet(collection.memo_vendor_order.vendor_order_address, 'billing'),
    region: getRegion(collection.memo_vendor_order.vendor_order_address, 'billing'),
    city: getCity(collection.memo_vendor_order.vendor_order_address, 'billing'),
    postcode: getPostcode(collection.memo_vendor_order.vendor_order_address, 'billing'),
    country: resolveCountry(collection.memo_vendor_order.vendor_order_address, 'billing'),
    phone: getMobileNumber(collection.memo_vendor_order.vendor_order_address, 'billing')
  }
  data.shipping = {
    name: getCustomerName(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    street: getStreet(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    region: getRegion(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    city: getCity(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    postcode: getPostcode(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    country: resolveCountry(collection.memo_vendor_order.vendor_order_address, 'shipping'),
    phone: getMobileNumber(collection.memo_vendor_order.vendor_order_address, 'shipping')
  }
  data.summary = {
    subtotal: resolveAmount(collection.subtotal),
    shipping_handling: resolveAmount(collection.shipping_amount),
    discount: resolveAmount(collection.discount_amount),
    grand_total: resolveAmount(collection.grand_total)
  }
  data.products = resolveProductItem(collection.memo_items)
  return data
}

export const resolveCountry = (collection, type) => {
  const addressType = getAddressType(collection, type)
  return getCountryName(addressType.country_id)
}

export const resolveProductItem = (collection) => {
  return collection.map((item) => {
    let newItem = Object.assign({},{})
    newItem.sku = item.sku
    newItem.name = _.escape(item.name)
    newItem.image = `${PRODUCT_META_URL}${item.product.small_image}`
    newItem.price = resolveAmount(item.price)
    newItem.qty = parseInt(item.qty)
    newItem.tax_ratio = resolveAmount(item.tax_ratio)
    newItem.row_total = resolveAmount(item.row_total)
    return newItem
  })
}

export const resolveTracks = (collection) => {
  return collection.map((item) => {
    let newItem = Object.assign({},{})
    newItem.title = item.title
    newItem.number = item.track_number
    return newItem
  })
}

export const getAddressType = (collection, type) => {
  return collection.filter((item) => item.address_type === type )[0]
}

export const getCountryName = (value) => {
  return country_map.filter((item) => item.code === value)[0].name
}

export const getPaymentInfo = (collection) => {
  return JSON.parse(collection).method_title
}

export const getClientName = (collection) => {
  return `${collection.customer_firstname} ${collection.customer_lastname}`
}

export const getMobileNumber = (collection, type) => {
  return getAddressType(collection, type).telephone
}

export const getCustomerName = (collection, type) => {
  const address = getAddressType(collection, type)
  return `${address.firstname} ${address.lastname}`
}

export const getFullAddress = (collection, type) => {
  const address = getAddressType(collection, type)
  const country = getCountryName(address.country_id)
  return `${address.company}<br/>${address.street}<br/>${address.city}, ${address.region}, ${address.postcode}<br/>${country}`
}

export const getStreet = (collection, type) => {
  return getAddressType(collection, type).street
}

export const getRegion = (collection, type) => {
  return getAddressType(collection, type).region
}

export const getCity = (collection, type) => {
  return getAddressType(collection, type).city
}

export const getPostcode = (collection, type) => {
  return getAddressType(collection, type).postcode
}

export const canCancel = (vo_collection, so_collection, vendor_id) => {
  return vo_collection.status === STAT_NEW
}

export const canInvoice = (vo_collection, so_collection, vendor_id) => {
  const filtered = vo_collection.filter(item => item.status === STAT_PENDING && item.vendor_id === vendor_id)
  return filtered.length > 0
}

export const canShip = (vo_collection, so_collection, vendor_id) => {
  const valid_vo = vo_collection.filter((item) => item.status === STAT_PENDING).length === 2
  const is_so_new = so_collection.state === STAT_NEW
  const is_so_processing = so_collection.state === STAT_PROCESSING
  const is_valid = valid_vo && is_so_new
  return is_valid || is_so_processing
}

export const canCreditMemo = (vo_collection, so_collection, vendor_id) => {
  const valid_status = [STAT_PROCESSING, STAT_COMPLETE]
  const is_valid = vo_collection.filter((item) => {
    return item.status === so_collection.state && item.vendor_id === vendor_id && valid_status.includes(item.status)
  }).length > 0
  return is_valid
}

export const resolveAmount = (val) => {
  return val ? Math.abs(parseFloat(val).toFixed(2)) : 0
}

export const resolveDate= (val) => {
  return moment(val).format('MMM DD, YYYY')
}

export const resolveDatetime= (val) => {
  return moment(val).format('MMM DD, YYYY h:mm:ss a')
}

export const getOrderStatus = (status, orderStatus, vendor_id) => {
  const target_vendor_order = orderStatus.vendor_sales.find((order) => order.vendor_id === vendor_id)

  if(status === 'pending_payment') {
    return 'Unpaid'
  }

  if(status === 'processing') {
    if(!orderStatus.canInvoice && !orderStatus.canShip && orderStatus.canCreditMemo && !target_vendor_order.has_tracks) {
      return 'To Ship'
    }
    if(!orderStatus.canInvoice && !orderStatus.canShip && orderStatus.canCreditMemo && target_vendor_order.has_tracks) {
      return 'Shipping'
    }
  }

  if(status === 'closed') {
    return 'Refund'
  }
  
  return _.startCase(status)
}