import React from "react"
import { Card, Form, Input, Switch, Space, Row, Col } from "antd"
import _ from "lodash"

export default function ProductGeneral(props) {
  const currentData = props.currentData

  const getNameTooltip = () => {
    const value = currentData.product_updates['name']
    return `Pending Update: ${value}`
  }

  const getSkuTooltip = () => {
    const value = currentData.product_updates['sku']
    return `Pending Update: ${value}`
  }

  const getStatusTooltip = () => {
    const value = currentData.product_updates['status']
    return `Pending Update: ${value}`
  }

  return (
    <div className="second_step_form">
      <Space
        direction="vertical" size="middle" style={{ display: 'flex' }}
      >
        <div className="product_name_card">

          <Card size="small">
            <Row gutter={16}>
              <Col xl={13} lg={12} sm={24}>
                <Form.Item name="name" label="Product Name: " rules={[{ required: true }]} tooltip={_.isUndefined(currentData.product_updates['name']) ? null : getNameTooltip}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xl={7} lg={12} sm={24}>
                <Form.Item name="sku" label="SKU: " rules={[{ required: true }]} tooltip={_.isUndefined(currentData.product_updates['sku']) ? null : getSkuTooltip}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xl={4} lg={24} sm={24} className="is_enable_btn">
                <Form.Item name="status" label="Is Enabled:" valuePropName="checked" tooltip={_.isUndefined(currentData.product_updates['status']) ? null : getStatusTooltip}>
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Card>

        </div>
      </Space >
    </div >
  )
}