import React, {useEffect, useState} from "react"
import _ from "lodash"
import { Spin, Empty } from "antd"

import { getOrder } from "../../../api/order.api"

import OrderAccountInformation from "./childs/OrderAccountInformation"
// import AddressInformation from "./childs/AddressInformation"
// import PaymentShippingMethod from "./childs/PaymentShippingMethod"
import ItemOrderedTable from "./childs/ItemOrderedTable"
import TotalSummary from "./childs/TotalSummary"
import OrderTotalSummary from "./childs/OrderTotalSummary"

const colStyle = {display: 'flex'}
const cardStyle = {flex:1}
const labelStyle = { fontWeight: 'bold' }

export default function OrderInformation(props) {
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMount = true
    setLoading(true)
    const fetchData = async() => {
      try {
        const { entity_id } = props
        const res = await getOrder({ entity_id })
        const res_data = res.data
        if(isMount) {
          setCurrentData(res_data)
          setLoading(false)
        }
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    
    fetchData()

    return () => {  
      isMount = false
    }

  }, [props])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin>  :
    <>
      <OrderAccountInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} labelStyle={labelStyle} orderStatus={props.orderStatus} {...props}/>
      <br />
      {/*<AddressInformation data={currentData} colStyle={colStyle} cardStyle={cardStyle} />
      <br />
      <PaymentShippingMethod data={currentData} colStyle={colStyle} cardStyle={cardStyle} />
      <br />*/}
      <ItemOrderedTable data={currentData}/>
      <br />
      <OrderTotalSummary data={currentData} colStyle={colStyle} cardStyle={cardStyle}/>
      <br />
      <TotalSummary data={currentData} colStyle={colStyle} cardStyle={cardStyle} />
    </>
  )
}
