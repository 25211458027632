import React, {useState, useEffect} from "react"
import _ from "lodash"
import Button from "antd-button-color"
import fileDownload from "js-file-download"
import { PageHeader, Spin, Empty, message } from 'antd'
import { PrinterOutlined } from "@ant-design/icons"

import { getUser } from "../../utils/auth"
import { getShipment } from "../../api/order.api"
import { downloadShipment, downloadShippingLabel } from "../../api/download.api"

import ShipmentView from "../../modules/Order/shipment/View"
import { ShipmentPrintableMapper } from "../../helpers/order.helper"

export default function OrderShipmentView(props) {
  const {vendor_id} = getUser()
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [printLoading, setPrintLoading] = useState(false)
  // const [lbmLoading, setLbmLoading] = useState(false)
  const [labelLoading, setLabelLoading] = useState(false)
  // const [mailLoading, setMailLoading] = useState(false)
  // const [modalVisible, setModalVisible] = useState(false)

  // const showModal = () => {
  //   setModalVisible(true)
  // }

  // const hideModal = () => {
  //   setModalVisible(false)
  // }

  const handlePrint = async() => {
    try {
      setPrintLoading(true)
      const collection = ShipmentPrintableMapper(currentData)
      const filename = `shipment-${collection.increment_id}.pdf`
      const res = await downloadShipment({
        collection
      })
      fileDownload(res.data, filename)
      setPrintLoading(false)
    } catch(err) {
      setPrintLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }
  const handleDownloadShippingLabel = async() => {
    try {
      setLabelLoading(true)
      const timestamp = Date.now()
      const filename = `shipping-label-${timestamp}.pdf`
      let payload = resolveLabelPayload()
      const res = await downloadShippingLabel(payload)
      fileDownload(res.data, filename)      
      setLabelLoading(false)
    } catch(err) {
      setLabelLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }
  // const handleDownloadLamboMove = async () => {
  //   try {
  //     setLbmLoading(true)
  //     const timestamp = Date.now()
  //     const filename = `lambomove-label-${timestamp}.pdf`
  //     let payload = resolveLamboMoveTrackPayload()
  //     const res = await downloadLabel({
  //       payload
  //     })
  //     fileDownload(res.data, filename)
  //     setLbmLoading(false)
  //   } catch(err) {
  //     setLbmLoading(false)
  //     console.error('unable to catch:', err)
  //     message.warning('something went wrong...')
  //   }
  // }
  // const handleSendTrackingInformation = async() => {
  //   try {
  //     setMailLoading(true)
  //     const payload = {
  //       id: currentData.entity_id,
  //       action: 'shipment'
  //     }
  //     const res = await sendNotify(payload)
  //     if(!res.data.result) {
  //       message.warning('unable to send email')
  //     } else {
  //       message.success('sent email successfully')
  //     }
  //     setModalVisible(false)
  //     setMailLoading(false)
  //   } catch(err) {
  //     setMailLoading(false)
  //     console.error('unable to catch:', err)
  //     message.warning('something went wrong...')
  //   }
  // }

  // const resolveLamboMoveTrackPayload = () => {
  //   const shiptracks = currentData.shiptracks
  //   const sortedTracks = _.orderBy(shiptracks, 'entity_id', 'desc')
  //   const selectedTrack = sortedTracks.filter((item) => item.carrier_code === 'custom')[0]
  //   let payload = {
  //     dropOffRef: '',
  //     id: currentData.entity_id,
  //     shipment: 'lamboMove',
  //     vendorId: vendor_id
  //   }
  //   if(selectedTrack) {
  //     payload.dropOffRef = selectedTrack.systemDropOffRefNo
  //   }
  //   return payload
  // }

  const resolveLabelPayload = () => {
    let payload = {
      entity_id: currentData.shipment_vendor_order.entity_id,
      vendor_id: vendor_id
    }
    return payload
  }
  
  const action_buttons = () => {
    const buttons = []
    const printButton = (<Button type="success" key="btn_print" icon={<PrinterOutlined />} onClick={handlePrint} loading={printLoading}>Print</Button>)
    const shippingLabel = (<Button type="success" key="btn_shipping_label" icon={<PrinterOutlined />} onClick={handleDownloadShippingLabel} loading={labelLoading}>Download Shipping Label</Button>)
    // const lbmLabel = (<Button type="success" key="btn_lbm_label" icon={<PrinterOutlined /> } onClick={handleDownloadLamboMove} loading={lbmLoading}>Download LamboMove Label</Button>)
    // const tracking = (<Button type="primary" key="btn_tracking" icon={<CheckCircleOutlined />} onClick={showModal} >Send Tracking Information</Button>)

    buttons.push(printButton)
    buttons.push(shippingLabel)
    // buttons.push(lbmLabel)
    // buttons.push(tracking)

    return buttons
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getShipment({entity_id: props.match.params.id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()

  }, [props])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <>
    <PageHeader
      className="site-page-header"
      onBack={() => window.history.back()}
      title="Shipment Details"
      extra={action_buttons()}
    >
      <ShipmentView data={currentData} />
    </PageHeader>
    {/* <Modal
      visible={modalVisible}
      title="Confirmation"
      okText="Confirm"
      cancelText="Cancel"
      onOk={handleSendTrackingInformation}
      confirmLoading={mailLoading}
      onCancel={hideModal}
      >
      <p>Are you sure you want to send a Shipment email to customer?</p>
    </Modal>  */}
    </>
  )
}