import React, {useState, useEffect} from "react"
import { Statistic } from "antd"
import { ProCard } from '@ant-design/pro-components'
import RcResizeObserver from 'rc-resize-observer'
import { CreditCardOutlined, ShoppingOutlined, BarChartOutlined, TagOutlined } from "@ant-design/icons/lib/icons"

import { getStatsRow } from "../../api/dashboard.api"

export default function StatCardRow(props) {
  const [responsive, setResponsive] = useState(false)
  const [dataSource, setDataSource] = useState({avgOrders:0, credits:0, lifetimeSales:0, totalProducts: 0})

  const fetchData = async() => {
    try {
      const res = await getStatsRow()
      const res_data = res.data
      setDataSource(res_data)
    } catch (err) {
      console.error('unable to catch:', err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <RcResizeObserver
        key="resize-observer"
        onResize={(offset) => {
          setResponsive(offset.width < 596);
        }}
      >
      <ProCard.Group className="dashboard_top" direction={responsive ? 'column' : 'row'} wrap={true} bordered>
        <ProCard colSpan={{ xs: 24, sm:12, md: 12, lg: 6 }}>
          <Statistic className="credit_amount_box" title="Credit Amount" value={dataSource.credits} precision={2} prefix="RM" suffix={<CreditCardOutlined />} />
        </ProCard>
        <ProCard colSpan={{xs: 24, sm:12, md: 12, lg: 6 }}>
          <Statistic className="lifetime_sales_box" title="Lifetime Sales" value={dataSource.lifetimeSales} precision={2} prefix="RM" suffix={<ShoppingOutlined />} />
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm:12, md: 12, lg: 6 }}>
          <Statistic className="average_orders_box" title="Average Orders" value={dataSource.avgOrders} precision={2} suffix={<BarChartOutlined />} prefix="RM" />
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm:12, md: 12, lg: 6 }}>
          <Statistic className="total_products_box" title="Total Products" value={dataSource.totalProducts} precision={0} suffix={<TagOutlined />}/>
        </ProCard>
      </ProCard.Group>
    </RcResizeObserver>
  )
}