import React from 'react'
import { Space } from 'antd'

import ProductGeneral from './ProductGeneral'
import ProductCategory from './ProductCategory'
import ProductInformation from './ProductInformation'
import PriceInformation from './PriceInformation'
import Specification from './Specification'
import Shipping from './Shipping'
import Variation from './Variation'

export default function EditProductForm({ formRef, specList, setSpecList, currentData }) {
  return (
    <Space
      direction="vertical" size="middle" style={{ display: 'flex' }}
    >
      <ProductGeneral currentData={currentData} />
      <ProductCategory formRef={formRef} currentData={currentData} />
      <ProductInformation formRef={formRef} currentData={currentData} />
      <PriceInformation currentData={currentData} />
      <Specification currentData={currentData} />
      <Shipping currentData={currentData} />
      <Variation formRef={formRef} specList={specList} setSpecList={setSpecList} />
    </Space>
  )
}
