import React from "react"
import { Space } from "antd"

import AvatarDropdown from "./AvatarDropdown"
import NotifyView from "./NotifyView"

import './index.css'

export default function RightContent(props) {
  return (
    <Space className="right">
        <AvatarDropdown {...props} />
        <NotifyView />
    </Space>
  )
}
