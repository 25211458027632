import axios from "axios";
import successHandler from "./successHandler";
import errorHandler from "./errorHandler";

import { getToken  } from "../utils/auth";

const axiosInstance = axios.create({
	baseURL: process.env.API_URL,
	timeout: 600000,
})

axiosInstance.interceptors.request.use(function (config) {
	// Do something before request is sent
	config.headers.Authorization = 'Bearer ' + getToken()
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

const request = {
  post: async (entityUrl, jsonData, option = {}) => {
    try {
      const response = await axiosInstance.post(entityUrl, jsonData);
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    }
  },
  get: async (entityUrl, params) => {
    try {
      const response = await axiosInstance.get(entityUrl, { params });
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    }
  },
  patch: async (entityUrl, jsonData) => {
    try {
      const response = await axiosInstance.patch(entityUrl, jsonData);
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    }
  },
  put: async (entityUrl, jsonData) => {
    try {
      const response = await axiosInstance.put(entityUrl, jsonData);
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    }
  },
}

export default request