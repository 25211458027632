import React, { useCallback, useEffect, useState } from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { uploadService } from '../../../../utils/uploader';

import { MAGENTO_META_URL } from "../../../../const/config";

import _ from "lodash"
import {DeleteOutlined } from '@ant-design/icons'

export default function VariationImage({showDelete = false, deleteImage = () => {}, indexNum, defaultImg, single, callBack, formRef, fieldName, listType, showUploadList, viewModal, subIndex, specList }) {
  const [fileList, setFileList] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [uploading, setUploading] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const addPicture = useCallback((data) => {
    const uid = Math.random() * 10000
    if (single) {
      setImageUrl(data ? `${MAGENTO_META_URL}/catalog/product${data.url}` : `${MAGENTO_META_URL}/catalog/product${defaultImg}`)
      setUploading(false)
    } else {
      if (data) {
        fileList.push({
          uid,
          name: data.name,
          status: 'done',
          url: data.url,
        })
      } else {
        defaultImg.split().forEach(c => {
          fileList.push({
            uid,
            name: c,
            status: 'done',
            url: c,
          })
        })
      }
      setFileList([...fileList])
    }
  }, [defaultImg, fileList, single])

  const handleUpload = async(file) => {
    if (single) setUploading(true)
    let progress = { percent: 1 }

    try {
      const formData = new FormData()
      formData.append("product_cover", file.file)
      formData.append("type", "product_cover")
      const imgData = await uploadService(formData)
      // addPicture(imgData)
      file.onProgress(progress)
      if(imgData.status === 200) {
        file.onProgress({ percent: 100 })
        file.onSuccess(imgData.data)
      }
      if (callBack) callBack(single ? imgData.data.url_path : fileList)
    } catch (err) {
      setUploading(false)
    }
    if (!single) setFileList([...fileList])
  }

  const handleImgPreview = (file) => {
    if (!file.url) return
    setPreviewVisible(true)
    setPreviewImage(file.url)
  }

  const beforeUpload = (file) => {
    const isJpgOrPngOrGif = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPngOrGif) {
      message.error('only can upload jpg、gif、png format');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('image size cannot over 2MB!');
    }
    return isJpgOrPngOrGif && isLt5M;
  }

  const handleChange = (e) => {
    if(_.isEmpty(e.fileList)) {
      setImageUrl('')
    }

    if (e.file.status === 'done' || e.file.status === 'uploading') {
      // Get this url from response in real world.
      getBase64(e.file.originFileObj, imageUrl => {
        setImageUrl(imageUrl)
      });
    }
    // if (onChange) onChange(e)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const onRemove = (e) => {
    const removedFileList = fileList.filter(c => c.name !== e.name)
    if (formRef && fieldName && !single) {
      const fieldValue = {}
      fieldValue[fieldName] = removedFileList.map(c => c.url).join()
      formRef?.current?.setFieldsValue(fieldValue)
      if (callBack) callBack(removedFileList)
      setFileList(removedFileList)
    }
  }

  const deleteTypeImage = () => {
    setImageUrl('')
    setPreviewImage('')
    deleteImage()
  }

  const getResolvedLabelName = () => {
    return specList[indexNum].subSpecList.find((item) => item.subSpecId === subIndex).value
  }

  useEffect(() => {
    if (defaultImg) {
      addPicture()
    }
  }, [defaultImg, addPicture])

  return (
    <div style={{position:'relative'}}>
      <Upload
        listType={listType || 'picture-card'}
        fileList={fileList}
        customRequest={handleUpload}
        onPreview={file => handleImgPreview(file)}
        beforeUpload={beforeUpload}
        showUploadList={showUploadList || true}
        onChange={handleChange}
        onRemove={onRemove}
      >
        {!single &&
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">{ getResolvedLabelName() }</div>
          </div>}

        {single &&
          (imageUrl !== '' ? <img src={imageUrl} alt="avatar" style={{ width: '50%' }} /> : (
            <div>
              {uploading ? <LoadingOutlined /> : <PlusOutlined/> }
              <div className="ant-upload-text">{ getResolvedLabelName() }</div>
            </div>
          ))}
      </Upload>
      {viewModal &&
        <Modal visible={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      }
      <div style={{position:'absolute',bottom: '10px', right: '31px', display: showDelete && imageUrl ? 'block' : 'none' }}>
        <DeleteOutlined onClick={e => deleteTypeImage()}/>
      </div>
    </div>
  )
}