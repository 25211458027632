import React, {useState, useEffect} from "react"
import { Table } from "antd"
import { getGrid } from "../../../api/dashboard.api"
import moment from 'moment'
import _ from 'lodash'

const columns = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => moment(val).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: val => _.startCase(_.camelCase(val))
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
]

export default function GridLastTransactions(props) {
  const [dataSource, setDataSource] = useState([])

  const fetchData = async() => {
		try {
      const res = await getGrid({ by_time: '2y', by_type: 'last_transaction' })
      const res_data = res.data
      setDataSource(res_data)
    } catch (err) {
      console.error('unable to catch:', err)
    }
	}

	useEffect(() => {
		fetchData()
	}, [])

  return (
    <Table dataSource={dataSource} columns={columns} pagination={false} />
  )
}