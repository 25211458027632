import React from "react"
import { Card, Row, Col } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { customer_group_map } from "../../../../const/order"

export default function OrderAccountInformation(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle
  const labelStyle = props.labelStyle

  const customTitle = () => {
    return `Order & Account Information #${currentData.order.increment_id}`
  }

  return (
    <Card title={customTitle()}>
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={2} title="Order Information" bordered>
            <ProDescriptions.Item span={2} label="Order Date" labelStyle={labelStyle}>{ currentData.created_at }</ProDescriptions.Item>
            <ProDescriptions.Item span={2} label="Order Status" labelStyle={labelStyle}>{ currentData.status }</ProDescriptions.Item>
            <ProDescriptions.Item span={2} label="Purchased From" valueType="text" labelStyle={labelStyle}>{ currentData.order.store_name.replace(/\n/g, ' -> ') }</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={2} title="Account Information" bordered>
            <ProDescriptions.Item span={2} label="Customer Name" labelStyle={labelStyle}>{currentData.order.customer_firstname} {currentData.order.customer_lastname}</ProDescriptions.Item>
            <ProDescriptions.Item span={2}label="Email" labelStyle={labelStyle}>{ currentData.order.customer_email }</ProDescriptions.Item>
            <ProDescriptions.Item span={2} label="Customer Group" labelStyle={labelStyle}>{ customer_group_map[currentData.order.customer_group_id] }</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}