const MAGENTO_META_URL= process.env.REACT_APP_MAGENTO_META_URL ? process.env.REACT_APP_MAGENTO_META_URL :'https://bo.lamboplace.com/pub/media'
const UPLOAD_META_URL= process.env.REACT_APP_UPLOAD_META_URL ? process.env.REACT_APP_UPLOAD_META_URL : 'https://bo.lamboplace.com'
const LBM_API_URL = process.env.REACT_APP_LBM_URL ? process.env.REACT_APP_LBM_URL : 'https://bo.lamboplace.com/curl.php'
const LABEL_URL = process.env.REACT_APP_LABEL_URL ? process.env.REACT_APP_LABEL_URL : 'https://bo.lamboplace.com/curl.php'
const CLOUDFLARE_URL = process.env.REACT_APP_MAGENTO_CLOUDFLARE_URL ? process.env.REACT_APP_MAGENTO_CLOUDFLARE_URL : 'https://d2hwz37rd4sfav.cloudfront.net'
const PUBLIC_URL = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : 'https://lambo-vendor.techzonite.com'

module.exports = {
  MAGENTO_META_URL,
  UPLOAD_META_URL,
  LBM_API_URL,
  LABEL_URL,
  CLOUDFLARE_URL,
  PUBLIC_URL
}