import _ from "lodash"
import moment from "moment"

export const getRandomIntID = () => {
  return parseInt(Math.random() * 1000)
}

export const getEditFormInitialValues = (collection) => {
  const { special_from_date, weight_type, price } = collection

  if(!_.isUndefined(special_from_date)) {
    collection.special_price_datetime_range = [
      moment(collection.special_from_date),moment(collection.special_to_date)
    ]
  }

  if(_.isUndefined(weight_type)) {
    collection.weight_type = 1
  } 

  if(_.isUndefined(price)) {
    collection.price = 0
  }

  return collection
}

export const prepareNewFormPayload = (collection, specList, vendor_id, description, short_description) => {
  const { special_price_datetime_range, cover, gallery, variations } = collection

  if(special_price_datetime_range) {
    const { from_date, to_date } = splitDates(special_price_datetime_range)
    collection.special_from_date = from_date
    collection.special_to_date = to_date
  }

  if(description) {
    collection.description = description
  }

  if(short_description) {
    collection.short_description = short_description
  }

  if(cover) {
    collection.cover = getCoverUploadUrl(cover)
  }

  if(gallery) {
    collection.gallery = getGalleryUploadUrls(gallery)
  }

  if(variations) {
    collection.variations = resolveVariationPayload(variations)
    collection.variationSpec = JSON.stringify(specList)
  }

  collection.vendor_id = vendor_id

  return collection
}

export const getEditFormPayload = (collection, currentData, brandList, specList, description, short_description) => {
  const { special_price_datetime_range, cover, gallery, variations } = collection

  if(special_price_datetime_range) {
    const { from_date, to_date } = splitDates(collection.special_price_datetime_range)
    collection.special_from_date = from_date
    collection.special_to_date = to_date
  }

  if(cover) {
    collection.cover = getEditCoverUploadUrl(cover)
  }

  if(!_.isUndefined(gallery)) {
    collection.gallery = getEditGalleryUploadUrls(gallery)
  } else {
    collection.gallery = currentData.photos
  }

  if(specList && !_.isUndefined(variations)) {
  //if(specList.length > 0) {
    collection.variationSpec = JSON.stringify(specList)
    
    if(JSON.stringify(currentData.variations) === JSON.stringify(variations)) {
      collection.requireDeepCheckVariation = false
    } else {
      collection.requireDeepCheckVariation = true
    }
  } else {
    collection.requireDeepCheckVariation = false
    //collection.variationSpec = specList
    //collection.requireDeepCheckVariation = true
  }

  if(description) {
    collection.description = description
  }

  if(short_description) {
    collection.short_description = short_description
  }

  if(currentData.variations.length === 0) {
    collection.isNewVariation = true
  } else {
    collection.isNewVariation = false
  }

  getObjDiff(collection,currentData,brandList)

  collection.entity_id = currentData.entity_id
  collection.vendor_id = currentData.vendor_id
  
  return collection
}

//this function is to pass the product_updates of current data to collection
const getObjDiff = async(obj1,obj2,brandList) => {
  let productUpdateKey = Object.keys(obj2['product_updates'])
  productUpdateKey.forEach((key) => {
    if(key === 'mgs_brand') {
      if(!obj1['brand']) {
        const brandId = brandList.find((brand) => brand.option_id === obj2['product_updates'][key])
        obj1['brand'] = brandId.brand_id
      }
    }
    if(_.isUndefined(obj1[key]) && _.isUndefined(obj2[key])) {
      obj1[key] = obj2['product_updates'][key]
      productUpdateKey = productUpdateKey.filter(updatekey => updatekey !== key)
    } else if(obj1[key] === obj2[key]) {
      obj1[key] = obj2['product_updates'][key]
      productUpdateKey = productUpdateKey.filter(updatekey => updatekey !== key)
    } else if (_.isUndefined(obj2[key])) {
      productUpdateKey = productUpdateKey.filter(updatekey => updatekey !== key)
    } else if(_.isNull(obj2[key])) {
      if(!obj1[key]) {
        obj1[key] = obj2['product_updates'][key]
      }
      productUpdateKey = productUpdateKey.filter(updatekey => updatekey !== key)
    } else if(obj1[key] !== obj2[key]) {
      productUpdateKey = productUpdateKey.filter(updatekey => updatekey !== key)
    } 
  })
  if(productUpdateKey.length > 0) {
    for(let i=0; i<productUpdateKey.length; i++) {
      obj1[productUpdateKey[i]] = obj2['product_updates'][productUpdateKey[i]]
    }
  }
}

const getCoverUploadUrl = (val) => {
  if(_.isUndefined(val)) {
    return ''
  }
  return val[0].response.url_path
}

const getGalleryUploadUrls = (val) => {
  if(_.isUndefined(val)) {
    return ''
  }

  return val.map((item) => {
    return item.response.url_path
  })
}

const getEditCoverUploadUrl = (val) => {
  if(_.isUndefined(val)) {
    return ''
  }
  if(_.isObject(val[0])) {
    return val[0].response.url_path
  }
  return val[0]
}

const getEditGalleryUploadUrls = (val) => {
  if(_.isUndefined(val)) {
    return ''
  }

  return val.map((item) => {
    if(_.isObject(item)) {
      if(!_.isUndefined(item.response)) {
        return item.response.url_path
      }
    }
    return item
  })
}

const resolveVariationPayload = (collection) => {
  // return collection.map((data) => {
  //   const { cover } = data
  //   data.cover = getCoverUploadUrl(cover)
  //   return data
  // })
  return collection
}

const splitDates = (dates) => {  
  const from_date = dates[0]
  const to_date = dates[1]
  return {
    from_date,
    to_date
  }
}

export const resolveShortDescription = (description) => {
  if(description) {
    return description.replace(/(<([^>]+)>)/gi, "")
  }
  return ''
}