import React from "react"
import { Card, Table, Form, InputNumber} from "antd"

import { resolveAmount } from "../../../../helpers/order.helper"

export default function ItemOrderedTable(props) {
  const currentData = props.data

  const rules = [{ required: true, message:'cannot be empty'} ]

  const product_columns = [
    {
      title: 'Product',
      dataIndex: ['name'],
      key: 'name',
      width: '25%',
      ellipsis: true,
      render: (text, record, index) => {
        return <>{getItemVal(index, 'name')}</>
      }
    },
    {
      title: 'Sku',
      dataIndex: ['sku'],
      key: 'sku',
      width: '10%',
      ellipsis: true,
      render: (text, record, index) => {
        return <>{getItemVal(index, 'sku')}</>
      }
    },
    {
      title: 'Price',
      dataIndex: ['price'],
      key: 'price',
      render: (text, record, index) => {
        return <>RM {resolveAmount(getItemVal(index, 'price'))}</>
      }
    },
    {
      title: 'Quantity',
      dataIndex: ['qty_ordered'],
      key: 'quantity',
      render: (text, record, index) => {
        const items = [
          { title:'Ordered', value: getItemVal(index, 'qty_ordered')}, 
          { title:'Canceled', value: getItemVal(index, 'qty_canceled')},
          { title:'Invoiced', value: getItemVal(index, 'qty_invoiced')},
          { title:'Shipped', value: getItemVal(index, 'qty_shipped')}
        ]
        const filtered_items = items.filter(item => item.value > 0)
        return (<table><tbody>
          { filtered_items.map((item, index) => {
            return (
              <tr key={index}><th>{item.title}</th><td>{item.value}</td></tr>
            )
          } )}
        </tbody></table>)
      }
    },
    {
      title: 'Qty to Invoice',
      dataIndex: ['qty_ordered'],
      key: 'qty',
      render: (text, record, index) => {
        return (
          <Form.Item name={[record.name, "qty_ordered"]} rules={rules}>
            <InputNumber min={0} max={getItemVal(index, 'qty_ordered')} />
          </Form.Item>
        )
      }
    },
    {
      title: 'Subtotal',
      dataIndex: ['price'],
      key: 'subtotal',
      render: (text, record, index) => {
        return <>RM {resolveAmount(getItemVal(index, 'price'))}</>
      }
    },
    {
      title: 'Tax Amount',
      dataIndex: ['tax_amount'],
      key: 'tax_amount',
      render: (text, record, index) => {
        return <>RM {resolveAmount(getItemVal(index, 'tax_amount'))}</>
      }
    },
    {
      title: 'Discount Amount',
      dataIndex: ['discount_amount'],
      key: 'discount_amount',
      render: (text, record, index) => {
        return <>RM {resolveAmount(getItemVal(index, 'discount_amount'))}</>
      }
    },
    {
      title: 'Row Total',
      dataIndex: ['row_total'],
      key: 'row_total',
      render: (text, record, index) => {
        return <>RM {resolveAmount(getItemVal(index, 'price') - getItemVal(index, 'discount_amount'))}</>
      }
    }
  ]

  const getItemVal = (index, key) => {
    return currentData.products.filter((val, i) => {
      return i === index
    })[0][key]
  }

  return (
    <Card title="Items Ordered">
      <Form.List name={['invoice', 'items']}>
        {(fields, { add, remove }) => {
          return (<Table rowKey="name" columns={product_columns} dataSource={fields} pagination={false} />)
        }}
      </Form.List>
    </Card>
  )
}