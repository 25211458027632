import React, {useState, useEffect} from "react"
import { Table } from "antd"
import { getGrid } from "../../../api/dashboard.api"
import moment from 'moment'

const columns = [
  {
    title: 'ID',
    dataIndex: 'entity_id',
    key: 'entity_id',
  },
  {
    title: 'Purchased Date',
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => moment(val).format('DD MMM YYYY HH:mm:ss')
  },
  {
    title: 'Customer',
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: 'Grand Total',
    dataIndex: 'grand_total',
    key: 'grand_total',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
]

export default function GridLastOrders(props) {
  const [dataSource, setDataSource] = useState([])

  const fetchData = async() => {
		try {
      const res = await getGrid({ by_time: '2y', by_type: 'last_order' })
      const res_data = res.data
      setDataSource(res_data)
    } catch (err) {
      console.error('unable to catch:', err)
    }
	}

	useEffect(() => {
		fetchData()
	}, [])

  return (
    <Table dataSource={dataSource} columns={columns} pagination={false} />
  )
}