import React, {useState} from "react"
import { Modal, Result, Button, message, Form, Input, Select } from "antd"
import { handleError } from "../../../utils/errorResponse"
import { simplyCancelOrder } from "../../../api/order.api"
import _ from "lodash"

const { Option } = Select

export default function PopupCreditMemo(props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [reasonVisible, setReasonVisible] = useState(false)
  const isVisible = props.isVisible
  const currentData = props.currentData

  const getCancelTitle = () => {
    return `Cancel Order # ${currentData.order_increment_id}`
  }

  const getFormInitValues = () => {
    return {
      refund_amount: currentData.refund_amount
    }
  }

  const showReason = () => {
    setReasonVisible(true)
    props.setVisibleState(false)
  }

  const hideReason = () => {
    setReasonVisible(false)
  }

  const handleCancel = () => {
    props.setVisibleState(false)
  }

  const handleSubmit = async() => {
    try {
      const values = await form.validateFields()
      const { reason } = values
      const { order_id, vendor_id } = currentData
      setLoading(true)
      await simplyCancelOrder({ order_id, vendor_id, reason })
      message
      .loading('Action in progress..', 2.5)
      .then(() => {
        message.success('This order has been cancelled...')
        setLoading(false)
      }).then(() => {
        hideReason()
        props.setSubmitState(true)
      })
    } catch(err) {
      if(_.has(err, 'errorFields')) {
        console.log(err)
      } else {
        setLoading(false)
        hideReason()
        message.warning(handleError(err))
      }
    }
  }

  return (
    <>
      <Modal 
        open={isVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={showReason}>
            Cancel Order
          </Button>,
          <Button key="cancel" onClick={handleCancel}>Don't Cancel</Button>
        ]}
      >
        <Result
          title="Are you want to cancel this order?"
          subTitle="Shipment has been arranged for this order. Please take note that if you cancel this order now, it cannot be undone. Lambo will not liable for any loss from this order."
        />
      </Modal>
      <Modal
        title={getCancelTitle()}
        open={reasonVisible}
        onCancel={hideReason}
        // footer={null}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Cancel This Order
          </Button>
        ]}
      >
        <Form
          initialValues={getFormInitValues()}
          // onFinish={handleSubmit}
          form={form}
        >
          <Form.Item name="refund_amount" label="Refund Amount" labelAlign="left">
            <Input readOnly={true}/>
          </Form.Item>
          <Form.Item name="reason" label="Select Reason" labelAlign="left" rules={[{ required: true, message: 'Please input reason!'}]}>
            <Select
              placeholder="Please select cancel reason."
            >
              <Option value="Out of stock">Out of stock</Option>
              <Option value="Undeliverable area">Undeliverable area</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item>
            <Button key="submit" type="primary" loading={loading} htmlType="submit">
              Cancel This Order
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  )
}