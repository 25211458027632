import React, {useEffect, useState} from "react"
import _ from "lodash"
import { Form, Input, Spin, Empty, Card, Row, Col, Typography, Tag, Button, Space, Select, message } from 'antd'
import { country_map } from "../../const/country"
import { getSellerDetail, updateSellerDetail } from "../../api/profile.api"
import { getRegionList } from "../../api/shared.api"
import { handleError } from "../../utils/errorResponse"
import { getUser } from "../../utils/auth"

const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

export default function SellerDetailForm(props) {
  const { vendor_id } = getUser()
  const [currentData, setCurrentData] = useState({})
  const [regionData, setRegionData] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [form] = Form.useForm()

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  const status_map = [
    { title: 'Pending', color: 'default', value: 1},
    { title: 'Approved', color: '#5CB85C', value: 2},
    { title: 'Disabled', color: '#D9534F', value: 3},
    { title: 'Expired', color: '#F0AD4E', value: 4}
  ]

  const group_map = [
    { title: 'Default', color: 'green', value: 1 },
  ]

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getSellerDetail({vendor_id})
        const res_data = res.data
        const region_data = await getRegionList({country_id: res_data.country_id})
        setCurrentData(res_data)
        setRegionData(region_data.data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    fetchData()
  }, [vendor_id])

  const onFinish = async(values) => {
    try {
      setSubmitLoading(true)
      values.entity_id = vendor_id
      const res = await updateSellerDetail(values)
      setCurrentData(res.data)
      const res_region = await getRegionList({country_id: currentData.country_id})
      setRegionData(res_region.data)
      setSubmitLoading(false)
      message.success('Update Succesfully...')
    } catch (err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  const onChangeCountry = async(e) => {
    const res = await getRegionList({ country_id: e })
    const res_data = res.datas

    if(_.isEmpty(res_data)) {
      form.setFieldsValue({
        region_id: ''
      })
    }

    setRegionData(res_data)
  }

  const getGroup = (id) => {
    return group_map.filter((item) => item.value === id )[0]
  }

  const getStatus = (id) => {
    return status_map.filter((item) => item.value === id )[0]
  }

  const getRegionIdField = (
    <Form.Item label="State/Province" name="region_id">
      { _.isEmpty(regionData) ? <Input /> : 
        <Select>
        { regionData.map((item, index) => {
          return <Option key={index} value={item.region_id}>{item.default_name}</Option>
        })}
      </Select>
      }
    </Form.Item>
  )

  const getRegionField = (
    <Form.Item label="State/Province" name="region">
      <Input />
    </Form.Item>
  )

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <Row>
      <Col span={4} />
      <Col span={16}>
        <Card bordered={false}>
          <Form form={form} onFinish={onFinish} initialValues={{...currentData}} {...formItemLayout}>
            <Form.Item label="Vendor ID">
              <Text style={{color: "red"}}>{currentData.vendor_id}</Text>
            </Form.Item>
            <Form.Item label="Group">
              <Text style={{color:getGroup(currentData.group_id).color}}>{getGroup(currentData.group_id).title}</Text>
            </Form.Item>
            <Form.Item label="Status">
              <Tag color={getStatus(currentData.status).color}>{getStatus(currentData.status).title}</Tag>
            </Form.Item>
            <Form.Item label="Company" name="company">
              <Input />
            </Form.Item>
            <Form.Item label="Address" name="street" rules={[{ required: true, message: 'Please input your address!'}]}>
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item label="City" name="city" rules={[{ required: true, message: 'Please input your city!'}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Country" name="country_id">
              <Select onChange={onChangeCountry}>
                { country_map.map((item, index) => {
                  return <Option key={index} value={item.code}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
              { currentData.region ? getRegionField : getRegionIdField }
            <Form.Item label="Postcode" name="postcode" rules={[{ required: true, message: 'Please input your postcode!'}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Phone" name="telephone" rules={[{ required: true, message: 'Please input your mobile number!'}]}>
              <Input />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space size={100}>
                <Button loading={submitLoading} type="primary" htmlType="submit">Submit</Button>
                <Button htmlType="button" onClick={onReset}>Reset</Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={4} />
    </Row>
  )

}