import React from "react"
import { Card, Row, Col } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { getMobileNumber, getCustomerName, getFullAddress } from "../../../../helpers/order.helper"

export default function AddressInformation(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Address Information">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={1} title="Billing Address" layout="vertical">
            <ProDescriptions.Item>{ getCustomerName(currentData.shipment_vendor_order.vendor_order_address, 'billing') }</ProDescriptions.Item>
            <ProDescriptions.Item><div dangerouslySetInnerHTML={{__html: getFullAddress(currentData.shipment_vendor_order.vendor_order_address, 'billing') }}></div></ProDescriptions.Item>
            <ProDescriptions.Item>T: { getMobileNumber(currentData.shipment_vendor_order.vendor_order_address, 'billing')}</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="small" column={1} title="Shipping Address" layout="vertical">
            <ProDescriptions.Item>{ getCustomerName(currentData.shipment_vendor_order.vendor_order_address, 'shipping') }</ProDescriptions.Item>
            <ProDescriptions.Item><div dangerouslySetInnerHTML={{__html: getFullAddress(currentData.shipment_vendor_order.vendor_order_address, 'shipping') }}></div></ProDescriptions.Item>
            <ProDescriptions.Item>T: { getMobileNumber(currentData.shipment_vendor_order.vendor_order_address, 'shipping')}</ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}