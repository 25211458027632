import React from "react"
import { Card, Divider, Row, Col, Form, Input, InputNumber, Checkbox } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { resolveAmount } from "../../../../helpers/order.helper"

export default function RefundSummary(props) {
  const currentData = props.data
  const colStyle = props.colStyle
  const cardStyle = props.cardStyle

  return (
    <Card title="Order Total">
      <Row gutter={16}>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions title="Credit Memo Comments"/>
          <Form.Item name="comment_text">
            <Input.TextArea rows={5} />
          </Form.Item>
        </Card>
        </Col>
        <Col span={12} style={colStyle}>
        <Card style={cardStyle}>
          <ProDescriptions size="middle" column={1} title="Refund Totals" bordered>
            <ProDescriptions.Item label="Subtotal"> RM {resolveAmount(currentData.subtotal) }</ProDescriptions.Item>
            <ProDescriptions.Item label="Discount"> - RM { resolveAmount(currentData.discount_amount) }</ProDescriptions.Item>
              <Divider dashed />
            <ProDescriptions.Item label="Refund Shipping"><Form.Item name="shipping_amount"><InputNumber min={0} step="0.1"/></Form.Item></ProDescriptions.Item>
            <ProDescriptions.Item label="Adjustment Refund"><Form.Item name="adjustment_positive"><InputNumber min={0} /></Form.Item></ProDescriptions.Item>
            <ProDescriptions.Item label="Adjustment Fee"><Form.Item name="adjustment_negative"><InputNumber min={0} /></Form.Item></ProDescriptions.Item>
            <ProDescriptions.Item label="Payment Fee"><Form.Item name="payment_fee"><InputNumber min={0} step="0.1" /></Form.Item></ProDescriptions.Item>
              <Divider dashed />
            <ProDescriptions.Item label="Grand Total"> RM {resolveAmount(currentData.grand_total) }</ProDescriptions.Item>
            <ProDescriptions.Item label="Refund to Store Credit"><Form.Item name="return_applied_credits"><InputNumber min={0} step="0.1" /></Form.Item></ProDescriptions.Item>
            <ProDescriptions.Item>
              <Form.Item name="comment_customer_notify" valuePropName="checked">
                <Checkbox>Append Comments</Checkbox>
              </Form.Item>
            </ProDescriptions.Item>
          </ProDescriptions>
        </Card>
        </Col>
      </Row>
    </Card>
  )
}