import React, {useState, useEffect} from "react"
import { Table } from "antd"
import { getGrid } from "../../../api/dashboard.api"

const columns = [
  {
    title: 'Product',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Views',
    dataIndex: 'qty_ordered',
    key: 'views',
  },
]

export default function GridMostViewedProduct(props) {
  const [dataSource, setDataSource] = useState([])

  const fetchData = async() => {
		try {
      const res = await getGrid({ by_time: '2y', by_type: 'most_viewed_product' })
      const res_data = res.data
      setDataSource(res_data)
    } catch (err) {
      console.error('unable to catch:', err)
    }
	}

	useEffect(() => {
		fetchData()
	}, [])

  return (
    <Table dataSource={dataSource} columns={columns} pagination={false} />
  )
}