import React, { useEffect, useState } from "react"
import { Card } from "antd"
import { Chart, Line, Point, Tooltip } from "bizcharts"
import { getGraph } from "../../../api/dashboard.api"

export default function GraphAmount(props) {
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {
		const fetchData = async() => {
			try {
				const { by_time } = props
				const res = await getGraph({ by_time, by_type: 'amount' })
				const res_data = res.data
				setDataSource(res_data)
			} catch (err) {
				console.error('unable to catch:', err)
			}
		}
		fetchData()
	}, [props])

  return (
		<Card>
			<Chart
				appendPadding={[10, 0, 0, 10]}
				autoFit
				height={250}
				data={dataSource}
				scale={{ value: { min: 0, type: 'linear-strict' }, y: { range: [0, 1] } }}
			>
				<Line position="y*value" />
				<Point position="y*value" />
				<Tooltip showCrosshairs follow={false} />
			</Chart>
		</Card>
  )
}