import { request } from "../request"

const prefix_url = '/api/sales/'

export function getTable(params) {
  return request.post(prefix_url+'table', params)
}

export function getOrder(params) {
  return request.get(prefix_url+'getOrder', params)
}

export function getOrderStatus(params) {
  return request.get(prefix_url+'getOrderStatus', params)
}

export function getInvoice(params) {
  return request.get(prefix_url+'getInvoice', params)
}

export function makeInvoice(params) {
  return request.post(prefix_url+'makeInvoice', params)
}

export function getShipment(params) {
  return request.get(prefix_url+'getShipment', params)
}

export function getShipments(params) {
  return request.get(prefix_url+'getShipments', params)
}

export function makeShipment(params) {
  return request.post(prefix_url+'makeShipment', params)
}

export function getCreditMemo(params) {
  return request.get(prefix_url+'getCreditMemo', params)
}

export function getCreditMemos(params) {
  return request.get(prefix_url+'getCreditMemos', params)
}

export function cancelOrder(params) {
  return request.post(prefix_url+'cancelOrder', params)
}

export function cancelOrders(params) {
  return request.post(prefix_url+'cancelOrders', params)
}

export function sendOrderComment(params) {
  return request.post(prefix_url+'sendOrderComment', params)
}

export function getOrderCommentHistory(params) {
  return request.get(prefix_url+'getOrderCommentHistory', params)
}

export function sendShipmentComment(params) {
  return request.post(prefix_url+'sendShipmentComment', params)
}

export function getShipmentCommentHistory(params) {
  return request.get(prefix_url+'getShipmentCommentHistory', params)
}

export function saveShipTrack(params) {
  return request.post(prefix_url+'saveShipTrack', params)
}

export function removeShipTrack(params) {
  return request.post(prefix_url+'removeShipTrack', params)
}

export function makeCreditMemo(params) {
  return request.post(prefix_url+'makeCreditMemo', params)
}

export function sendNotify(params) {
  return request.post(prefix_url+'sendNotify', params)
}

export function arrangeShipment(params) {
  return request.post(prefix_url+'arrangeShipment', params)
}

export function getShipmentDetailForLabel(params) {
  return request.get(prefix_url+'getShipmentDetailForLabel', params)
}

export function simplyCancelOrder(params) {
  return request.post(prefix_url+'simplyCancelOrder', params)
}