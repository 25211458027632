import React, {useState, useEffect } from "react"
import { PageHeader, Spin, Empty, Form, message } from "antd"
import _ from "lodash"

import { getProduct, storeProduct } from "../../api/product.api"
import { handleError } from "../../utils/errorResponse"
import { getEditFormInitialValues, getEditFormPayload } from "../../helpers/product.helper"

import EditProductForm from "../../components/Product/EditProductForm"

import "./index.css"

export default function EditProductVariationView(props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [isDone, setIsDone] = useState(false)
  const id = props.match.params.id

  const getInitialFormValues = () => {
    const newValues = getEditFormInitialValues(currentData)
    return newValues
  }

  const onFinish = async(values) => {
    try {
      setSubmitLoading(true)
      // const formData = form.getFieldsValue()
      const payload = getFormattedPayload(values)
      await storeProduct(payload)
      setIsDone(true)
    } catch(err) {
      console.debug(err)
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo)
  }

  const getFormattedPayload = (values) => {
    return getEditFormPayload(values, currentData)
  }

  useEffect(() => {
    let isMount = true
    if(isDone) {
      message.success(`Update Product Successfully...`, 2)
      .then(() => {
        if(isMount) {
          setSubmitLoading(false)
        }
        props.history.push(`/admin/products`)
      })
    }
  }, [props, isDone])
  
  useEffect(() => {
    let isMount = true
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getProduct({ entity_id: id })
        const res_data = res.data
        if(isMount) {
          setCurrentData(res_data)
          setLoading(false)
        }
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }

		fetchData()
    return () => {
      isMount = false
    }

  }, [id, props, isDone])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <>
      <PageHeader
        title="Edit product"
        onBack={() => window.history.back()}
      >
      <Form 
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialFormValues()}
      >
        <EditProductForm form={form} submitLoading={submitLoading} currentData={currentData} history={props.history} />
      </Form>
      </PageHeader>
    </>
  )
}