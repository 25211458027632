import React from "react"
import { Form, Select } from "antd"

const { Option } = Select

export default function SpecWarrantyPeriod({ selectedWarrantyType, warrantyPeriodOptions }) {
  return (
    <Form.Item name="warranty_period">
      <Select placeholder="Warranty Period" disabled={selectedWarrantyType === 317}>
      { warrantyPeriodOptions.map(item => (
        <Option key={item.option_id} value={item.option_id}>{item.value}</Option>
      ))}
      </Select>
    </Form.Item>
  )
}