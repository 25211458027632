import React, { useState } from "react"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Form, Divider, Button, Select, Input, Space, InputNumber, Row, Col } from "antd"

import VariationImageUpload from "./VariationImageUpload"

const { Option } = Select

export default function AddVariation(props) {
  const attributes = props.attributes
  const attribute_options = props.attribute_options
  const [attributeOptions, setAttributeOptions] = useState([])

  const handleChangeAttribute = (value) => {
    const selected = attribute_options[value]
    setAttributeOptions(selected)
  }

  return (
    <Form.List name="new_variations">
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <div key={field.key}>
              <Divider>Variation {index + 1}</Divider>
              <Row gutter={16}>
                <Col lg={12} sm={24}>
                  <Form.Item label="Variation Information" labelCol={{ span: 24 }}>
                    <Input.Group compact>
                      <Form.Item name={[index, "name"]} rules={[{ required: true }]}>
                        <Input placeholder="Variation Name" />
                      </Form.Item>
                      <Form.Item name={[index, "weight"]} rules={[{ required: true }]}>
                        <Input placeholder="Variation Weight" />
                      </Form.Item>
                      <Form.Item name={[index, "price"]} rules={[{ required: true }]}>
                        <InputNumber addonBefore="RM" step="0.0001" />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>

                  <VariationImageUpload formItemName={[index, "cover"]} labelCol={{ span: 24 }}/>
                </Col>

                <Col lg={12} sm={24}>
                  <Form.List name={[field.name, "options"]}>
                    {(options, { add, remove }) => (
                      <>
                       <Form.Item label="Add options" labelCol={{ span: 24 }}>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Option
                          </Button>
                        </Form.Item>
                        {
                          options.map(field => (
                            <React.Fragment key={field.key}>
                              <Space
                                key={field.key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 8,
                                }}
                                align="baseline"
                              >
                                <Form.Item name={[field.name, 'attribute']}>
                                  <Select
                                    style={{
                                      width: 200,
                                    }}
                                    onChange={handleChangeAttribute}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                  >
                                    {attributes.map((attribute) => (
                                      <Option key={attribute.attribute_id} value={attribute.attribute_id}>{attribute.frontend_label}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item name={[field.name, 'attribute_option']}>
                                  <Select
                                    style={{
                                      width: 200,
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                  >
                                    {attributeOptions.map((attribute) => (
                                      <Option key={attribute.option_id} value={attribute.option_id}>{attribute.value}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </Space>
                            </React.Fragment>
                          ))
                        }
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              {fields.length > 0 ? (
                <Button
                  type="danger"
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                  icon={<MinusCircleOutlined />}
                >
                  Remove Variation
                </Button>
              ) : null}
            </div>
          ))}
          <Divider />
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Variation
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  )
}