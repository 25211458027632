import React, { useState, Fragment } from 'react'
import { Card, Switch } from 'antd'

import VariationOptions from './VariationOptions';
import VariationTable from './VariationTable';
import VariationFillBar from './VariationFillBar';
import VariationImageWall from './VariationImageWall';

import './variation.css'

export default function Variation({ formRef, specList, setSpecList, setVariationList }) {
  const [visible, setVisible] = useState(false);

  const handleChange = (checked) => {
    setVisible(checked)
  }

  // useEffect(() => {
  //   console.log(specList)
  // }, [specList])

  return (
    <div className="second_step_form">
      <Card
        title="Variation"
        extra={<Switch onClick={handleChange}></Switch>}
      >
        {
          visible ? 
          <Fragment>
            <VariationOptions 
              formRef={formRef} 
              specList={specList}
              setSpecList={setSpecList}
            />
            <VariationFillBar
              formRef={formRef}
              visible={specList.length > 0 && specList[0].subSpecList}
              specList={specList}
              setSpecList={setSpecList}
            />
            <VariationTable
              specList={specList}
            />
            <VariationImageWall
              formRef={formRef}
              specList={specList}
              setSpecList={setSpecList}
              visible={specList.length > 0 && specList[0].subSpecList}
            />
          </Fragment> : null
        }
      </Card>
    </div>
  )
}