import React from "react"
import { Card, Tabs } from "antd"

import GridLastOrders from "./grids/GridLastOrders"
import GridLastTransactions from "./grids/GridLastTransactions"
import GridBestseller from "./grids/GridBestseller"
import GridMostViewedProduct from "./grids/GridMostViewedProduct"
import GridRecentlyAddedProduct from "./grids/GridRecentlyAddedProduct"

export default function StatGridRow(props) {
  const tab_items = [
    {
      label: 'Last Orders',
      key: 'last_order',
      children: <GridLastOrders />
    },
    {
      label: 'Last Transactions',
      key: 'last_transaction',
      children: <GridLastTransactions />
    },
    {
      label: 'Bestseller',
      key: 'bestseller',
      children: <GridBestseller />
    },
    {
      label: 'Most Viewed Products',
      key: 'most_viewed_product',
      children: <GridMostViewedProduct />
    },
    {
      label: 'Recently Added Products',
      key: 'recently_added_product',
      children: <GridRecentlyAddedProduct />
    },
  ]

  return (
    <Card
      className="stat-grid"
      bordered
    >
      <Tabs
        items={tab_items}
      />
    </Card>
  )
}