import { post } from '../utils/request'

const prefix_url = '/api/download/'

export function downloadInvoice(params) {
  return post(prefix_url+'downloadInvoice', params, { responseType: 'blob' })
}

export function bulkDownloadInvoice(params) {
  return post(prefix_url+'bulkDownloadInvoice', params, { responseType: 'blob' })
}

export function downloadShipment(params) {
  return post(prefix_url+'downloadShipment', params, { responseType: 'blob' })
}

export function bulkDownloadShipment(params) {
  return post(prefix_url+'bulkDownloadShipment', params, { responseType: 'blob' })
}

export function downloadCreditMemo(params) {
  return post(prefix_url+'downloadCreditMemo', params, { responseType: 'blob' })
}

export function bulkDownloadCreditMemo(params) {
  return post(prefix_url+'bulkDownloadCreditMemo', params, { responseType: 'blob' })
}

export function downloadLabel(params) {
  return post(prefix_url+'downloadLabel', params, { responseType: 'blob' })
}

export function downloadShippingLabel(params) {
  return post(prefix_url+'downloadShippingLabel', params, { responseType: 'blob' })
}

export function bulkDownloadShippingLabel(params) {
  return post(prefix_url+'bulkDownloadShippingLabel', params, { responseType: 'blob' })
}