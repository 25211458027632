import React, { useState } from "react"
import { Card, Space, message, Form, Upload } from "antd"
import { LoadingOutlined, CameraOutlined } from '@ant-design/icons'
import _ from "lodash"

import CustomEditor from "../../../../components/CustomEditor"

import { uploadService } from "../../../../utils/uploader"

export default function ProductInformation({ formRef }) {
  const [coverUpload, setCoverUpload] = useState('')
  const [coverLoading, setCoverLoading] = useState(false)
  const [galleryLoading, setGalleryLoading] = useState(false)

  const coverUploadLabel = (
    <div>
      {coverLoading ? <LoadingOutlined /> : <CameraOutlined />}
      <div style={{ marginTop: 8 }}>Cover Image</div>
    </div>
  )

  const galleryUploadLabel = (
    <div>
      {galleryLoading ? <LoadingOutlined /> : <CameraOutlined />}
      <div style={{ marginTop: 8 }}>Image</div>
    </div>
  )

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleCoverUpload = async (file) => {
    try {
      setCoverLoading(true)
      let progress = { percent: 1 }
      const formData = new FormData()
      formData.append("product_cover", file.file)
      formData.append("type", "product_cover")
      const res = await uploadService(formData)

      file.onProgress(progress)
      if (res.status === 200) {
        file.onProgress({ percent: 100 })
        file.onSuccess(res.data)
      }
      setCoverLoading(false)
    } catch (err) {
      setCoverLoading(false)
      file.onError(err)
    }
  }

  const handleCoverUploadChange = async (file) => {
    if (_.isEmpty(file.fileList)) {
      setCoverUpload('')
    }

    if (file.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(file.file.originFileObj, imageUrl =>
        setCoverUpload(imageUrl)
      );
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleGalleryUpload = async (file) => {
    try {
      setGalleryLoading(true)
      let progress = { percent: 1 }
      const formData = new FormData()
      formData.append("product_image", file.file)
      formData.append("type", "product_image")
      const res = await uploadService(formData)

      file.onProgress(progress)
      if (res.status === 200) {
        file.onProgress({ percent: 100 })
        file.onSuccess(res.data)
      }
      setGalleryLoading(false)
    } catch (err) {
      file.onError(err)
      setGalleryLoading(false)
    }
  }

  return (
    <div className="second_step_form">
      <div className="product_info_card">
        <Card title="Product Information">
          <p style={{ color: '#626262', fontWeight: 'bold' }}>Product Images</p>
          <Space style={{ width: '100%', justifyContent: 'left' }}>
            <div className="image_border">
              <Card bordered={false}>
                <Form.Item name="cover" valuePropName="fileList" getValueFromEvent={getFile}>
                  <Upload
                    customRequest={handleCoverUpload}
                    onChange={handleCoverUploadChange}
                    className="avatar-uploader"
                    showUploadList={false}
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    maxCount={1}
                  >
                    {coverUpload ? <img src={coverUpload} alt="avatar" style={{ width: 80 }} /> : coverUploadLabel}
                  </Upload>
                </Form.Item>
              </Card>
            </div>
            <div className="image_border">
              <Card bordered={false} >
                <Form.Item name="gallery" valuePropName="fileList" getValueFromEvent={getFile}>
                  <Upload
                    customRequest={handleGalleryUpload}
                    className="avatar-uploader"
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                  >
                    {galleryUploadLabel}
                  </Upload>
                </Form.Item>
              </Card>
            </div>
          </Space>
          <Form.Item label="Short Description" labelCol={{ span: 24 }}>
            <CustomEditor formRef={formRef} field="short_description" />
          </Form.Item>
          <Form.Item label="Product Description" labelCol={{ span: 24 }}>
            <CustomEditor formRef={formRef} field="description" />
          </Form.Item>
        </Card>
      </div>
    </div>
  )
}