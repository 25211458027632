import React from "react"
import { Form, InputNumber } from "antd"
import _ from "lodash"

export default function SpecWeight({ currentData, selectedWeightType}) {
  const getWeightTooltip = () => {
    const value = currentData.product_updates['weight']
    return `Pending Update: ${value}`
  }

  return (
    <Form.Item name="weight" label=' ' rules={[{ required: true, min:0.5, type:'number' }]} tooltip={_.isUndefined(currentData.product_updates['weight']) ? null : getWeightTooltip}>
      <InputNumber style={{width:'100%'}} addonBefore="kgs" disabled={selectedWeightType === 0} step="0.1" />
    </Form.Item>
  )
}