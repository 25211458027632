import React, {useEffect, useState} from "react"
import { Button } from "antd"
import { EditableProTable } from '@ant-design/pro-components'
import _ from "lodash"

import { saveShipTrack, removeShipTrack } from "../../../../api/order.api"

export default function CarrierTable(props) {
  const currentData = props.data
  const [editableKeys, setEditableRowKeys] = useState([])
  const [dataSource, setDataSource] = useState([])
  
  const tracking_columns = [
    {
      title: 'Carrier',
      dataIndex: 'carrier_code',
      key: 'carrier_code',
      valueType: 'select',
      valueEnum: {
        custom: { text: 'Custom Value', status: 'custom'},
        dhl: { text: 'DHL', status: 'dhl'},
        fedex: { text: 'Federal Express', status: 'fedex'},
        ups: { text: 'United Parcel Service', status: 'ups'},
        usps: { text: 'United States Postal Service', status: 'usps'},
      },
      width: '30%',
      render: (text, record, _, action) => {
        return text
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Number',
      dataIndex: 'track_number',
      key: 'track_number',
    },
    {
      title: 'Action',
      dataIndex: ['action'],
      key: 'action',
      valueType: 'option',
      width: '20%',
      render: (text, record, _, action) => [
        <Button
          key="delete"
          type="link"
          onClick={async () => {
            await handleDelete(record)
            setDataSource(dataSource.filter((item) => item.entity_id !== record.entity_id)) 
          }}
        >
          Remove
        </Button>
      ]
    }
  ]

  const handleOnSave = async () => {
    const tracks = resolveTracks()
    if(!_.isEmpty(tracks)) {
      let payload = {
        shipment_id: currentData.entity_id,
        order_id: currentData.order_id,
        tracks
      }
      const res = await saveShipTrack(payload)
      if(res) {
        const res_data = res.data
        setDataSource(res_data)
      }
    }
  }

  const handleDelete = async(record) => {
    if(record) {
      await removeShipTrack({ entity_id: record.entity_id })
    }
  }

  const resolveTracks = () => {
    const filtered_tracks = dataSource.filter((item) => !item.hasOwnProperty('parent_id'))
    if(!_.isEmpty(filtered_tracks)) {
      return filtered_tracks.map((item) => {
        let track = {}
        track.carrier_code = item.carrier_code
        track.track_number = item.track_number
        track.title = item.title
        return track
      })
    }
    return []
  }

  const toolbarAction = () => {
    return [
      <Button 
        key="save" 
        type="primary"
        onClick={handleOnSave}
      >
        Save
      </Button>
    ]
  }

  useEffect(() => {
    let isMount = true
    if(isMount) {
      setDataSource(currentData.shiptracks)
    }
  }, [currentData])

  return (
    <EditableProTable
      rowKey="entity_id"
      toolBarRender={toolbarAction}
      columns={tracking_columns}
      recordCreatorProps={{
        position: 'bottom',
        newRecordType: 'dataSource',
        creatorButtonText: 'Add Tracking Number',
        record: (index) => {
          return { entity_id: index + 1 };
        },
      }}
      value={dataSource}
      onChange={setDataSource}
      editable={{
        type: 'multiple',
        editableKeys,
        deleteText: 'Remove',
        deletePopconfirmMessage: 'Are you sure want to remove?',
        onValuesChange: (record, recordList) => {
          setDataSource(recordList)
        },
        onChange: setEditableRowKeys,
        actionRender: (row, _, dom) => {
          return [dom.delete]
        },
      }}
    />
  )
}