import React, {useState } from "react"
import { Modal, Result, Button, message, Typography } from "antd"
import { ShoppingCartOutlined } from '@ant-design/icons'
import fileDownload from "js-file-download"

import { downloadShippingLabel } from "../../../api/download.api"

const { Paragraph, Text } = Typography

export default function PopupWaybill(props) {
  const [labelLoading, setLabelLoading] = useState(false)
  const isVisible = props.isVisible
  const currentData = props.currentData

  const handleDownloadShippingLabel = async() => {
    try {
      setLabelLoading(true)
      const timestamp = Date.now()
      const filename = `shipping-label-${timestamp}.pdf`
      let payload = resolveLabelPayload()
      const res = await downloadShippingLabel(payload)
      fileDownload(res.data, filename)      
      setLabelLoading(false)
      props.setVisibleState(false)
    } catch(err) {
      setLabelLoading(false)
      console.error('unable to catch:', err)
      message.warning('something went wrong...')
    }
  }

  const resolveLabelPayload = () => {
    const { vendor_id, entity_id } = currentData
    let payload = {
      entity_id,
      vendor_id
    }
    return payload
  }

  const handleCancel = () => {
    props.setVisibleState(false)
  }

  return (
    <Modal 
      title="Drop-off Detail"
      onCancel={handleCancel}
      open={isVisible}
      footer={[
        <Button key="submit" type="primary" loading={labelLoading} onClick={handleDownloadShippingLabel}>
          Print Waybill
        </Button>
      ]}
    >
      <Result
        status="success"
        icon={<ShoppingCartOutlined />}
        title={`Your Shipment ID: ${currentData.shipment_increment_id}`}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              Instruction
            </Text>
          </Paragraph>
          <Paragraph>
            1. Print out one copy of the AWB in A6.
          </Paragraph>
          <Paragraph>
            2. Attached the AWB on your parcel.
          </Paragraph>
        </div>
      </Result>
    </Modal>
  )
}