import React from "react"
import { Card } from 'antd'

import NotificationTable from "../../modules/Notification/NotificationTable"

export default function Notification(props) {
  return (
    <Card
      style={{ width: '100%'}}
      title="Notifications"
    >   
        <NotificationTable />      
    </Card>
  )

}