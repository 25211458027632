import { request } from "../request"

const prefix_url = '/api/profile/'

export function getProfileHeader(params) {
  return request.get(prefix_url+'getProfileHeader', params)
}

export function getSellerDetail(params) {
  return request.get(prefix_url+'getSellerDetail', params)
}

export function updateSellerDetail(params) {
  return request.put(prefix_url+'updateSellerDetail', params)
}

export function getBusinessInformation(params) {
  return request.get(prefix_url+'getBusinessInformation', params)
}

export function updateBusinessInformation(params) {
  return request.put(prefix_url+'updateBusinessInformation', params)
}

export function getShippingInformation(params) {
  return request.get(prefix_url+'getShippingInformation', params)
}

export function updateShippingInformation(params) {
  return request.put(prefix_url+'updateShippingInformation', params)
}
