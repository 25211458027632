import React from "react"
import { Card, Row, Col } from "antd"
import { ProDescriptions } from '@ant-design/pro-components';

import { resolveAmount } from "../../../../helpers/order.helper"

export default function OrderTotalSummary(props) {
  const currentData = props.data

  return (
    <div className="order_info_page_title order_totals_component">
      <Card title="Order Totals">
        <Row gutter={16}>
          <Col lg={12}>
            <div className="order_totals_table">
              <ProDescriptions size="large" column={1} 
              style ={{marginBottom: '10px', background: '#F8F8F8', border: '2px solid #EFEFEF', borderRadius:'5px'}} bordered>
                <ProDescriptions.Item label="Subtotal"> RM {resolveAmount(currentData.subtotal)}</ProDescriptions.Item>
              </ProDescriptions>
              <ProDescriptions size="large" column={1} 
              style ={{marginBottom: '10px', border: '1px solid #EFEFEF', borderRadius:'5px'}} bordered>
                <ProDescriptions.Item label="Shipping & Handling"> RM {resolveAmount(currentData.shipping_amount)}</ProDescriptions.Item>
              </ProDescriptions>
              <ProDescriptions size="default" column={1} 
              style ={{marginBottom: '10px', background: '#F8F8F8', border: '2px solid #EFEFEF', borderRadius:'5px'}} bordered>
                <ProDescriptions.Item label="Discount"> RM {resolveAmount(currentData.discount_amount)}</ProDescriptions.Item>
              </ProDescriptions>
            </div>
          </Col>

          <Col lg={12}>
            <div className="order_totals_table2">
              <ProDescriptions size="large" column={1} bordered>
                {/*</ProDescriptions><ProDescriptions size="middle" column={1} title="Order Totals" bordered>*/}
                <ProDescriptions.Item label="Marketplace Commission"> RM 0</ProDescriptions.Item>
                <ProDescriptions.Item label="Grand Total"> RM {resolveAmount(currentData.grand_total)}</ProDescriptions.Item>
                <ProDescriptions.Item label="Total Paid"> RM {resolveAmount(currentData.total_paid)}</ProDescriptions.Item>
                <ProDescriptions.Item label="Total Refunded"> RM {resolveAmount(currentData.total_refunded)}</ProDescriptions.Item>
                <ProDescriptions.Item label="Total Due"> RM {resolveAmount(currentData.total_due)}</ProDescriptions.Item>
              </ProDescriptions>
            </div>
          </Col>
        </Row >
      </Card >
    </div >
  )
}