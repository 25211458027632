import React, {useEffect, useState} from "react"
import { Dropdown, Badge, Button, List } from "antd"
import { BellOutlined } from "@ant-design/icons"
import { getFiveLatestNotification, getOrderId, updateOneNotification, updateAllNotification } from "../../api/notification.api"
import { useHistory } from 'react-router-dom';

export default function NotifyView(props) {
  const [currentData, setCurrentData] = useState([])
  const [show, setShow] = useState(false)
  const [count,setCount] = useState(0)
  const history = useHistory()
  useEffect(() => {
    const fetchNotification = async () => {
      try {
        let unread=0
        
        const res = await getFiveLatestNotification()
        if(res && res.data && res.data && res.data.rows && res.data.rows.length >0) {
          const res_data = res.data.rows
          setCurrentData(res_data)
          for(let i = 0; i<res.data.rows.length; i++) {
            if(res.data.rows[i].is_read !== 1) {
              unread +=1
            }
          }
          setCount(unread)
          if(unread !== 0) {
            setShow(true)
          }
        } 
      } catch (err) {
        console.error('unable to catch:', err)
      }
    }
    fetchNotification()
  },[])

  const onMenuClick = () => {
    history.push('/admin/notifications')
  }

  const loadMore =(
      <div
        className="loadbackground"
      >
        <Button onClick={onMenuClick} className={"viewall"}>View All</Button>
      </div>
  ) 

  const header = (
    <div style={{display:'flex'}}>
      <h1 style={{fontSize:18}}> Notification </h1>
      <Button style={{marginLeft:90}} onClick={()=>readAll()}>Mark All As Read</Button>
    </div>
  )

  const readAll = async() => {
    await updateAllNotification()
    const res = await getFiveLatestNotification()
    if(res && res.data && res.data && res.data.rows && res.data.rows.length >0) {
      const res_data = res.data.rows
      setCurrentData(res_data)
    } 
    setShow(false)
  }

  const getOrder = async(id,data) => {
    await updateOneNotification({notification_id:id})
    const order_res = await getOrderId({entity_id:data})
    const res_data = order_res.data.order_id
    let path = '/admin/orders/view/' + res_data
    history.push(path)
    const res = await getFiveLatestNotification()
    if(res && res.data && res.data && res.data.rows && res.data.rows.length >0) {
      const res_data = res.data.rows
      setCurrentData(res_data)
    } 
    setCount(count-1)
    if(count <= 1) {
      setShow(false)
    }
  }

  const menuNotifyDropdown =  (
    <div>
    <List   
      size="small"
      header={header}
      itemLayout="vertical"
      dataSource={currentData}
      bordered
      renderItem={(item) => 
      <List.Item onClick={()=>getOrder(item.notification_id,item.additional_info.id)} className={item.is_read === 0 ? "unread" : "read"}>
        <><div dangerouslySetInnerHTML={{__html: item.message}} /></>
      </List.Item>
      }
      loadMore={loadMore}
    />   
    </div>
  )

  return (
    <Dropdown overlay={menuNotifyDropdown}>
      <span className="action">
      <Badge dot={show} >
        <BellOutlined style={{ fontSize: 16 }} />
      </Badge>
      </span>
    </Dropdown>
  )
}
