import { message } from "antd"
import storePersist from "./storePersist"

export function getToken() {
	return localStorage.getItem('token')
}

export function setToken(token) {
	return localStorage.setItem('token', token)
}

export function clearToken() {
	localStorage.removeItem('token')
}

export function setUser(user) {
	return localStorage.setItem('profile', JSON.stringify(user))
}

export function getUser() {
	return localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null
}

export function clearUser() {
	localStorage.removeItem('profile')
}

export function logout() {
	clearToken()
	clearUser()
	storePersist.clear()
}

export function isLogged() {
	try {
		const token = getToken()
		const parseToken = parseJwt(token)
		const { exp } = parseToken
    if (exp < (new Date().getTime() + 1) / 1000) {
			return message.loading('session expired...', 2).then(() => {
				logout()
				return false
			})
    }
		return true
	} catch (err) {
		return false
	}
}

function parseJwt (token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}
